import { defineStore } from "pinia";

export let useBannerStore = defineStore('banner', {
    state: () => ({
        banners: [],
        banners_trashed: [],
        total_pages:0
    }),

    actions: {
        async fill(page = 1, paginated = 1, search = null) {
            try {
                let link = paginated == 1 ? `/banners?page=${page}` : `/banners?paginated=0`;
                if(search !== null){ link += `&search=${search}`;}
                let paginatedResponse = (search == null || search.length < 2) && paginated == 1;
                const response = await axios.get(link);
                this.banners = paginatedResponse ? response.data.data : response.data;
                this.total_pages = paginatedResponse ? response.data.last_page : 1;
            }
            catch (error) {
                console.log(error)
            }
        },
        async get(id) {
            try {
                return await axios
                .get("/banners/" + id).then(function (response) {
                    return response;
                });
            }
            catch(error){
                console.log(error);
            }
        },
        async getTrashed(page = 1, paginated = 1, search = null) {
            try {
                let link = paginated == 1 ? `/banners-trash?page=${page}` : `/banners-trash?paginated=0`;
                if(search !== null){ link += `&search=${search}`;}
                let paginatedResponse = (search == null || search.length < 3) && paginated == 1;
                const response = await axios.get(link);
                this.banners_trashed = paginatedResponse ? response.data.data : response.data;
                this.total_pages = paginatedResponse ? response.data.last_page : 1;
            }
            catch (error) {
                console.log(error)
            }
        },
        async create(banner) {
            try {
                return await axios
                .post("/banners", banner).then(function (response) {
                    return response;
                });
            }
            catch(error){
                return error.response;
            }
        },
        async update(id, banner) {
            try {
                return await axios
                .put("/banners/" + id, banner).then(function (response) {
                    return response;
                });
            }
            catch(error){
                return error.response;
            }
        },
        async delete(id) {
            try {
                return axios
                .delete(
                    "/banners/" + id
                )
                .then(function (response) {
                    return response;
                });
            }
            catch(error){
                console.log(error);
            }
        },
        async deleteForever(id) {
            try {
                return axios
                .delete(
                    "/banners-delete-forever/" + id
                )
                .then(function (response) {
                    return response;
                });
            }
            catch(error){
                console.log(error);
            }
        },
        async restore(id) {
            try {
                return axios
                .post(
                    "/banners-restore/" + id
                )
                .then(function (response) {
                    return response;
                });
            }
            catch(error){
                console.log(error);
            }
        },
    
    async changeStatus(banner, status) {
        try {
            return await axios
            .put("/banners-change-status/" + banner + "/" + status).then(function (response) {
                return response;
            });
        }
        catch(error){
            return error.response;
        }
    },
}
    
    
});


