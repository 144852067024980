import { defineStore } from "pinia";

export let useLanguageStore = defineStore('language', {
    state: () => ({
        languageCodes: [],
        mainCode: ""
    }),

    actions: {
        async fill() {
            try {
                const response = await axios.get('/languages/get-language-codes')
                this.languageCodes = response.data
            }
            catch (error) {
                console.log(error)
            }
        },
        async getMainCode() {
            try {
                const response = await axios.get('/languages/get-main-lang-code')
                this.mainCode = response.data
            }
            catch(error){
                return error.response;
            }
        }
    }
});


