const couponMessages = {
    en: {
        'code' : 'Code',
        'value' : 'Value',
        'type' : 'Type',
        'start_date' : 'Start date',
        'end_date' : 'End date',
        'percentage' : 'Percentage',
        'used_once' : 'Single use',
        'yes' : 'Yes',
        'no' : 'No',
        'coupon_products' : 'Product(s) whom belongs to',
        'used_number':'Used number',
        '1':'YES',
        '0':'NO',
        'reusable_per_days': 'Reusable per x days'
    },
    hu: {
        'code' : 'Kód',
        'value': 'Érték',
        'type': 'Típus',
        'start_date' : 'Kezdő dátum',
        'end_date' : 'Lejárati dátum',
        'percentage' : 'Százalék',
        'used_once' : 'Egyszer használatos',
        'yes' : 'Igen',
        'no' : 'Nem',
        'coupon_products' : 'Termék(ek) amihez tartozik',
        'used_number':'Felhasználva',
        '1':'IGEN',
        '0':'NEM',
        'reusable_per_days': 'Újra használható ennyi naponta: '
    },
    ro: {
        'code' : 'Cod',
        'value' : 'Valoare',
        'type' : 'Tip',
        'start_date' : 'Data de începere',
        'end_date' : 'Data finală',
        'percentage' : 'Procentaj',
        'used_once' : 'Utilizare unică',
        'yes' : 'Da',
        'no' : 'Nu',
        'coupon_products' : 'Produs(e) care aparține',
        'used_number':'',
        'reusable_per_days': 'Reutilizabile pe zile: '
    }
}

export default couponMessages