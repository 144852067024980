<template>
    <div class="row">
        <div class="col-sm-12">
            <div class="page-header close_icon">
        <div class="header-wrapper row m-0">
          <form
            class="form-inline search-full col custom-search"
            action="javascript:void(0)"
            method="get"
          >
            <div class="form-group w-100">
              <div class="Typeahead Typeahead--twitterUsers">
                <div class="u-posRelative">
                  <input
                    class="demo-input Typeahead-input form-control-plaintext w-100"
                    type="text"
                    :placeholder="$t('message.search')"
                    name="q"
                    title=""
                    autofocus
                    v-model="searchText"
                    @keyup="searchDeliveryPrice"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
            <div class="card">
                <p v-if="noDeliveryPriceFound" class="m-3">{{ $t('message.no_result') }}</p>
                <tableList v-if="deliveryPrices.length" :columns="columns" :listItems="deliveryPrices" :options="options" :store="deliveryPriceStore"
                    :withStatusChange="true" :page="page" :totalPages="totalPages"/>
            </div>
        </div>
    </div>
</template>
  
<script setup>
import { computed, onMounted, ref, watch } from "vue";
import { useDeliveryPriceStore } from "@/stores/deliveryPriceStore";
import { useI18n } from "vue-i18n";
import tableList from "@/components/custom/tableList";
import { useRoute, useRouter } from "vue-router";
import debounce from 'lodash.debounce';

/*
  multilang
*/
const { t } = useI18n();

/*
  data
*/
const deliveryPrices = computed(() => {
    if (path.value == "/delivery_price_list") {
        return deliveryPriceStore.deliveryPrices;
    } else {
        return deliveryPriceStore.deliveryPrices_trashed;
    }
});
const columns = [
    { name: "ID", data: "id" },
    { name: t("message.country"), data: "country_name" },
    { name: t("message.county"), data: "county_name" },
    { name: t("message.city"), data: "city_name" },
    { name: t("message.start_date"), data: "date_from" },
    { name: t("message.end_date"), data: "date_to" },
    { name: t("message.price"), data: "price" },
];
const options = ref([]);
const totalPages = computed(() => {
    return deliveryPriceStore.total_pages;
});

const searchText = ref();
let noDeliveryPriceFound = ref(false);

/*
  route
*/
const route = useRoute();
const router = useRouter();
const path = ref(route.path);
const page = computed(() => {
    return parseInt(route.query.page);
});

/*
  stores
*/
let deliveryPriceStore = useDeliveryPriceStore();


/*
  events
*/
onMounted(() => {
    getList(route.query.page);
});

watch(
    () => route.path,
    (newPath) => {
        path.value = newPath;
        getList();
    }
);

watch(
    () => route.query.page,
    (newPage) => {
        searchText.value = '';
      noDeliveryPriceFound.value = false;
      getList(newPage);
    },
  );

  const getList = debounce(async (page = 1,search = null) => {
    try{
        if (path.value == "/delivery_price_list") {
        await deliveryPriceStore.fill(page,search);
        options.value = [
            { type: "edit_link", name: "edit_delivery_price" },
            { type: "event", icon: "lnr lnr-trash", event_type: "trash" },
        ];
    } else {
        await deliveryPriceStore.getTrashed(page,search);
        options.value = [
            { type: "event", icon: "lnr lnr-undo", event_type: "restore" },
            { type: "edit_link", name: "edit_delivery_price" },
            { type: "event", icon: "lnr lnr-trash", event_type: "delete" },
        ];
    }
    noDeliveryPriceFound.value = deliveryPriceStore.deliveryPrices.length === 0;
} catch (error) {
    console.error("Error in getList:", error);
  }
}, 1000);

const searchDeliveryPrice = () => {
  router.push({ query: { page: 1 } });
  getList(1, searchText.value);
};
</script>

<style scoped>
.page-header.close_icon {
  position: unset;
  margin-left: 0 !important;
  width: calc(100% - 50px) !important;
  background-color: #fbfeff;
  box-shadow: unset;
}

.header-wrapper {
  padding: 22px 0px !important;
}
/* search input not to dissapear at mobile */
@media only screen and (max-width: 762px){
    .custom-search {
      display: flex !important;
      position: relative !important;
      .form-group {
        margin-top: 0 !important;
      }
  }
}
</style>

  