<template>
    <div class="row">
      <div class="col-sm-12">
        <div class="page-header close_icon">
          <div class="header-wrapper row m-0">
            <form
              class="form-inline search-full col custom-search"
              action="javascript:void(0)"
              method="get"
            >
              <div class="form-group w-100">
                <div class="Typeahead Typeahead--twitterUsers">
                  <div class="u-posRelative">
                    <input
                      class="demo-input Typeahead-input form-control-plaintext w-100"
                      type="text"
                      :placeholder="$t('message.search')"
                      name="q"
                      title=""
                      autofocus
                      v-model="searchText"
                      @keyup="searchHomePage"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="card">
          <p v-if="noHomePageFound" class="m-3">{{ $t('message.no_result') }}</p>
          <tableList
            v-if="homePages.length"
            :columns="columns"
            :listItems="homePages"
            :options="options"
            :store="homePagestore"
            :page="page"
            :totalPages="totalPages"
          />
        </div>
      </div>
    </div>
  </template>
    
  <script setup>
  import { computed, onMounted, ref, watch } from "vue";
  import { useHomePageStore } from "@/stores/homePageStore";
  import { useI18n } from "vue-i18n";
  import tableList from "@/components/custom/tableList";
  import { useRoute, useRouter } from "vue-router";
  import debounce from 'lodash.debounce';
  
  /*
    multilang
  */
  const { t } = useI18n();
  
  /*
    data
  */
  const homePages = computed(() => {
    if (path.value == "/home_page_list") {
      return homePagestore.homePages;
    } else {
      return homePagestore.home_pages_trashed;
    }
  });
  const columns = [
    { name: "ID", data: "id" },
    { name: t("message.title_1"), data: "title_1" },
    { name: t("message.title_2"), data: "title_2" },
    { name: t("message.title_3"), data: "title_3" },

  ];
  const options = ref([]);
  const totalPages = computed(() => {
      return homePagestore.total_pages;
  });
  const searchText = ref();
  let noHomePageFound = ref(false);
  
  /*
    route
  */
  const route = useRoute();
  const router = useRouter();
  const path = ref(route.path);
  const page = computed(() => {
    return parseInt(route.query.page);
  });
  
  /*
    stores
  */
  let homePagestore = useHomePageStore();
  
  
  /*
    events
  */
  onMounted(() => {
    getList(route.query.page);
  });
  
  watch(
    () => route.query.page,
    (newPage) => {
      getList(newPage, 1, null);
    },
  );
  
  watch(
    () => route.path,
    (newPath) => {
      path.value = newPath;
      searchText.value = '';
      noHomePageFound.value = false;
      getList();
    }
  );
  
  const getList = debounce(async (page = 1, paginated = 1, search = null) => {
    if (path.value == "/home_page_list") {
      await homePagestore.fill(page, paginated, search);
      options.value = [
        { type: "edit_link", name: "edit_home_page" },
        { type: "event", icon: "lnr lnr-trash", event_type: "trash" },
      ];
    } else {
      await homePagestore.getTrashed(page, paginated, search);
      options.value = [
        { type: "event", icon: "lnr lnr-undo", event_type: "restore" },
        { type: "edit_link", name: "edit_home_page" },
        { type: "event", icon: "lnr lnr-trash", event_type: "delete" },
      ];
    }
    if(homePages.value.length == 0){
      noHomePageFound.value = true;
    } else {
      noHomePageFound.value = false;
    }
  }, 1000);
  
  const searchHomePage = () => {
    router.push({ query: {page: 1} });
    getList(1, 1, searchText.value);
  }
  </script>
  
  <style scoped>
  .page-header.close_icon {
    position: unset;
    margin-left: 0 !important;
    width: calc(100% - 50px) !important;
    background-color: #fbfeff;
    box-shadow: unset;
  }
  
  .header-wrapper {
    padding: 22px 0px !important;
  }
  /* search input not to dissapear at mobile */
  @media only screen and (max-width: 762px){
      .custom-search {
        display: flex !important;
        position: relative !important;
        .form-group {
          margin-top: 0 !important;
        }
    }
  }
  </style>
  