<template>
  <div class="tab-pane fade show active" id="pills-home" role="tabpanel">
    <form class="theme-form theme-form-2 mega-form">
      <div class="card-header-1">
        <h5>Product Information</h5>
      </div>

      <div class="row g-4">
        <div class="col-lg-6">
          <label for="fname" class="form-label-title mb-2 form-label"
            >First Name</label
          >
          <input type="text" class="form-control" id="fname" />
        </div>

        <div class="col-lg-6">
          <label for="mail" class="form-label-title mb-2 form-label"
            >Email Address</label
          >
          <input type="email" class="form-control" id="mail" />
        </div>

        <div class="col-lg-6">
          <label class="col-form-label form-label-title mb-2">Password</label>
          <input class="form-control" type="password" />
        </div>

        <div class="col-lg-6">
          <label class="col-form-label form-label-title mb-2"
            >Confirm Password</label
          >
          <input class="form-control" type="password" />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
