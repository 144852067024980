<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="page-header close_icon">
        <div class="header-wrapper row m-0">
          <form class="form-inline search-full col custom-search" action="javascript:void(0)" method="get">
            <div class="form-group w-100">
              <div class="Typeahead Typeahead--twitterUsers">
                <div class="u-posRelative">
                  <input class="demo-input Typeahead-input form-control-plaintext w-100" type="text"
                    :placeholder="$t('message.search')" name="q" title="" autofocus v-model="searchText"
                    @keyup="searchBanner" />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="card">
        <p v-if="noBannerFound" class="m-3">{{ $t('message.no_result') }}</p>
        <tableList v-if="banners.length" :columns="columns" :listItems="banners" :options="options" :store="bannerStore"
        :withStatusChange="true" :page="page" :totalPages="totalPages" />
    </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from "vue";
import { useBannerStore } from "@/stores/bannerStore";
import { useI18n } from "vue-i18n";
import tableList from "@/components/custom/tableList";
import { useRoute, useRouter } from "vue-router";
import debounce from 'lodash.debounce';


/*
  multilang
*/
const { t } = useI18n();

/*
  data
*/
const banners = computed(() => {
  if (path.value == "/banner_list") {
    return bannerStore.banners;
  } else {
    return bannerStore.banners_trashed;
  }
});
const columns = [
  { name: "ID", data: "id" },
  { name: "Link név", data: "link_name" },
];
const options = ref([]);
const totalPages = computed(() => {
  return bannerStore.total_pages;
});
const searchText = ref();
let noBannerFound = ref(false);

/*
  route
*/
const route = useRoute();
const router = useRouter();
const path = ref(route.path);
const page = computed(() => {
  return parseInt(route.query.page);
});

/*
  stores
*/
let bannerStore = useBannerStore();


/*
  events
*/
onMounted(() => {
  getList(route.query.page);
});

watch(
  () => route.query.page,
  (newPage) => {
    getList(newPage, 1, null);
  },
);

watch(
  () => route.path,
  (newPath) => {
    path.value = newPath;
    searchText.value = '';
    noBannerFound.value = false;
    getList();
  }
);

const getList = debounce(async (page = 1, paginated = 1, search = null) => {
  if (path.value == "/banner_list") {
    await bannerStore.fill(page, paginated, search);
    options.value = [
      { type: "edit_link", name: "edit_banner" },
      { type: "event", icon: "lnr lnr-trash", event_type: "trash" },
    ];
  } else {
    await bannerStore.getTrashed(page, paginated, search);
    options.value = [
      { type: "event", icon: "lnr lnr-undo", event_type: "restore" },
      { type: "edit_link", name: "edit_banner" },
      { type: "event", icon: "lnr lnr-trash", event_type: "delete" },
    ];
  }
  if (banners.value.length == 0) {
    noBannerFound.value = true;
  } else {
    noBannerFound.value = false;
  }
}, 1000);

const searchBanner = () => {  
  router.push({ query: { page: 1 } });
  getList(1, 1, searchText.value);
}
</script>

<style scoped>
.page-header.close_icon {
  position: unset;
  margin-left: 0 !important;
  width: calc(100% - 50px) !important;
  background-color: #fbfeff;
  box-shadow: unset;
}

.header-wrapper {
  padding: 22px 0px !important;
}

/* search input not to dissapear at mobile */
@media only screen and (max-width: 762px) {
  .custom-search {
    display: flex !important;
    position: relative !important;

    .form-group {
      margin-top: 0 !important;
    }
  }
}
</style>
