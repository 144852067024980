import { defineStore } from "pinia";

export let useLabelStore = defineStore('label', {
    state: () => ({
        labels: [],
        labels_trashed: [],
        total_pages:0
    }),

    actions: {
        async fill(page = 1,search = null) {
            try {
                let link = `/labels?page=${page}`;
                if (search !== null) {
                    link += `&search=${search}`;
                }
                let paginated = search == null || search.length < 2;
                const response = await axios.get(link);
                this.labels = paginated ? response.data.data : response.data;
                this.total_pages = paginated ? response.data.last_page : 1;
            }
            catch (error) {
                console.log(error)
            }
        },
        async get(id) {
            try {
                return await axios
                .get("/labels/" + id).then(function (response) {
                    return response;
                });
            }
            catch(error){
                console.log(error);
            }
        },
        async getTrashed(page = 1,search = null) {
            try {
                let link = `/labels-trash?page=${page}`;
                if(search !== null){ link += `&search=${search}`;}
                let paginated = search == null || search.length < 3;
                const response = await axios.get(link);
                this.labels_trashed = paginated ? response.data.data : response.data;
                this.total_pages = paginated ? response.data.last_page : 1;
            }
            catch (error) {
                console.log(error)
            }
        },
        async create(label) {
            try {
                return await axios
                .post("/labels", label).then(function (response) {
                    return response;
                });
            }
            catch(error){
                return error.response;
            }
        },
        async update(id, label) {
            try {
                return await axios
                .put("/labels/" + id, label).then(function (response) {
                    return response;
                });
            }
            catch(error){
                return error.response;
            }
        },
        async delete(id) {
            try {
                return axios
                .delete(
                    "/labels/" + id
                )
                .then(function (response) {
                    return response;
                });
            }
            catch(error){
                console.log(error);
            }
        },
        async deleteForever(id) {
            try {
                return axios
                .delete(
                    "/labels-delete-forever/" + id
                )
                .then(function (response) {
                    return response;
                });
            }
            catch(error){
                console.log(error);
            }
        },
        async restore(id) {
            try {
                return axios
                .post(
                    "/labels-restore/" + id
                )
                .then(function (response) {
                    return response;
                });
            }
            catch(error){
                console.log(error);
            }
        }
    }
});


