import { createApp, markRaw } from "vue";
import { createPinia } from 'pinia'
import App from "./App.vue";
import router from "./router";
import store from "./store";
import titleMixin from "@/mixins/titleMixin";
import "./assets/scss/app.scss";
import "../node_modules/bootstrap/dist/js/bootstrap.js";
import "@/assets/css/font-awesome.css";
import "@/assets/css/linearicon.css";
import "@/assets/css/vendors/font-awesome.css";
import vueFeather from "vue-feather";
import CKEditor from "@ckeditor/ckeditor5-vue";
import VueApexCharts from "vue3-apexcharts";
import axios from 'axios';
import VueSweetalert2 from 'vue-sweetalert2';
import { createI18n } from 'vue-i18n';
import messages from './locales/translations';
import Toaster from '@meforma/vue-toaster';
import Uploader from 'vue-media-upload';
import "choices.js/public/assets/styles/choices.min.css";
import { useAuthStore } from "@/stores/auth";

const app = createApp(App);
const pinia = createPinia();
// acess router in store
pinia.use(({store}) => {
  store.router = markRaw(router);
})

const i18n = createI18n({
    legacy: false,
    locale: 'hu',
    fallbackLocale: 'hu',
    messages,
});

window.axios = axios;
axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;

axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  const authStore = useAuthStore();
  if (error.response.status === 419) {
    authStore.authUser = null;
    router.push('log_in')
  }
  return Promise.reject(error)
})

// storage location
app.config.globalProperties.$storageLocation = process.env.VUE_APP_STORAGE_LOCATION

app.use(store);
app.use(CKEditor);
app.component("vueFeather", vueFeather);
app.component("apexchart", VueApexCharts);
app.component('Uploader', Uploader);
app.mixin(titleMixin);
app.use(router);
app.use(pinia);
app.use(VueSweetalert2);
app.use(i18n);
app.use(Toaster, {
    position: 'bottom-right'
  }).provide('toast', app.config.globalProperties.$toast)
app.mount("#app");
