import { defineStore } from "pinia";

export let useDeliveryPriceStore = defineStore('deliveryPrice', {
    state: () => ({
        deliveryPrices: [],
        deliveryPrices_trashed: [],
        total_pages:0
    }),

    actions: {
        async fill(page = 1,search = null) {
            try {
                let link = `/delivery-prices?page=${page}`;
                if (search !== null) {
                    link += `&search=${search}`;
                }
                let paginated = search == null || search.length < 2;
                const response = await axios.get(link);
                this.deliveryPrices = paginated ? response.data.data : response.data;
                this.total_pages = paginated ? response.data.last_page : 1;
            }
            catch (error) {
                console.log(error)
            }
        },
        async get(id) {
            try {
                return await axios
                .get("/delivery-prices/" + id).then(function (response) {
                    return response;
                });
            }
            catch(error){
                console.log(error);
            }
        },
        async getTrashed(page = 1,search = null) {
            try {
                let link = `/delivery-prices-trash?page=${page}`;
                if(search !== null){ link += `&search=${search}`;}
                let paginated = search == null || search.length < 3;
                const response = await axios.get(link);
                this.deliveryPrices_trashed = paginated ? response.data.data : response.data;
                this.total_pages = paginated ? response.data.last_page : 1;
            }
            catch (error) {
                console.log(error)
            }
        },
        async create(deliveryPrice) {
            try {
                return await axios
                .post("/delivery-prices", deliveryPrice).then(function (response) {
                    return response;
                });
            }
            catch(error){
                return error.response;
            }
        },
        async update(id, deliveryPrice) {
            try {
                return await axios
                .put("/delivery-prices/" + id, deliveryPrice).then(function (response) {
                    return response;
                });
            }
            catch(error){
                return error.response;
            }
        },
        async delete(id) {
            try {
                return axios
                .delete(
                    "/delivery-prices/" + id
                )
                .then(function (response) {
                    return response;
                });
            }
            catch(error){
                console.log(error);
            }
        },
        async deleteForever(id) {
            try {
                return axios
                .delete(
                    "/delivery-prices-delete-forever/" + id
                )
                .then(function (response) {
                    return response;
                });
            }
            catch(error){
                console.log(error);
            }
        },
        async restore(id) {
            try {
                return axios
                .post(
                    "/delivery-prices-restore/" + id
                )
                .then(function (response) {
                    return response;
                });
            }
            catch(error){
                console.log(error);
            }
        },
        async changeStatus(deliveryPrice, status) {
            try {
                return await axios
                .put("/delivery-prices-change-status/" + deliveryPrice + "/" + status).then(function (response) {
                    return response;
                });
            }
            catch(error){
                return error.response;
            }
        },
        async getCountries() {
            try {
                return await axios
                .get("/get-delivery-price-countries").then(function (response) {
                    return response.data;
                });
            }
            catch (error) {
                console.log(error)
            }
        },        
        async getCounties() {
            try {
                return await axios
                .get("/get-delivery-price-counties").then(function (response) {
                    return response.data;
                });
            }
            catch (error) {
                console.log(error)
            }
        },        
        async getCities(county) {
            try {
                return await axios
                .get("/get-delivery-price-cities/" + county).then(function (response) {
                    return response.data;
                });
            }
            catch (error) {
                console.log(error)
            }
        },        
    }
});


