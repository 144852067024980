<template>
  <div class="card">
    <div class="card-body">
      <form class="theme-form theme-form-2 mega-form" @submit.prevent="savePostCategory">
        <language-inputs v-if="mainCode && fields && fields.length > 0" v-model:fields="fields" :fields="fields"
          :mainCode="mainCode" :fieldTypes="fieldTypes" />
        <div class="mt-3 lang-errors-container">
          <p class="text-danger" v-for="error of v$.fields.$errors" :key="error.$uid">
            <strong>{{
        t("validationErrors.lang_field", {
          field: t(`message.${error.$propertyPath.split(".")[2]}`),
          code: mainCode,
        })
      }}</strong>
          </p>
        </div>
        <div class="row mt-1">
          <div class="col-lg-6">
            <h4 class="how">{{ t("message.how_to") }}</h4>
            <label for="icon" class="form-label-title mb-2 form-label-icon">Icon</label>
            <Vue3IconPicker v-model="icon" />
            <p class="text-danger" v-for="error of v$.icon.$errors" :key="error.$uid">
              <strong>{{ t(`validationErrors.${[error.$message]}`) }}</strong>
            </p>
          </div>
        </div>
        <div class="row g-4">
          <div class="col-lg-6 text-center mt-5">
            <button :class="{ 'btn btn-primary': true, 'disabled': btnDisabled }">
              {{ t("message.save") }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeMount, computed, inject, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useLocaleStore } from "@/stores/locale";
import { usePostCategoryStore } from "@/stores/postCategoryStore";
import { fillTranslationFields } from "@/helpers/useTranslationFields";
import { useFieldStore } from "@/stores/fieldStore";
import languageInputs from "@/components/custom/languageInputs";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { useLanguageStore } from "@/stores/languageStore";
import { useRouter, useRoute } from "vue-router";
import Vue3IconPicker from '@/components/custom/Vue3IconPicker.vue'

/*
  multilang, notifications
*/
const { t } = useI18n();
const toast = inject("toast");

/*
  form data, data
*/
let fields = ref([]);
let icon = ref();
let fieldTypes = ref();
let btnDisabled = ref(false);

/*
  stores
*/
let activeLang = useLocaleStore();
let postCategoryStore = usePostCategoryStore();
let fieldStore = useFieldStore();
const languageStore = useLanguageStore();

const mainCode = computed(() => {
  return languageStore.mainCode;
});


/*
  validation
*/
const rules = {
  icon: { required },
  fields: { 0: { name: { [mainCode.value]: { required } } } },
};

const v$ = useVuelidate(rules, { icon, fields });

/*
  router
*/
const router = useRouter();
const route = useRoute();
const id = computed(() => {
  return route.params.id;
});

/*
  events
*/
onBeforeMount(async () => {
  await languageStore.getMainCode();
});

onMounted(async () => {
  await getFields();
  if (id.value > 0) {
    const response = await postCategoryStore.get(id.value);
    fillTranslationFields(fields, response.data.translations);
    icon.value = response.data.icon;
  }
});
const savePostCategory = async () => {
  btnDisabled.value = true;
  const request = {
    lang: activeLang.locale,
    icon: icon.value,
    translations: fields.value,
  };

  const isValid = await v$.value.$validate();

  if (isValid) {
    const response =
      id.value > 0
        ? await postCategoryStore.update(id.value, request)
        : await postCategoryStore.create(request);

    if (response && response.data.message == "success") {
      toast.success(t("message.success_message"));
      id.value > 0 ? router.go(-1) : router.push({ name: "post_category_list", query: { page: response.data.lastPage } });
    } else {
      toast.error(t("message.error_message"));
    }
  }
  btnDisabled.value = false;
};

watch(
  () => route.path,
  (newPath) => {
    if (newPath == "/create_post_category") {
      getFields();
      icon.value = "";
    }
  }
);

const getFields = async () => {
  const fieldsResponse = await fieldStore.fill("post_category_translations");
  fields.value = fieldsResponse.data;
  const typesResponse = await fieldStore.getFieldTypes("post_category_translations");
  fieldTypes.value = typesResponse.data;
};


</script>
<style>
.lang-errors-container {
  border-bottom: 1px solid #ddd;
  padding-bottom: 1rem;
}

.form-label-icon {
  margin-bottom: 10px;
  margin-right: 10px;
  font-size: 25px !important;
}

.how {
  margin-top: 20px;
}
</style>
