<template>
  <div class="card">
    <div class="card-body">
      <form class="theme-form theme-form-2 mega-form" @submit.prevent="saveProduct">
        <language-inputs v-if="mainCode && fields && fields.length > 0" v-model:fields="fields" :fields="fields"
          :mainCode="mainCode" :fieldTypes="fieldTypes" />
        <div class="mt-3 lang-errors-container">
          <p class="text-danger" v-for="error of v$.fields.$errors" :key="error.$uid">
            <strong>{{
              t("validationErrors.lang_field", {
                field: t(`message.${error.$propertyPath.split(".")[2]}`),
                code: mainCode,
              })
            }}</strong>
          </p>
        </div>
        <div class="row mt-1">
          <div class="col-lg-6">
            <label class="form-label-title mb-2 form-label">EAN</label>
            <input v-model="ean" type="text" class="form-control" />
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-lg-6">
            <label class="form-label-title mb-2 form-label">SKU</label>
            <input v-model="sku" type="text" class="form-control" />
          </div>
        </div>
        <div class="row mt-1 mb-2">
          <div class="col-lg-6">
            <label class="form-label-title mb-2 form-label">{{
              t("message.brand")
            }}</label>
            <select class="js-example-basic-single w-100" v-model="brand">
              <option></option>
              <option v-for="brand in brands" :value="brand.id">
                {{ brand.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-lg-6">
            <label class="scoped-label form-label-title mb-2 form-label">
              {{ t("message.manage_stock") }}
            </label>
            <input class="scoped-checkbox check-it" type="checkbox" :checked="manageStock" v-model="manageStock" />
          </div>
        </div>
        <td class="menu-status status-td">
          <select name="stock_status" :disabled="manageStock" v-model="manageStockStatus">
            <option :selected="manageStockStatus == 'instock'" value="instock">{{ t('message.inStock') }}</option>
            <option :selected="manageStockStatus == 'onbackorder'" value="onbackorder">{{ t('message.onBackOrder') }}
            </option>
            <option :selected="manageStockStatus == 'outofstock'" value="outofstock">{{ t('message.outOfStock') }}
            </option>
          </select>
        </td>

        <div class="row mt-1">
          <div class="col-lg-6">
            <label class="form-label-title mb-2 form-label">{{ t("message.stock") }}</label>
            <input v-model="stock" type="number" step="0.01" class="form-control" :disabled="!manageStock" />
          </div>
        </div>

        <div class="row mt-1">
          <div class="col-lg-6">
            <label class="form-label-title mb-2 form-label">{{
              t("message.stock_multiply")
            }}</label>
            <input v-model="stock_multiply" type="number" step="0.01" class="form-control" />
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-lg-6">
            <label class="form-label-title mb-2 form-label">{{
              t("message.price")
            }}</label>
            <input v-model="price" type="number" step="0.01" class="form-control" />
            <p class="text-danger" v-for="error of v$.price.$errors" :key="error.$uid">
              <strong>{{ t(`validationErrors.${[error.$message]}`) }}</strong>
            </p>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-lg-6">
            <label class="mt-4 form-label">{{ t("message.labels") }}</label>
            <select v-model="selectedLabels" id="product-labels" class="form-control" multiple></select>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-lg-6">
            <label class="mt-4 form-label">{{ t("message.product_categories") }}</label>
            <select v-model="v$.selectedCategories.$model" id="product-categories" class="form-control" multiple></select>
            <p class="text-danger" v-for="error of v$.selectedCategories.$errors" :key="error.$uid">
              <strong>{{ t(`validationErrors.${[error.$message]}`) }}</strong>
            </p>
          </div>
        </div>
        <div class="row">
          <label for="basic-media-input" class="form-label-title form-label mb-1 mt-2">{{
            t("message.basic_media")
          }}</label>
          <Uploader v-if="basicMedia.saved && basicMedia.saved.length > 0 && id > 0" max="1" class="mt-1"
            server="/store-media" inputId="basic-media-input" :media="basicMedia.saved" :location="$storageLocation"
            :withIdFolder="true" @add="addBasicMedia" @remove="removeBasicMedia" maxFilesize="100" />
          <Uploader v-else max="1" class="mt-1" server="/store-media" @change="changeBasicMedia"
            inputId="basic-media-input" maxFilesize="100" />
        </div>
        <div class="row">
          <label for="media-collection-input" class="form-label-title form-label mb-1 mt-2">{{ t("message.media_files")
          }}</label>
          <Uploader v-if="mediaCollection.saved && mediaCollection.saved.length > 0 && id > 0" class="mt-1"
            server="/store-media" inputId="media-collection-input" :media="mediaCollection.saved"
            :location="$storageLocation" :withIdFolder="true" @add="addMediaCollection" @remove="removeMediaCollection"
            maxFilesize="100" />
          <Uploader v-else class="mt-1" server="/store-media" @change="changeMediaCollection"
            inputId="media-collection-input" maxFilesize="100" />
        </div>
        <div class="row g-4">
          <div class="col-lg-6 text-center mt-5">
            <button :class="{ 'btn btn-primary': true, disabled: btnDisabled }">
              {{ t("message.save") }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeMount, computed, inject, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useLocaleStore } from "@/stores/locale";
import { useProductStore } from "@/stores/productStore";
import { fillTranslationFields } from "@/helpers/useTranslationFields";
import { useFieldStore } from "@/stores/fieldStore";
import languageInputs from "@/components/custom/languageInputs";
import { useVuelidate } from "@vuelidate/core";
import { required, numeric } from "@vuelidate/validators";
import { useLanguageStore } from "@/stores/languageStore";
import { useRouter, useRoute } from "vue-router";
import Uploader from "vue-media-upload";
import { initChoice } from "@/helpers/useInitChoice";
import { useBrandStore } from "@/stores/brandStore";

/*
    multilang, notifications
  */
const { t } = useI18n();
const toast = inject("toast");

/*
    form data, data
  */
let fields = ref([]);
let fieldTypes = ref();
let btnDisabled = ref(false);
const basicMedia = ref({ saved: [], added: [], removed: [] });
const mediaCollection = ref({ saved: [], added: [], removed: [] });
let ean = ref();
let sku = ref();
let stock = ref();
let manageStock = ref(true);
let manageStockStatus = ref("instock");
let stock_multiply = ref();
let price = ref();
let labelSelect = ref();
let labels = ref([]);
let selectedLabels = ref([]);
let categorySelect = ref();
let categories = ref([]);
let selectedCategories = ref([]);
let brand = ref();
const brands = computed(() => {
  return brandStore.brands;
});

/*
    stores
  */
let activeLang = useLocaleStore();
let productStore = useProductStore();
let fieldStore = useFieldStore();
let brandStore = useBrandStore();
const languageStore = useLanguageStore();

const mainCode = computed(() => {
  return languageStore.mainCode;
});

/*
    validation
  */
const rules = {
  fields: {
    0: { title: { [mainCode.value]: { required } } }
  },
  manageStock: { required },
  selectedCategories: { required },
  price: { required, numeric }
};

const v$ = useVuelidate(rules, {
  fields,
  manageStock,
  selectedCategories,
  price,
});

/*
    router
  */
const router = useRouter();
const route = useRoute();
const id = computed(() => {
  return route.params.id;
});

/*
    events
  */
onBeforeMount(async () => {
  await languageStore.getMainCode();
});

onMounted(async () => {
  await getFields();
  initChoice("product-labels", labelSelect, true);
  initChoice("product-categories", categorySelect, true);
  getLabels();
  getCategories();
  brandStore.fill(1, 0);
  if (id.value > 0) {
    const response = await productStore.get(id.value);
    fillTranslationFields(fields, response.data.translations);
    selectedLabels.value = response.data.labels;
    selectedCategories.value = response.data.categories;
    ean.value = response.data.ean;
    sku.value = response.data.sku;
    brand.value = response.data.brand_id;
    price.value = response.data.price;
    manageStock.value = response.data.manage_stock;
    stock.value = response.data.stock;
    stock_multiply.value = response.data.stock_multiply;
    manageStockStatus.value = response.data.wp_availability
    let basic = response.data.media.filter((item) => item.collection_name === "basic")[0];
    basicMedia.value.saved = basic ? [{ id: basic.id, name: basic.file_name }] : [];
    let collection = response.data.media.filter(
      (item) => item.collection_name === "products"
    );
    collection.forEach(function (val, key) {
      mediaCollection.value.saved.push({
        id: val.id,
        name: val.file_name,
        url: val.original_url,
      });
    });
  }
});

const saveProduct = async () => {
  btnDisabled.value = true;
  const request = {
    lang: activeLang.locale,
    translations: fields.value,
    categories: selectedCategories.value,
    labels: selectedLabels.value,
    ean: ean.value,
    sku: sku.value,
    stock: stock.value == '' || stock.value == undefined ? 0 : stock.value,
    stock_multiply: stock_multiply.value == '' || stock_multiply.value == undefined ? 1 : stock_multiply.value,
    manage_stock: manageStock.value,
    wp_availability: manageStockStatus.value,
    price: price.value,
    brand: brand.value,
    basicMedia: id.value > 0 ? basicMedia.value : basicMedia.value.added,
    mediaCollection: id.value > 0 ? mediaCollection.value : mediaCollection.value.added,
  };

  const isValid = await v$.value.$validate();

  if (isValid) {
    const response =
      id.value > 0
        ? await productStore.update(id.value, request)
        : await productStore.create(request);

    if (response && response.data.message == "success") {
      toast.success(t("message.success_message"));
      id.value > 0 ? router.go(-1) : router.push({ name: "product_list", query: { page: response.data.lastPage } });
    } else {
      if (response.status == 422) {
        toast.error(t("message.lang_title_error"));
      } else {
        toast.error(t("message.error_message"));
      }
    }
  }
  btnDisabled.value = false;
};

watch(
  () => route.path,
  (newPath) => {
    if (newPath == "/create_product") {
      getFields();
      getLabels();
      getCategories();
      mediaCollection.value = {};
      basicMedia.value = {};
      ean.value = "";
      sku.value = "";
      stock.value = "";
      stock_multiply.value = "";
      manageStock.value = "",
        price.value = "";
      brand.value = "";
    }
  }
);

const getFields = async () => {
  const fieldsResponse = await fieldStore.fill("product_translations");
  fields.value = fieldsResponse.data;
  const typesResponse = await fieldStore.getFieldTypes("product_translations");
  fieldTypes.value = typesResponse.data;
};

function getLabels() {
  labelSelect.value.removeActiveItems();
  (async () => {
    await productStore.get_labels(id.value).then(function (response) {
      let datas = response.data;
      let choices = [];
      datas.forEach((val, key) => {
        choices[key] = {};
        choices[key].value = val.id;
        choices[key].label = val.name == null ? t('message.without_name') : val.name;
        choices[key].selected = id.value ? val.selected : false;
      });
      labels.value = choices;
      labelSelect.value.setChoices(labels.value, "value", "label", true);
    });
  })();
}

function getCategories() {
  categorySelect.value.removeActiveItems();
  (async () => {
    await productStore.get_categories(id.value).then(function (response) {
      let datas = response.data;
      let choices = [];
      datas.forEach((val, key) => {
        choices[key] = {};
        choices[key].value = val.id;
        choices[key].label = val.name == null ? t('message.without_name') : val.name;
        choices[key].selected = id.value ? val.selected : false;
      });
      categories.value = choices;
      categorySelect.value.setChoices(categories.value, "value", "label", true);
    });
  })();
}


const changeBasicMedia = (basic) => {
  basicMedia.value.added = basic;
};

const changeMediaCollection = (collection) => {
  mediaCollection.value.added = collection;
};

const addBasicMedia = (addedImage, added) => {
  basicMedia.value.added = added;
};

const removeBasicMedia = (removedImage, removed) => {
  basicMedia.value.removed = removed;
};

const addMediaCollection = (addedImage, added) => {
  mediaCollection.value.added = added;
};

const removeMediaCollection = (removedImage, removed) => {
  mediaCollection.value.removed = removed;
};
</script>
<style scoped>
.row .col-lg-6 .scoped-checkbox.check-it {
  margin-bottom: 8px;
  margin-top: 4px;
}

.row .col-lg-6 .scoped-label {
  margin-right: 8px;
}
.row .col-lg-6 input:disabled {
  opacity: 0.6; 
  cursor: not-allowed; 
}
menu-status status-td{
  opacity: 0.6; 
  cursor: not-allowed; 
}
</style>
