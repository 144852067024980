const deliveryPriceMessages = {
    en: {
        'country': 'Country',
        'county': 'County',
        'city': 'City',
        'location': 'Location'
    },
    hu: {
        'country': 'Ország',
        'county': 'Megye',
        'city': 'Helység',
        'location': 'Helység'
    },
    ro: {
        'country': 'Țară',
        'county': 'Județ',
        'city': 'Oraș',
        'location': 'Localitate'
    }
}

export default deliveryPriceMessages