const isEndDateValid = (start_date,end_date) => {
    if (!start_date || !end_date) {
        return true;
    }
    const startDateObj = new Date(start_date);
    const endDateObj = new Date(end_date);
    return endDateObj >= startDateObj;
};

export {
    isEndDateValid
}

