const productMessages = {
    en: {
        'product_categories':'Product categories',
        'labels':'Labels',
        'brand':'Producer',
        'stock':'Stock',
        'parent':'Parent',
        'type':'Type',
        'value':'Value',
        'start_date':'Start date',
        'end_date':'End date',
        'amount':'Amount',
        'product':'Product',
        'percentage':'%',
        'price':'Price',
        'stock_multiply': 'Stock multiplier (1 item in the webshop means this quantity in Saga)',
        'no_product_found':'No product found',
        'reset_search':'Reset search',
    },
    hu: {
        'product_categories':'Termékkategóriák',
        'labels':'Címkék',
        'brand':'Termelő',
        'stock':'Készlet',
        'parent':'Szülő',
        'type':'Típus',
        'value':'Érték',
        'start_date':'Kezdő dátum',
        'end_date':'Vég dátum',
        'amount':'Összeg',
        'product':'Termék',
        'percentage':'%',
        'price':'Ár',
        'stock_multiply': 'Sztokk szorzó (1 darab a webshopban ennyi mennyiséget jelent a Saga programban)',
        'no_product_found':'Nem található termék',
        'reset_search':'Keresés törlése',
    },
    ro: {
        'product_categories':'Categorii de produse',
        'labels':'Etichete',
        'brand':'Producător',
        'stock':'Stoc',
        'parent':'Părinte',
        'type':'Tip',
        'value':'Valoare',
        'start_date':'Data de început',
        'end_date':'Data de încheiere',
        'amount':'Sumă',
        'product':'Produs',
        'percentage':'%',
        'price':'Preț',
        'stock_multiply': 'Înmulțitor Stoch (1 bucată în magazinul online este cantitatea din programul Saga)',
        'no_product_found':'Nu a fost găsit niciun produs',
        'reset_search':'resetați căutarea',
    }
}

export default productMessages