<template>
  <!-- Table Start -->
  <div class="card-body">
    <div class="table-responsive table-desi">
      <table v-if="!isNaN(currentPage) && options.length"
        class="user-table vendor-table menu-list-table table table-striped">
        <thead>
          <tr>
            <th class="text-capitalize" v-for="column in columns">{{ column.name }}</th>
            <th class="status-th" v-if="withStatusChange">Status</th>
            <th v-if="options.length">{{ t("message.options") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in listItems" :key="item.id">
            <td v-for="column in columns">
              <template v-if="column.data === 'title' && !item[column.data]">
                <template v-for="translation in item.translations">
                  <template v-if="translation.locale === 'hu'">
                    {{ translation.title }}
                  </template>
                </template>
              </template>
              <template v-if="column.data === 'locale'">
                <template v-if="!item[column.data]">
                  <template v-for="translation in item.translations">
                    <template v-if="translation.title">
                      {{ translation.locale.toUpperCase() }}
                      <template></template>
                    </template>
                  </template>
                </template>
              </template>
              {{ column.translate ? t(`message.${item[column.data]}`) : item[column.data] }}
              {{ column.suffix ? column.suffix : '' }}
              <span v-if="item[column.discount]">
                <span :title="t('message.discount')" class="lnr lnr-arrow-down text-success"></span>
              </span>
            </td>
            <td v-if="withStatusChange" class="menu-status status-td">
              <input class="checkbox_animated check-it" type="checkbox" :checked="item.status"
                @change="changeStatus($event, item)" />
              <span :class="item.status ? 'success' : 'warning'">{{ item.status ? t("message.active") :
        t("message.inactive")
                }}</span>
            </td>
            <td v-if="options.length">
              <ul>
                <li v-for="option in options">
                  <router-link v-if="option.type == 'edit_link'" :to="{
        name: option.name,
        params: { id: item.id },
      }">
                    <span class="lnr lnr-pencil"></span>
                  </router-link>
                  <a v-if="option.type == 'event'" @click="event(item.id, option.event_type)" href="javascript:void(0)">
                    <span :class="option.icon"></span>
                  </a>
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <!-- Table End -->
  <div class="pagination-box">
    <nav class="ms-auto me-auto" aria-label="...">
      <ul class="pagination pagination-primary">
        <li class="page-item" :class="[{ disabled: currentPage == 1 }]">
          <a class="page-link" href="javascript:void(0)" @click.prevent="
        changePage(currentPage - 1 > 0 ? currentPage - 1 : changePage)
        ">{{ t("message.prev") }}</a>
        </li>

        <li v-if="pages" class="page-item" :class="[{ active: currentPage == page }]" v-for="(page, index) in pages"
          :key="index">
          <a class="page-link" href="javascript:void(0)" @click.prevent="changePage(page)">{{ page }}</a>
        </li>
        <li class="page-item" :class="[{ disabled: currentPage == totalPages }]">
          <a class="page-link" href="javascript:void(0)" @click.prevent="
        changePage(currentPage + 1 <= totalPages ? currentPage + 1 : changePage)
        ">{{ t("message.next") }}</a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script setup>
import { deleteItem } from "@/helpers/useDeleteItem";
import { useI18n } from "vue-i18n";
import { ref, onMounted, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

/*
  multilang
*/
const { t } = useI18n();

/*
  props
*/
const props = defineProps({
  columns: Array,
  options: Array,
  listItems: Array,
  store: Object,
  withStatusChange: { type: Boolean, default: false },
  page: Number,
  totalPages: Number
});

/*
  data
*/
let currentPage = ref(props.page);
const pages = ref([]);

/*
  route
*/
const route = useRoute();
const router = useRouter();

/*
  events
*/
onMounted(() => {
  currentPage.value = props.page;
  calculatePages();
});

const event = async (id, event_type) => {
  const getEventDetails = (event_type, id = null) => {
    switch (event_type) {
      case "trash":
        return [
          t("message.move_to_trash"),
          t("message.moved_to_trash"),
          () => props.store.delete(id),
          () => props.store.fill(),
        ];
      case "delete":
        return [
          t("message.delete_it"),
          t("message.deleted"),
          () => props.store.deleteForever(id),
          () => props.store.getTrashed(),
        ];
      case "restore":
        return [
          t("message.restore"),
          t("message.restored"),
          () => props.store.restore(id),
          () => props.store.getTrashed(),
        ];
      case "only_simple_delete":
        return [
          t("message.delete_it"),
          t("message.deleted"),
          () => props.store.delete(id),
          () => props.store.fill(),
        ];
      default:
        return ["", ""];
    }
  };

  const eventDetails = getEventDetails(event_type, id);

  await deleteItem({
    title: t("message.are_you_sure"),
    confirmButtonText: eventDetails[0],
    cancelButtonText: t("message.no_cancel"),
    messageCancelled: t("message.cancelled"),
    messageDeleted: eventDetails[1],
    deleteAction: eventDetails[2],
    reloadAction: eventDetails[3],
  });
};

const changePage = (toPage) => {
  if (toPage <= props.totalPages && toPage > 0) {
    currentPage.value = toPage;
    router.push({ path: route.path, query: { page: toPage } })
  }
  calculatePages();
};

const calculatePages = () => {
  var numbers = [];
  let startpage = currentPage.value === 1 ? currentPage.value : currentPage.value - 1;
  let endPage =
    currentPage.value === 1
      ? currentPage.value + 2
      : currentPage.value + 1 <= props.totalPages
        ? currentPage.value + 1
        : currentPage.value;
  for (let i = startpage; i <= endPage; i++) {
    i <= props.totalPages && numbers.push(i);
  }
  pages.value = numbers;

}

watch(
  () => props.totalPages,
  () => {
    if (isNaN(currentPage.value)) {
      currentPage.value = props.totalPages;
    } else {
      currentPage.value = props.page;
    }
    calculatePages();
  }
);

const changeStatus = async (event, item) => {
  let checked = event.target.checked;
  const response = await props.store.changeStatus(item.id, checked ? 1 : 0);
  if (response.data) {
    item.status = checked;
  } else {
    event.target.checked = !checked;
    item.status = !checked;
  }
};


</script>

<style scoped>
.status-th,
.status-td {
  text-align: center !important;
}

.vendor-table tbody tr td:nth-child(5) {
  font-weight: normal;
  color: #2b2b2b;
}

.user-table tbody tr td {
  padding: 0.75rem !important;
}
</style>
