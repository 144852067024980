import { defineStore } from "pinia";

export let useFieldStore = defineStore('field', {

    actions: {
        async fill(table) {
            try {
                return await axios
                .get('/languages/get-translation-fields-by-table/'+table).then(function (response) {
                    return response;
                });
            }
            catch(error){
                console.log(error);
            }
        },
        async getFieldTypes(table) {
            try {
                return await axios
                .get("/get-translation-field-types/" + table).then(function (response) {
                    return response;
                });
            }
            catch(error){
                console.log(error);
            }
        },
    }
});


