const dashboardMessages = {
    en: {
        'total_earnings': 'Total Earnings',
        'total_orders': 'Total Orders',
        'total_users': 'Total Users',
        'transactions': 'Transactions',
    },
    hu: {
        'total_earnings': 'Összes bevétel',
        'total_orders': 'Összes rendelés',
        'total_users': 'Összes felhasználó',
        'transactions': 'Tranzakciók',

    },
    ro: {
        'total_earnings': 'Total venituri',
        'total_orders': 'Total comenzi',
        'total_users': 'Total utilizatori',
        'transactions': 'Tranzacții',

    }
}

export default dashboardMessages