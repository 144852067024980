<template>
    <div class="row">
        <div class="col-sm-12">
            <button @click="goBack()" class="btn btn-primary text-uppercase mb-3">
                <span class="lnr lnr-arrow-left"></span> {{ t("message.back") }}
            </button>
            <div class="card">
                <div class="card-body">
                    <div class="bg-inner cart-section order-details-table">
                        <div class="row g-4">
                            <div class="col-xl-8">
                                <div class="table-responsive table-details">
                                    <table class="table cart-table table-borderless">
                                        <thead>
                                            <tr>
                                                <th colspan="3">{{ t("message.items") }}</th>
                                                <th class="text-end" colspan="2">
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr class="table-order" v-for="(item, index) in order.products"
                                                :key="index">
                                                <td>
                                                    <img :src="item.basic_media ? item.basic_media : getImageUrl('placeholder/placeholder1.png')"
                                                        class="img-fluid blur-up lazyload w-50" alt="" />
                                                </td>
                                                <td>
                                                    <p>{{ t("message.title") }}</p>
                                                    <h5>
                                                        {{ item.title }}
                                                    </h5>
                                                </td>
                                                <td>
                                                    <p>{{ t("message.quantity") }}</p>
                                                    <h5>{{ item.quantity }}</h5>
                                                </td>
                                                <td>
                                                    <p>{{ t("message.unit_price") }}</p>
                                                    <h5>{{ Number(item.unit_price).toFixed(2) }} Lei</h5>
                                                </td>
                                                <td>
                                                    <p>{{ t("message.total_unit_price") }}</p>
                                                    <h5>{{ Number(item.quantity * item.unit_price).toFixed(2) }} Lei
                                                    </h5>
                                                </td>
                                            </tr>
                                        </tbody>

                                        <tfoot>
                                            <tr class="table-order">
                                                <td colspan="4">
                                                    <h5>{{ t("message.subtotal") }} :</h5>
                                                </td>
                                                <td>
                                                    <h4>{{ Number(order.subtotal).toFixed(2) }} Lei</h4>
                                                </td>
                                            </tr>

                                            <tr v-if="order.couponDiscount > 0" class="table-order">
                                                <td colspan="4">
                                                    <h5>{{ t("message.coupon") }} :</h5>
                                                </td>
                                                <td>
                                                    <h4>
                                                        {{ - Number(order.couponDiscount).toFixed(2) }} Lei
                                                    </h4>
                                                </td>
                                            </tr>

                                            <tr class="table-order">
                                                <td colspan="4">
                                                    <h5>{{ t("message.shipping") }} :</h5>
                                                </td>
                                                <td>
                                                    <h4>{{ order.shippingPrice }} Lei</h4>
                                                </td>
                                            </tr>

                                            <tr class="table-order">
                                                <td colspan="4">
                                                    <h4 class="theme-color fw-bold">{{ t("message.total_price") }} :
                                                    </h4>
                                                </td>
                                                <td>
                                                    <h4 class="theme-color fw-bold">
                                                        {{ Number(order.payableAmount).toFixed(2) }} Lei
                                                    </h4>
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>

                            <div class="col-xl-4">
                                <div class="order-success">
                                    <div class="row g-4">
                                        <h4>{{ t("message.state") }}</h4>
                                        <select name="stock_status" id="stock_status"
                                            @change="changeOrderStatus($event, order)">
                                            <option :selected="order.status == 1 ? true : false" value=1>
                                                {{ t('message.new') }}
                                            </option>
                                            <option :selected="order.status == 2 ? true : false" value=2>
                                                {{ t('message.registered') }}
                                            </option>
                                            <option :selected="order.status == 3 ? true : false" value=3>
                                                {{ t('message.packaged') }}
                                            </option>
                                            <option :selected="order.status == 4 ? true : false" value=4>
                                                {{ t('message.courier') }}
                                            </option>
                                            <option :selected="order.status == 5 ? true : false" value=5>
                                                {{ t('message.arrived') }}
                                            </option>
                                            <option :selected="order.status == 6 ? true : false" value=6>
                                                {{ t('message.archived') }}
                                            </option>
                                        </select>
                                        <h4>{{ t("message.summary") }}</h4>
                                        <ul class="order-details">
                                            <li>{{ $t('message.order_number') }}: {{ order.order_number }}</li>
                                            <li v-if="order.created_at">{{ $t('message.date') }}: {{ format(new
                                                Date(order.created_at),
                                                'yyyy-MM-dd H:mm:ss') }}</li>
                                        </ul>

                                        <h4>{{ t("message.shipping_details") }}</h4>
                                        <ul class="order-details" v-if="order.shipping_address">
                                            <li>{{ t("message.name") }}: {{ order.shipping_address.contact_person }}
                                            </li>
                                            <li>{{ t("message.tel") }}: {{ order.shipping_address.phone }}</li>
                                            <template v-if="isJSON(order.shipping_address.address)">
                                                <li>{{ t("message.street") }}: {{
                                                    getAddressDetail(order.shipping_address.address, 'street') }}</li>
                                                <li>{{ t("message.number") }}: {{
                                                    getAddressDetail(order.shipping_address.address, 'number') }}</li>
                                                <li>{{ t("message.staircase") }}: {{
                                                    getAddressDetail(order.shipping_address.address, 'staircase') }}
                                                </li>
                                                <li>{{ t("message.floor") }}: {{
                                                    getAddressDetail(order.shipping_address.address, 'floor') }}</li>
                                                <li>{{ t("message.apartman") }}: {{
                                                    getAddressDetail(order.shipping_address.address, 'apartman') }}</li>
                                            </template>
                                            <template v-else>
                                                <li>{{ t("message.address") }}: {{ order.shipping_address.address }}
                                                </li>
                                            </template>
                                            <li>{{ $t("message.location") }}: {{ order.shipping_address.city.name_ro }}
                                            </li>
                                            <li>{{ $t("message.county") }}: {{
                                                order.shipping_address.city.county.name_ro }}</li>
                                            <li>{{ order.shipping_address.post_code }}</li>
                                        </ul>

                                        <h4>{{ t("message.billing_details") }}</h4>
                                        <ul class="order-details"
                                            v-if="order.billing_data && order.billing_data !== null">
                                            <li>{{ t("message.name") }}: {{ order.billing_data.contact_person }}</li>
                                            <li>{{ t("message.tel") }}: {{ order.billing_data.phone }}</li>
                                            <template v-if="isJSON(order.billing_data.address)">
                                                <li>{{ t("message.street") }}: {{
                                                    getAddressDetail(order.billing_data.address, 'street') }}</li>
                                                <li>{{ t("message.number") }}: {{
                                                    getAddressDetail(order.billing_data.address, 'number') }}</li>
                                                <li>{{ t("message.staircase") }}: {{
                                                    getAddressDetail(order.billing_data.address, 'staircase') }}</li>
                                                <li>{{ t("message.floor") }}: {{
                                                    getAddressDetail(order.billing_data.address, 'floor') }}</li>
                                                <li>{{ t("message.apartman") }}: {{
                                                    getAddressDetail(order.billing_data.address, 'apartman') }}</li>
                                            </template>
                                            <template v-else>
                                                <li>{{ t("message.address") }}: {{ order.billing_data.address }}</li>
                                            </template>
                                            <li>{{ $t("message.location") }}: {{ order.billing_data.city.name_ro }}</li>
                                            <li>{{ $t("message.county") }}: {{ order.billing_data.city.county.name_ro }}
                                            </li>
                                            <li>{{ order.billing_data.post_code }}</li>
                                        </ul>

                                        <ul class="order-details"
                                            v-if="order.company_data && order.company_data !== null">
                                            <li>{{ t("message.company_name") }}: {{ order.company_data.company_name }}
                                            </li>
                                            <li>{{ t("message.unique_registration_code") }}: {{
                                                order.company_data.unique_registration_code }}</li>
                                            <li>{{ t("message.registration_number") }}: {{
                                                order.company_data.registration_number || "-" }}</li>
                                            <li>{{ t("message.bank") }}: {{ order.company_data.bank || "-" }}</li>
                                            <li>{{ t("message.bank_account") }}: {{ order.company_data.bank_account ||
                                                "-" }}</li>
                                            <template v-if="isJSON(order.company_data.address)">
                                                <li>{{ t("message.street") }}: {{
                                                    getAddressDetail(order.company_data.address, 'street') }}</li>
                                                <li>{{ t("message.number") }}: {{
                                                    getAddressDetail(order.company_data.address, 'number') }}</li>
                                                <li>{{ t("message.staircase") }}: {{
                                                    getAddressDetail(order.company_data.address, 'staircase') }}</li>
                                                <li>{{ t("message.floor") }}: {{
                                                    getAddressDetail(order.company_data.address, 'floor') }}</li>
                                                <li>{{ t("message.apartman") }}: {{
                                                    getAddressDetail(order.company_data.address, 'apartman') }}</li>
                                            </template>
                                            <template v-else>
                                                <li>{{ t("message.address") }}: {{ order.company_data.address }}</li>
                                            </template>
                                            <li>{{ $t("message.location") }}: {{ order.company_data.city.name_ro }}</li>
                                            <li>{{ $t("message.county") }}: {{ order.company_data.city.county.name_ro }}
                                            </li>
                                            <li>{{ order.company_data.post_code }}</li>
                                        </ul>

                                        <div class="payment-mode">
                                            <h4>{{ t("message.payment_method") }}</h4>
                                            <p>{{ t("message." + order.payment_method) }}</p>
                                        </div>

                                        <div v-if="order.comment">
                                            <h4>{{ t("message.comment") }}</h4>
                                            <p>{{ order.comment }}</p>
                                        </div>

                                        <!-- <div class="delivery-sec">
                                        <h3>
                                            expected date of delivery:
                                            <span>{{ order.expectedDateOfDelivery }}</span>
                                        </h3>
                                        <router-link to="/order_tracking" href="javascript:void(0)">track
                                            order</router-link>
                                    </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- section end -->
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import { useOrderStore } from "@/stores/orderStore";
import { useI18n } from "vue-i18n";
import { useRouter, useRoute } from "vue-router";
import { add, format } from 'date-fns'
/*
  multilang
*/
const { t } = useI18n();

/**
 * data
 */
let order = ref([]);

/*
  route
*/
const route = useRoute();
const id = computed(() => {
    return route.params.id;
});

/*
  stores
*/
let orderStore = useOrderStore();

const router = useRouter();

/*
  events
*/
onMounted(async () => {
    if (id.value > 0) {
        order.value = await orderStore.get(id.value);
    }
});

const changeOrderStatus = async (event, item) => {
    const response = await orderStore.changeOrderStatus(item.id, event.target.value);
    if (response.data) {
        item.stock_status = event.target.value;
    }
};
const isJSON = (str) => {
    try {
        JSON.parse(str);
        return true;
    } catch (error) {
        return false;
    }
};
const getAddressDetail = (addressString, detailName) => {
    try {
        const address = JSON.parse(addressString);
        return address[detailName] || '-';
    } catch (error) {
        return addressString; // Ha  JSON formátumban nem érkezik az adat,  megjelenítjük az eredeti stringet
    }
};
function goBack() {
    router.go(-1);
}

function getImageUrl(path) {
    return require("@/assets/images/" + path);
}

</script>

<style></style>
