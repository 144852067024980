import { defineStore } from "pinia";

export let useAuthStore = defineStore('auth', {
    state: () => ({
        authUser: null
    }),
    getters:{
       user: (state) => state.authUser
    },

    actions: {
        async getToken() {
            await axios.get(`${process.env.VUE_APP_AUTH_BASE_URL}/sanctum/csrf-cookie`)
        },
        async getUser() {
            await this.getToken();
            const data = await axios.get(`${process.env.VUE_APP_AUTH_BASE_URL}/api/admin-user`)
            this.authUser = data.data;
        },
        async login(data) {
            try {
                await this.getToken();
                const response = await axios.post(`${process.env.VUE_APP_AUTH_BASE_URL}/api/admin/admin-login`, {
                    email: data.email,
                    password: data.password
                });
                if(response.status === 200){
                    // with router.push not working after logging out
                    window.location.href = '/';
                }
            } catch (error) {
                if (error.response && error.response.status === 422) {
                    throw new Error('authError');
                } else {
                    this.router.push('/');
                }
            }
        },
        async logout() {
            await axios.post(`${process.env.VUE_APP_AUTH_BASE_URL}/api/admin/admin-logout`);
            this.authUser = null;
            this.router.push('/log_in');
        }
    }
});


