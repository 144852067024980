const popupMessages = {
    en: {
        'start_date' : 'Start date',
        'end_date' : 'End date',
        'link_name ' : 'Link name',
    },
    hu: {
        'start_date' : 'Kezdő dátum',
        'end_date' : 'Lejárati dátum',
        'link_name ' : 'Link név',

    },
    ro: {
        'start_date' : 'Data de începere',
        'end_date' : 'Data finală',
        'link_name ' : 'Link nume',

    }
}

export default popupMessages