<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <!-- Table Start -->
        <div class="card-body">
          <div class="table-responsive table-desi">
            <table
              class="user-table trans-table texes-table table table-striped"
            >
              <thead>
                <tr>
                  <th>Tax Detail</th>
                  <th>Tax Schedule</th>
                  <th>Tax Rate</th>
                  <th>Total Tax Amount</th>
                  <th>Options</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>USASTE-PS6N0</td>
                  <td>USASTCITY-6 <span class="theme-color">*</span></td>
                  <td>10%</td>
                  <td>15.24</td>
                  <td>
                    <ul>
                      <li>
                        <router-link
                          to="/order_detail"
                          href="javascript:void(0)"
                        >
                          <span class="lnr lnr-eye"></span>
                        </router-link>
                      </li>
                      <li>
                        <a href="javascript:void(0)">
                          <span class="lnr lnr-pencil"></span>
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">
                          <span class="lnr lnr-trash"></span>
                        </a>
                      </li>
                    </ul>
                  </td>
                </tr>

                <tr>
                  <td>USASTE-PS6N0</td>
                  <td>USASTCITY-6 <span class="theme-color">*</span></td>
                  <td>18%</td>
                  <td>5.29</td>
                  <td>
                    <ul>
                      <li>
                        <router-link
                          to="/order_detail"
                          href="javascript:void(0)"
                        >
                          <span class="lnr lnr-eye"></span>
                        </router-link>
                      </li>
                      <li>
                        <a href="javascript:void(0)">
                          <span class="lnr lnr-pencil"></span>
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">
                          <span class="lnr lnr-trash"></span>
                        </a>
                      </li>
                    </ul>
                  </td>
                </tr>

                <tr>
                  <td>USASTE-PS6N0</td>
                  <td>USASTCITY-6 <span class="theme-color">*</span></td>
                  <td>25%</td>
                  <td>4.78</td>
                  <td>
                    <ul>
                      <li>
                        <router-link
                          to="/order_detail"
                          href="javascript:void(0)"
                        >
                          <span class="lnr lnr-eye"></span>
                        </router-link>
                      </li>
                      <li>
                        <a href="javascript:void(0)">
                          <span class="lnr lnr-pencil"></span>
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">
                          <span class="lnr lnr-trash"></span>
                        </a>
                      </li>
                    </ul>
                  </td>
                </tr>

                <tr>
                  <td>USASTE-PS6N0</td>
                  <td>USASTCITY-6 <span class="theme-color">*</span></td>
                  <td>40%</td>
                  <td>3.20</td>
                  <td>
                    <ul>
                      <li>
                        <router-link
                          to="/order_detail"
                          href="javascript:void(0)"
                        >
                          <span class="lnr lnr-eye"></span>
                        </router-link>
                      </li>
                      <li>
                        <a href="javascript:void(0)">
                          <span class="lnr lnr-pencil"></span>
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">
                          <span class="lnr lnr-trash"></span>
                        </a>
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>USASTE-PS6N0</td>
                  <td>USASTCITY-6 <span class="theme-color">*</span></td>
                  <td>80%</td>
                  <td>8.4</td>
                  <td>
                    <ul>
                      <li>
                        <router-link
                          to="/order_detail"
                          href="javascript:void(0)"
                        >
                          <span class="lnr lnr-eye"></span>
                        </router-link>
                      </li>
                      <li>
                        <a href="javascript:void(0)">
                          <span class="lnr lnr-pencil"></span>
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">
                          <span class="lnr lnr-trash"></span>
                        </a>
                      </li>
                    </ul>
                  </td>
                </tr>

                <tr>
                  <td>USASTE-PS6N0</td>
                  <td>USASTCITY-6 <span class="theme-color">*</span></td>
                  <td>50%</td>
                  <td>4.78</td>
                  <td>
                    <ul>
                      <li>
                        <router-link
                          to="/order_detail"
                          href="javascript:void(0)"
                        >
                          <span class="lnr lnr-eye"></span>
                        </router-link>
                      </li>
                      <li>
                        <a href="javascript:void(0)">
                          <span class="lnr lnr-pencil"></span>
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">
                          <span class="lnr lnr-trash"></span>
                        </a>
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- Table End -->

        <!-- Pagination Start -->
        <div class="pagination-box">
          <nav class="ms-auto me-auto" aria-label="...">
            <ul class="pagination pagination-primary">
              <li class="page-item disabled">
                <a class="page-link" href="javascript:void(0)">Previous</a>
              </li>
              <li class="page-item active">
                <a class="page-link" href="javascript:void(0)">1</a>
              </li>
              <li class="page-item">
                <a class="page-link" href="javascript:void(0)">2</a>
              </li>
              <li class="page-item">
                <a class="page-link" href="javascript:void(0)">3</a>
              </li>
              <li class="page-item">
                <a class="page-link" href="javascript:void(0)">Next</a>
              </li>
            </ul>
          </nav>
        </div>
        <!-- Pagination End -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  title: "Voxo-Taxes",
};
</script>

<style></style>
