<template>
    <div class="row">
      <div class="col-sm-12">
        <div class="page-header close_icon">
          <div class="header-wrapper row m-0">
            <form
              class="form-inline search-full col custom-search"
              action="javascript:void(0)"
              method="get"
            >
              <div class="form-group w-100">
                <div class="Typeahead Typeahead--twitterUsers">
                  <div class="u-posRelative">
                    <input
                      class="demo-input Typeahead-input form-control-plaintext w-100"
                      type="text"
                      :placeholder="$t('message.search')"
                      name="q"
                      title=""
                      autofocus
                      v-model="searchText"
                      @keyup="searchProducts"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="card">
          <stockTableList
            v-if="products.length"
            :columns="columns"
            :listItems="products"
            :store="stockStore"
            :page="page"
            :totalPages="totalPages"
          />
        </div>
      </div>
    </div>
</template>
  
  <script setup>
  import { computed, onMounted, ref, watch } from "vue";
  import { useStockStore } from "@/stores/stockStore";
  import { useI18n } from "vue-i18n";
  import stockTableList from "@/components/custom/stockTableList";
  import { useRoute, useRouter } from "vue-router";
  import debounce from 'lodash.debounce';

  /*
      multilang
    */
  const { t } = useI18n();
  
  /*
      data
    */
  const products = computed(() => {
    if (path.value == "/stock_list") {
      return stockStore.products;
    }
  });
  const totalPages = computed(() => {
    return stockStore.total_pages;
  });
  const columns = [
    { name: "ID", data: "id" },
  ];
  const searchText = ref();
  
  /*
      route
    */
  const route = useRoute();
  const router = useRouter();
  const path = ref(route.path);
  const page = computed(() => {
    return parseInt(route.query.page);
  });
  
  /*
      stores
    */
  let stockStore = useStockStore();
  
  /*
      events
    */
  onMounted(() => {
    getList(route.query.page);
  });
  
  watch(
    () => route.query.page,
    (newPage) => {
      getList(newPage);
    }
  );
  
  watch(
    () => route.path,
    (newPath) => {
      path.value = newPath;
      getList();
    }
  );
  
  const getList = debounce(async (page = 1, search = null) => {
    if (path.value == "/stock_list") {
      await stockStore.fill(page, search);
    }
  }, 1000);
  
  const searchProducts = () => {
    router.push({ query: {page: 1} })
    getList(1, searchText.value);
  }
  
  
  </script>
  
  <style scoped>
  .page-header.close_icon {
    position: unset;
    margin-left: 0 !important;
    width: calc(100% - 50px) !important;
    background-color: #fbfeff;
    box-shadow: unset;
  }
  
  .header-wrapper {
    padding: 22px 0px !important;
  }

  /* search input not to dissapear at mobile */
  @media only screen and (max-width: 762px){
      .custom-search {
        display: flex !important;
        position: relative !important;
        .form-group {
          margin-top: 0 !important;
        }
    }
  }
  </style>
  