import { defineStore } from "pinia";

export let usePostStore = defineStore('post', {
    state: () => ({
        posts: [],
        posts_trashed: [],
        total_pages:0
    }),

    actions: {
        async fill(page = 1,search = null) {
            try {
                let link = `/posts?page=${page}`;
                if (search !== null) {
                    link += `&search=${search}`;
                }
                let paginated = search == null || search.length < 2;
                const response = await axios.get(link);
                this.posts = paginated ? response.data.data : response.data;
                this.total_pages = paginated ? response.data.last_page : 1;
            }
            catch (error) {
                console.log(error)
            }
        },
        async get(id) {
            try {
                return await axios
                .get("/posts/" + id).then(function (response) {
                    return response;
                });
            }
            catch(error){
                console.log(error);
            }
        },
        async getTrashed(page = 1,search = null) {
            try {
                let link = `/posts-trash?page=${page}`;
                if(search !== null){ link += `&search=${search}`;}
                let paginated = search == null || search.length < 3;
                const response = await axios.get(link);
                this.posts_trashed = paginated ? response.data.data : response.data;
                this.total_pages = paginated ? response.data.last_page : 1;
            }
            catch (error) {
                console.log(error)
            }
        },
        async create(post) {
            try {
                return await axios
                .post("/posts", post).then(function (response) {
                    return response;
                });
            }
            catch(error){
                return error.response;
            }
        },
        async update(id, post) {
            try {
                return await axios
                .put("/posts/" + id, post).then(function (response) {
                    return response;
                });
            }
            catch(error){
                return error.response;
            }
        },
        async delete(id) {
            try {
                return axios
                .delete(
                    "/posts/" + id
                )
                .then(function (response) {
                    return response;
                });
            }
            catch(error){
                console.log(error);
            }
        },
        async deleteForever(id) {
            try {
                return axios
                .delete(
                    "/posts-delete-forever/" + id
                )
                .then(function (response) {
                    return response;
                });
            }
            catch(error){
                console.log(error);
            }
        },
        async restore(id) {
            try {
                return axios
                .post(
                    "/posts-restore/" + id
                )
                .then(function (response) {
                    return response;
                });
            }
            catch(error){
                console.log(error);
            }
        },
        async get_categories(id = null) {
            let link = id == null ? "/get-post-categories" : "/get-post-categories/" + id;
            try {
                return await axios.get(link).then(function (response) {
                return response;
            });
            }
            catch(error){
                console.log(error);
            }
        },
        async changeStatus(post, status) {
            try {
                return await axios
                .put("/posts-change-status/" + post + "/" + status).then(function (response) {
                    return response;
                });
            }
            catch(error){
                return error.response;
            }
        },
    }
});


