<template>
  <div class="card">
    <div class="card-body">
      <form class="theme-form theme-form-2 mega-form" @submit.prevent="savePopup">
        <language-inputs v-if="mainCode && fields && fields.length > 0" v-model:fields="fields" :fields="fields"
          :mainCode="mainCode" :fieldTypes="fieldTypes" />
        <div class="mt-3 lang-errors-container">
          <p class="text-danger" v-for="error of v$.fields.$errors" :key="error.$uid">
            <strong>{{
        t("validationErrors.lang_field", {
          field: t(`message.${error.$propertyPath.split(".")[2]}`),
          code: mainCode,
        })
      }}</strong>
          </p>
        </div>
        <div class="row mt-1">
          <div class="col-lg-6">
            <label class="form-label-title mb-2 form-label">Link</label>
            <input v-model="link" type="text" class="form-control" />
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-lg-6">
            <label class="form-label-title mb-2 form-label">{{ t("message.start_date") }}</label>
            <input v-model="start_date" type="date" class="form-control" />
            <p class="text-danger" v-for="error of v$.start_date.$errors" :key="error.$uid">
              <strong>{{ t(`validationErrors.${[error.$message]}`) }}</strong>
            </p>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-lg-6">
            <label class="form-label-title mb-2 form-label">{{ t("message.end_date") }}</label>
            <input v-model="end_date" type="date" class="form-control" />
            <p class="text-danger" v-for="error of v$.end_date.$errors" :key="error.$uid">
              <strong v-if="error.$validator == 'isEndDateValid'">{{ t(`validationErrors.${[error.$validator]}`) }}</strong>
              <strong v-else>{{ t(`validationErrors.${[error.$message]}`) }}</strong>
            </p>
          </div>
        </div>
        <div class="row">
          <label for="basic-media-input" class="form-label-title form-label mb-1 mt-2">{{ t("message.basic_media")
            }}</label>
          <Uploader v-if="basicMedia.saved && basicMedia.saved.length > 0 && id > 0" max="1" class="mt-1"
            server="/store-media" inputId="basic-media-input" :media="basicMedia.saved" :location="$storageLocation"
            :withIdFolder="true" @add="addBasicMedia" @remove="removeBasicMedia" maxFilesize="100" />
          <Uploader v-else max="1" class="mt-1" server="/store-media" @change="changeBasicMedia"
            inputId="basic-media-input" maxFilesize="100" />
        </div>
        <div class="row g-4">
          <div class="col-lg-6 text-center mt-5">
            <button :class="{ 'btn btn-primary': true, 'disabled': btnDisabled }">
              {{ t("message.save") }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeMount, computed, inject, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useLocaleStore } from "@/stores/locale";
import { usePopupStore } from "@/stores/popupStore";
import { fillTranslationFields } from "@/helpers/useTranslationFields";
import { useFieldStore } from "@/stores/fieldStore";
import languageInputs from "@/components/custom/languageInputs";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { useLanguageStore } from "@/stores/languageStore";
import { useRouter, useRoute } from "vue-router";
import Uploader from "vue-media-upload";
import { isEndDateValid } from "@/helpers/useIsEndDateValid";


/*
  multilang, notifications
*/
const { t } = useI18n();
const toast = inject("toast");

/*
  form data, data
*/
let fields = ref([]);
let fieldTypes = ref();
let start_date = ref();
let end_date = ref();
let link = ref();
let btnDisabled = ref(false);
const basicMedia = ref({ saved: [], added: [], removed: [] });
/*
  stores
*/
let activeLang = useLocaleStore();
let popupStore = usePopupStore();
let fieldStore = useFieldStore();
const languageStore = useLanguageStore();

const mainCode = computed(() => {
  return languageStore.mainCode;
});


/*
  validation
*/
const rules = {
  fields: {
    0: { title: { [mainCode.value]: { required } } },
    1: { body: { [mainCode.value]: { required } } }
  },
  start_date: { required },
  end_date: { required, isEndDateValid: () => isEndDateValid(start_date.value, end_date.value) },
};




const v$ = useVuelidate(rules, { fields, start_date, end_date });
/*
  router
*/
const router = useRouter();
const route = useRoute();
const id = computed(() => {
  return route.params.id;
});

/*
  events
*/
onBeforeMount(async () => {
  await languageStore.getMainCode();
});

onMounted(async () => {
  await getFields();
  if (id.value > 0) {
    const response = await popupStore.get(id.value);
    fillTranslationFields(fields, response.data.translations);
    link.value = response.data.link;
    start_date.value = response.data.start_date;
    end_date.value = response.data.end_date;
    let basic = response.data.media.filter(item => item.collection_name === 'basic')[0];
    basicMedia.value.saved = basic ? [{ id: basic.id, name: basic.file_name }] : [];
  }
});

const savePopup = async () => {
  btnDisabled.value = true;
  const request = {
    lang: activeLang.locale,
    translations: fields.value,
    start_date: start_date.value,
    end_date: end_date.value,
    link: link.value,
    basicMedia: id.value > 0 ? basicMedia.value : basicMedia.value.added,
  };
  const isValid = await v$.value.$validate();

  if (isValid) {
    const response =
      id.value > 0
        ? await popupStore.update(id.value, request)
        : await popupStore.create(request);

    if (response && response.data.message == "success") {
      toast.success(t("message.success_message"));
      id.value > 0 ? router.go(-1) : router.push({ name: "popup_list", query: { page: response.data.lastPage } });
    } else {
      if (response.status == 422) {
        toast.error(t("message.lang_name_error"));
      } else {
        toast.error(t("message.error_message"));
      }
    }
  }
  btnDisabled.value = false;
};

watch(
  () => route.path,
  (newPath) => {
    if (newPath == "/create_popup") {
      getFields();
      start_date.value = "";
      end_date.value = "";
      link.value = "";
      basicMedia.value = {};
    }
  }
);

const getFields = async () => {
  const fieldsResponse = await fieldStore.fill("popup_translations");
  fields.value = fieldsResponse.data;
  const typesResponse = await fieldStore.getFieldTypes("popup_translations");
  
  fieldTypes.value = typesResponse.data;
};

const changeBasicMedia = (basic) => {
  basicMedia.value.added = basic;
};

const addBasicMedia = (addedImage, added) => {
  basicMedia.value.added = added;
};

const removeBasicMedia = (removedImage, removed) => {
  basicMedia.value.removed = removed;
};

</script>