import { defineStore } from "pinia";

export let useOrderStore = defineStore('order', {
    state: () => ({
        orders: [],
        total_pages:0
    }),

  
    actions: {
        async fill(status = 1, page = 1, search = null) {
            try {
                let link = `/orders?status=${status}&page=${page}`;
                
                if (search !== null) {
                    link += `&search=${search}`;
                }
                
                let paginated = search == null || search.length < 2;
    
                const response = await axios.get(link);
                this.orders = paginated ? response.data.data : response.data;
                this.total_pages = paginated ? response.data.last_page : 1;
            } catch (error) {
                console.log(error);
            }
        },
        async get(id) {
            try {
                return await axios
                .get("/orders/" + id).then(function (response) {
                    return response.data;
                });
            }
            catch(error){
                console.log(error);
            }
        },
        // async get_products(id = null) {
        //     let link = id == null ? "/get-coupon-products" : "/get-coupon-products/" + id;
        //     try {
        //         return await axios.get(link).then(function (response) {
        //         return response;
        //     });
        //     }
        //     catch(error){
        //         console.log(error);
        //     }
        // },
        async changePayed(order, payed) {
            try {
                return await axios
                .put("/order-change-payed/" + order + "/" + payed).then(function (response) {
                    return response;
                });
            }
            catch(error){
                return error.response;
            }
        },
        async changeOrderStatus(order, stock_status) {            
            try {
                return await axios
                    .put(`/order-change-status/${order}/${stock_status}`)
                    .then(function (response) {
                        return response;  
                    });
            } catch (error) {
                return error.response; 
            }
            
    }
}
});


