import { defineStore } from "pinia";

export let useProductStore = defineStore('product', {
    state: () => ({
        products: [],
        products_trashed: [],
        total_pages:0
    }),

    actions: {
        async fill(page = 1, search = null, category = null, brand = null) {
            try {
                let link = `/products?page=${page}`;
                if(search !== null){ link += `&search=${search}`;}
                if(category !== null){ link += `&category=${category}`;}
                if(brand !== null){ link += `&brand=${brand}`;}
                let paginated = search == null || search.length < 2;
                const response = await axios.get(link);
                this.products = paginated ? response.data.data : response.data;
                this.total_pages = paginated ? response.data.last_page : 1;
            }
            catch (error) {
                console.log(error)
            }
        },
        async get(id) {
            try {
                return await axios
                .get("/products/" + id).then(function (response) {
                    return response;
                });
            }
            catch(error){
                console.log(error);
            }
        },
        async getTrashed(page = 1, search = null, category = null, brand = null) {
            try {
                let link = `/products-trash?page=${page}`;
                if(search !== null){ link += `&search=${search}`;}
                if(category !== null){ link += `&category=${category}`;}
                if(brand !== null){ link += `&brand=${brand}`;}
                let paginated = search == null || search.length < 3;
                const response = await axios.get(link);
                this.products_trashed = paginated ? response.data.data : response.data;
                this.total_pages = paginated ? response.data.last_page : 1;
            }
            catch (error) {
                console.log(error)
            }
        },
        async create(product) {
            try {
                return await axios
                .post("/products", product).then(function (response) {
                    return response;
                });
            }
            catch(error){
                return error.response;
            }
        },
        async update(id, product) {
            try {
                return await axios
                .put("/products/" + id, product).then(function (response) {
                    return response;
                });
            }
            catch(error){
                return error.response;
            }
        },
        async delete(id) {
            try {
                return axios
                .delete(
                    "/products/" + id
                )
                .then(function (response) {
                    return response;
                });
            }
            catch(error){
                console.log(error);
            }
        },
        async deleteForever(id) {
            try {
                return axios
                .delete(
                    "/products-delete-forever/" + id
                )
                .then(function (response) {
                    return response;
                });
            }
            catch(error){
                console.log(error);
            }
        },
        async restore(id) {
            try {
                return axios
                .post(
                    "/products-restore/" + id
                )
                .then(function (response) {
                    return response;
                });
            }
            catch(error){
                console.log(error);
            }
        },
        async changeStatus(product, status) {
            try {
                return await axios
                .put("/products-change-status/" + product + "/" + status).then(function (response) {
                    return response;
                });
            }
            catch(error){
                return error.response;
            }
        },
        async get_labels(id = null) {
            let link = id == null ? "/get-product-labels" : "/get-product-labels/" + id;
            try {
                return await axios.get(link).then(function (response) {
                return response;
            });
            }
            catch(error){
                console.log(error);
            }
        },
        async get_categories(id = null) {
            let link = id == null ? "/get-product-categories" : "/get-product-categories/" + id;
            try {
                return await axios.get(link).then(function (response) {
                return response;
            });
            }
            catch(error){
                console.log(error);
            }
        },
    }
});


