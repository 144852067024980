<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="page-header close_icon">
        <div class="header-wrapper row m-0">
          <form class="form-inline search-full col custom-search" action="javascript:void(0)" method="get">
            <div class="form-group w-100">
              <div class="Typeahead Typeahead--twitterUsers">
                <div class="u-posRelative">
                  <input class="demo-input Typeahead-input form-control-plaintext w-100" type="text"
                    :placeholder="$t('message.search')" name="q" title="" autofocus v-model="searchText"
                    @keyup="searchPost" />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="card">
        <p v-if="noPostFound" class="m-3">{{ $t('message.no_result') }}</p>
        <tableList v-if="posts.length" :columns="columns" :listItems="posts" :options="options" :store="postStore"
          :withStatusChange="true" :page="page" :totalPages="totalPages" />
        <ul v-if="posts.length">
          <li v-for="post in posts" :key="post.id">
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, onMounted, ref, watch } from "vue";
import { usePostStore } from "@/stores/postStore";
import { useI18n } from "vue-i18n";
import tableList from "@/components/custom/tableList";
import { useRoute, useRouter } from "vue-router";
import debounce from 'lodash.debounce';

/*
  multilang
*/
const { t } = useI18n();

/*
  data
*/
const posts = computed(() => {
  if (path.value == "/post_list") {
    return postStore.posts;
  } else {
    return postStore.posts_trashed;
  }
});
const columns = [
  { name: "ID", data: "id" },
  { name: t("message.title"), data: "title" },
  { name: t("message.locale"), data: "locale" },
  { name: t("message.published_date"), data: "published_at" },
];
const options = ref([]);
const totalPages = computed(() => {
  return postStore.total_pages;
});

const searchText = ref();
let noPostFound = ref(false);

/*
  route
*/
const route = useRoute();
const router = useRouter();
const path = ref(route.path);
const page = computed(() => {
  return parseInt(route.query.page);
});

/*
  stores
*/
let postStore = usePostStore();


/*
  events
*/
onMounted(() => {
  getList(route.query.page);
});

watch(
  () => route.path,
  (newPath) => {
    path.value = newPath;
    searchText.value = '';
    noPostFound.value = false;
    getList();
  }
);

watch(
  () => route.query.page,
  (newPage) => {
    getList(newPage);
  },
);

const getList = debounce(async (page = 1, search = null) => {
  try {
    if (path.value == "/post_list") {
      await postStore.fill(page, search);
      options.value = [
        { type: "edit_link", name: "edit_post" },
        { type: "event", icon: "lnr lnr-trash", event_type: "trash" },
      ];
    } else {
      await postStore.getTrashed(page, search);
      options.value = [
        { type: "event", icon: "lnr lnr-undo", event_type: "restore" },
        { type: "edit_link", name: "edit_post" },
        { type: "event", icon: "lnr lnr-trash", event_type: "delete" },
      ];
    }
    noPostFound.value = postStore.posts.length === 0;
  } catch (error) {
    console.error("Error in getList:", error);
  }
}, 1000);

const searchPost = () => {
  router.push({ query: { page: 1 } });
  getList(1, searchText.value);
};
</script>
<style scoped>
.page-header.close_icon {
  position: unset;
  margin-left: 0 !important;
  width: calc(100% - 50px) !important;
  background-color: #fbfeff;
  box-shadow: unset;
}

.header-wrapper {
  padding: 22px 0px !important;
}

/* search input not to dissapear at mobile */
@media only screen and (max-width: 762px) {
  .custom-search {
    display: flex !important;
    position: relative !important;

    .form-group {
      margin-top: 0 !important;
    }
  }
}
</style>