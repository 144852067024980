import { defineStore } from "pinia";

export let useCouponStore = defineStore('coupon', {
    state: () => ({
        coupons: [],
        coupons_trashed: [],
        total_pages:0
    }),

    actions: {
        async fill(page = 1, search = null) {
            try {
                let link = `/coupons?page=${page}`;

                if (search !== null) {
                    link += `&search=${search}`;
                }
                let paginated = search == null || search.length < 2;
                const response = await axios.get(link);
                this.coupons = paginated ? response.data.data : response.data;
                this.total_pages = paginated ? response.data.last_page : 1;
            } catch (error) {
                console.log(error);
            }
        },
        
        async get(id) {
            try {
                return await axios.get("/coupons/" + id).then(function (response) {
                    return response;
                });
            } catch (error) {
                console.log(error);
            }
        },
        async getTrashed(page = 1, search = null) {
            try {
                let link = `/coupons-trash?page=${page}`;
                if(search !== null){ link += `&search=${search}`;}
                let paginated = search == null || search.length < 3;
                const response = await axios.get(link);
                this.coupons_trashed = paginated ? response.data.data : response.data;
                this.total_pages = paginated ? response.data.last_page : 1;
            } catch (error) {
                console.log(error);
            }
        },
        async create(coupon) {
            try {
                return await axios
                .post("/coupons", coupon).then(function (response) {
                    return response;
                });
            }
            catch(error){
                return error.response;
            }
        },
        async update(id, coupon) {
            try {
                return await axios
                .put("/coupons/" + id, coupon).then(function (response) {
                    return response;
                });
            }
            catch(error){
                return error.response;
            }
        },
        async delete(id) {
            try {
                return axios
                .delete(
                    "/coupons/" + id
                )
                .then(function (response) {
                    return response;
                });
            }
            catch(error){
                console.log(error);
            }
        },
        async deleteForever(id) {
            try {
                return axios
                .delete(
                    "/coupons-delete-forever/" + id
                )
                .then(function (response) {
                    return response;
                });
            }
            catch(error){
                console.log(error);
            }
        },
        async restore(id) {
            try {
                return axios
                .post(
                    "/coupons-restore/" + id
                )
                .then(function (response) {
                    return response;
                });
            }
            catch(error){
                console.log(error);
            }
        },
        async get_products(id = null) {
            let link = id == null ? "/get-coupon-products" : "/get-coupon-products/" + id;
            try {
                return await axios.get(link).then(function (response) {
                return response;
            });
            }
            catch(error){
                console.log(error);
            }
        },
        async changeStatus(coupon, status) {
            try {
                return await axios
                .put("/coupons-change-status/" + coupon + "/" + status).then(function (response) {
                    return response;
                });
            }
            catch(error){
                return error.response;
            }
        },
    }
});


