<template>
  <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
    <li
      class="nav-item"
      role="presentation"
      v-for="(languageCode, index) in languageCodes"
      :key="index"
    >
      <button
        class="nav-link"
        :class="{ active: languageCode == activeTab, 'text-uppercase': true }"
        id="pills-home-tab"
        type="button"
        @click="changeTab(languageCode)"
      >
        {{ languageCode }}
      </button>
    </li>
  </ul>

  <div class="tab-content" id="pills-tabContent">
    <template v-for="(languageCode, index) in languageCodes" :key="index">
      <div
        v-if="activeTab == languageCode"
        class="tab-pane fade show active"
        id="pills-home"
        role="tabpanel"
      >
        <div class="row g-4">
          <div class="col-lg-6">
            <div v-for="field in fields">
              <div v-for="(f, findex) in field">
                <template v-for="type in fieldTypes">
                  <label style="text-transform:capitalize" v-if="type.type != 'without_type' && findex == type.field_name" class="form-label-title my-2 form-label"
                  >{{ t(`message.${findex}`) }} <span>({{ languageCode }})</span></label
                >
                  <input
                    v-if="type.field_name == findex && type.type == 'input'"
                    v-model="field[findex][languageCode]"
                    type="text"
                    class="form-control"
                  />
                  <textarea
                    v-if="type.field_name == findex && type.type == 'textarea'"
                    v-model="field[findex][languageCode]"
                    class="form-control" rows="10"
                  ></textarea>
                  <ckeditor
                    v-if="type.field_name == findex && type.type == 'editor'"
                    v-model="field[findex][languageCode]"
                    :editor="editor"
                  ></ckeditor>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { useLanguageStore } from "@/stores/languageStore";
import { useI18n } from "vue-i18n";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

/*
  props
*/
const props = defineProps({
  fields: Array,
  mainCode: String,
  fieldTypes: Array,
});

const editor = ClassicEditor;

/*
  multilang
*/
const { t } = useI18n();

/*
  data
*/
const languageCodes = computed(() => {
  return languageStore.languageCodes;
});
let activeTab = ref(props.mainCode);

/*
  stores
*/
const languageStore = useLanguageStore();

/*
  events
*/
onMounted(async () => {
  languageStore.fill();
});

function changeTab(code) {
  activeTab.value = code;
}
</script>
