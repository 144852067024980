<template>
    <div class="card">
        <div class="card-body">
            <form class="theme-form theme-form-2 mega-form" @submit.prevent="saveDeliveryPrice">
                <div class="row mt-1 mb-2">
                    <div class="col-lg-6">
                        <label class="form-label-title mb-2 form-label">{{
                            t("message.start_date")
                        }}</label>
                        <input v-model="date_from" class="form-control" type="date" />
                    </div>
                </div>
                <div class="row mt-1 mb-2">
                    <div class="col-lg-6">
                        <label class="form-label-title mb-2 form-label">{{
                            t("message.end_date")
                        }}</label>
                        <input v-model="date_to" class="form-control" type="date" />
                    </div>
                    <p class="text-danger" v-for="error of v$.date_to.$errors" :key="error.$uid">
                        <strong>{{ t(`validationErrors.${[error.$validator]}`) }}</strong>
                    </p>
                </div>
                <div class="row mt-1 mb-2">
                    <div class="col-lg-6">
                        <label class="form-label-title mb-2 form-label">{{
                            t("message.country")
                        }}</label>
                        <select v-model="v$.selectedCountry.$model" id="countries" class="form-control"></select>
                        <p class="text-danger" v-for="error of v$.selectedCountry.$errors" :key="error.$uid">
                            <strong>{{ t(`validationErrors.${[error.$message]}`) }}</strong>
                        </p>
                    </div>
                </div>
                <div class="row mt-1 mb-2">
                    <div class="col-lg-6">
                        <label class="form-label-title mb-2 form-label">{{
                            t("message.county")
                        }}</label>
                        <select v-model="selectedCounty" id="counties" class="form-control" @change="listCities($event)"></select>
                    </div>
                </div>
                <div class="row mt-1 mb-2">
                    <div class="col-lg-6">
                        <label class="form-label-title mb-2 form-label">{{
                            t("message.city")
                        }}</label>
                        <select v-model="selectedCity" id="cities" class="form-control" disabled></select>
                    </div>
                </div>
                <div class="row mt-1">
                    <div class="col-lg-6">
                        <label class="form-label-title mb-2 form-label">{{ t("message.price") }}</label>
                        <input v-model="price" type="text" class="form-control" />
                        <p class="text-danger" v-for="error of v$.price.$errors" :key="error.$uid">
                            <strong>{{ t(`validationErrors.${[error.$message]}`) }}</strong>
                        </p>
                    </div>
                </div>


                <div class="row g-4">
                    <div class="col-lg-6 text-center mt-5">
                        <button
                            :class="{ 'btn btn-primary': true, disabled: btnDisabled }">
                            {{ t("message.save") }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>
  
<script setup>
import { ref, onMounted, computed, inject, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useLocaleStore } from "@/stores/locale";
import { useDeliveryPriceStore } from "@/stores/deliveryPriceStore";
import { useVuelidate } from "@vuelidate/core";
import { required, numeric } from "@vuelidate/validators";
import { useRouter, useRoute } from "vue-router";
import { isEndDateValid } from "@/helpers/useIsEndDateValid";
import { initChoice } from "@/helpers/useInitChoice";

/*
    multilang, notifications
*/
const { t } = useI18n();
const toast = inject("toast");

/*
    form data, data
*/
let btnDisabled = ref(false);
let date_from = ref();
let date_to = ref();
let price = ref();
let countries = ref([]);
let counties = ref([]);
let cities = ref([]);
let selectedCountry = ref();
let selectedCounty = ref();
let selectedCity = ref();
let country_name = ref();
let county_name = ref();
let city_name = ref();
let countrySelect = ref();
let countySelect = ref();
let citySelect = ref();

/*
    stores
  */
let activeLang = useLocaleStore();
let deliveryPriceStore = useDeliveryPriceStore();

/*
    validation
*/
const rules = {
    date_to: { isEndDateValid: () => isEndDateValid(date_from.value, date_to.value) },
    price: { required, numeric },
    selectedCountry: { required },
};

const v$ = useVuelidate(rules, { price, selectedCountry, date_to });

/*
    router
  */
const router = useRouter();
const route = useRoute();
const id = computed(() => {
    return route.params.id;
});


onMounted(async () => {
    if (id.value > 0) {
        initChoice("countries", countrySelect, false);
        initChoice("counties", countySelect, false);
        initChoice("cities", citySelect, false);
        const response = await deliveryPriceStore.get(id.value);
        price.value = response.data.price;
        selectedCountry.value = response.data.country_id;
        selectedCounty.value = response.data.county_id;
        selectedCity.value = response.data.city_id;
        date_from.value = response.data.date_from;
        date_to.value = response.data.date_to;
        country_name.value = response.data.country_name;
        county_name.value = response.data.county_name;
        city_name.value = response.data.city_name;
        getCountries();
        getCounties();
        getCities(selectedCounty.value);
        countrySelect.value.setChoices([{ value: selectedCountry.value, label: country_name.value, selected: true }], "value", "label", true);
        countySelect.value.setChoices([{ value: selectedCounty.value, label: county_name.value, selected: true }], "value", "label", true);
        citySelect.value.setChoices([{ value: selectedCity.value, label: city_name.value, selected: true }], "value", "label", true);
    } else {
        initChoice("countries", countrySelect, false);
        initChoice("counties", countySelect, false);
        initChoice("cities", citySelect, false);
        getCountries();
        getCounties();
        getCountries();
    }
});

const saveDeliveryPrice = async () => {
    btnDisabled.value = true;
    const request = {
        lang: activeLang.locale,
        country_id: selectedCountry.value,
        county_id: selectedCounty.value,
        city_id: selectedCity.value,
        price: price.value,
        date_from: date_from.value,
        date_to: date_to.value
    };
    const isValid = await v$.value.$validate();

    if (isValid) {
        const response =
            id.value > 0
                ? await deliveryPriceStore.update(id.value, request)
                : await deliveryPriceStore.create(request);

        if (response && response.data.message == "success") {
            toast.success(t("message.success_message"));
            id.value > 0 ? router.go(-1) : router.push({ name: "delivery_price_list", query: { page: response.data.lastPage } });
        } else {
            toast.error(t("message.error_message"));
        }
    }
    btnDisabled.value = false;
};

watch(
    () => route.path,
    async (newPath) => {
        if (newPath == "/create_delivery_price") {
            await getCountries();
            await getCounties();
            countrySelect.value.destroy();
            countySelect.value.destroy();
            citySelect.value.destroy();
            initChoice("countries", countrySelect, true);
            initChoice("counties", countySelect, true);
            initChoice("cities", citySelect, true);
            selectedCountry.value = "";
            selectedCounty.value = "";
            selectedCity.value = "";
            price.value = "";
            date_from.value = "";
            date_to.value = "";
        }
    }
);

const listCities = async (event) => {
    getCities(event.target.value);
}

function getCountries() {
    countrySelect.value.removeActiveItems();
    (async () => {
        await deliveryPriceStore.getCountries().then(function (response) {
            let datas = response;
            let choices = [];
            datas.forEach((val, key) => {
                choices[key] = {};
                choices[key].value = val.id;
                choices[key].label = val.name_ro == null ? t('message.without_name') : val.name_ro;
                choices[key].selected = val.id == 179 ? true : false;
            });
            countries.value = choices;
            countrySelect.value.setChoices(countries.value, "value", "label", true);
            selectedCountry.value = 179;
        });
    })();
}

function getCounties() {
    countySelect.value.removeActiveItems();
    (async () => {
        await deliveryPriceStore.getCounties().then(function (response) {
            let datas = response;
            let choices = [];
            datas.forEach((val, key) => {
                choices[key] = {};
                choices[key].value = val.id;
                choices[key].label = val.name_ro == null ? t('message.without_name') : val.name_ro;
                choices[key].selected = id.value ? val.selected : false;
            });
            counties.value = choices;
            countySelect.value.setChoices(counties.value, "value", "label", true);
        });
    })();
}

function getCities(county) {
    citySelect.value.removeActiveItems();
    citySelect.value.enable();
    (async () => {
        await deliveryPriceStore.getCities(county).then(function (response) {
            let datas = response;
            let choices = [];
            datas.forEach((val, key) => {
                choices[key] = {};
                choices[key].value = val.id;
                choices[key].label = val.name_ro == null ? t('message.without_name') : val.name_ro;
                choices[key].selected = id.value ? val.selected : false;
            });
            cities.value = choices;
            citySelect.value.setChoices(cities.value, "value", "label", true);
        });
    })();
}

</script>
  