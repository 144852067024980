const orderMessages = {
    en: {
        'new': 'New',
        'registered': 'Registered',
        'packaged': 'Packaged',
        'courier': 'With courier',
        'arrived': 'Arrived',
        'archived': 'Archived',
        'order_number': 'Order number',
        'payment_method': 'Payment method',
        'payed': 'Payed',
        'state': 'State',
        'date': 'Date',
        'cash': 'Cash',
        'cash_on_delivery': 'Cash on delivery',
        'bank_card': 'Bank card',
        'bank_transfer': 'Bank transfer',
        'courier ': 'Courier',
        'local_pickup ': 'Local pickup',
        'quantity': 'Quantity',
        'items': 'Items',
        'subtotal': 'Subtotal',
        'shipping': 'Shipping',
        'total_price': 'Total Price',
        'summary': 'Summary',
        'shipping_details': 'Shipping Details',
        'discount': 'Discount',
        'tel': 'Tel',
        'address': 'Address',
        'street' : 'Strada',
        'number' : 'Numărul',
        'staircase' : 'Scara',
        'floor' : 'Etaj',
        'apartman' : 'Apartman',
        'billing_details': 'Billing Details',
        'company_name': 'Company Name',
        'bank': 'Bank',
        'coupon': 'Coupon',
        'unit_price': 'Unit Price',
        'total_unit_price': 'Total Unit Price',
    },
    hu: {
        'new': 'Új',
        'registered': 'Regisztrált',
        'packaged': 'Csomagolt',
        'courier': 'Futárnál',
        'arrived': 'Kiszállítva',
        'archived': 'Arhiválva',
        'order_number': 'Rendelészszám',
        'payment_method': 'Fizetési mód',
        'payed': 'Fizetett',
        'state': 'Státusz',
        'date': 'Dátum',
        'cash': 'Készpénz',
        'cash_on_delivery': 'Készpénz a futárnál',
        'bank_card': 'Bankkártya',
        'bank_transfer': 'Banki átutalás',
        'courier ': 'Szállítás',
        'local_pickup ': 'Helyben átvétel',
        'quantity': 'Mennyiség',
        'items': 'Tételek',
        'subtotal': 'Részösszeg',
        'shipping': 'Szállítás',
        'total_price': 'Teljes Ár',
        'summary': 'Összegzés',
        'shipping_details': 'Szállítási Adatok',
        'discount': 'Leárazás',
        'tel': 'Tel',
        'address': 'Cím',
        'street' : 'Utca',
        'number' : 'Házszám',
        'staircase' : 'Lépcsőház',
        'floor' : 'Emelet',
        'apartman' : 'Ajtó',
        'billing_details': 'Számlázási Adatok',
        'company_name': 'Cégnév',
        'unique_registration_code': 'Adóazonosító szám',
        'registration_number': 'Cégjegyzékszám',
        'bank': 'Bank',
        'bank_account': 'Bankszámlaszám',
        'coupon': 'Kupon',
        'unit_price': 'Egységár',
        'total_unit_price': 'Teljes ár',
    },
    ro: {
        'new': 'Nou',
        'registered': 'Înregistrat',
        'packaged': 'Ambalat',
        'courier': 'La curier',
        'arrived': 'Expediat',
        'archived': 'Arhivat',
        'order_number': 'Numărul de comandă',
        'payment_method': 'Metodă de plată',
        'payed': 'Plătit',
        'state': 'Statul',
        'date': 'Data',
        'cash': 'Cash',
        'cash_on_delivery': 'Plată la ramburs',
        'bank_card': 'Card bancar',
        'bank_transfer': 'Transfer bancar',
        'courier ': 'Curier',
        'local_pickup ': 'Ridicare locală',
        'quantity': 'Cantitate',
        'items': 'Articole',
        'subtotal': 'Subtotal',
        'shipping': 'Expediere',
        'total_price': 'Preț Total',
        'summary': 'Sumar',
        'shipping_details': 'Detalii de Livrare',
        'discount': 'Discount',
        'tel': 'Tel',
        'address': 'Adresă',
        'billing_details': 'Detalli de Facturare',
        'company_name': 'Firma',
        'bank': 'Banca',
        'unique_registration_code': 'Cod Unic de Înregistrare',
        'registration_number': 'Număr de Înregistrare în Registrul Comerțului',
        'bank': 'Bank',
        'bank_account': 'Cont bancar',
        'coupon': 'Cupon',
        'unit_price': 'Preț unitar',
        'total_unit_price': 'Prețul total',
    }
}

export default orderMessages