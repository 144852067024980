<template>
  <div class="container-fluid p-0">
    <div class="row m-0">
      <div class="col-12 p-0">
        <div class="login-section">
          <div class="materialContainer">
            <div class="box">
              <div class="login-title">
                <h2>{{t('message.sign_in')}}</h2>
              </div>
              <div class="input">
                <label
                  for="email"
                  :style="[
                    { 'line-height': selected['email'] ? '18px' : '60px' },
                  ]"
                  >Email</label
                >
                <input
                  type="text"
                  name="email"
                  id="email"
                  v-model="emailField"
                  required
                  v-on:blur="handleFocusOut('email')"
                  @focus.prevent="select('email')"
                />
                <span
                  class="spin"
                  :style="[{ width: selected['email'] ? '100%' : '0%' }]"
                ></span>
                  <p class="text-danger" v-for="error of v$.emailField.$errors" :key="error.$uid">
                    <strong strong>{{ t(`validationErrors.${[error.$message]}`) }}</strong>
                  </p>
              </div>
              <div class="input">
                <label
                  for="password"
                  :style="[
                    { 'line-height': selected['password'] ? '18px' : '60px' },
                  ]"
                  >{{t('message.password')}}</label
                >
                <input
                  type="password"
                  name="password"
                  id="password"
                  v-model="passwordField"
                  v-on:blur="handleFocusOut('password')"
                  @focus.prevent="select('password')"
                />
                <span
                  class="spin"
                  :style="[{ width: selected['password'] ? '100%' : '0%' }]"
                ></span>
                <p class="text-danger" v-for="error of v$.passwordField.$errors" :key="error.$uid">
                  <strong strong>{{ t(`validationErrors.${[error.$message]}`) }}</strong>
                </p>
              </div>

              <div class="button login mt-5">
                <button
                  href="javascript:void(0)"
                  @click.prevent="handleLogin"
                  type="submit"
                >
                  <span>{{t('message.sign_in')}}</span>
                  <i class="fa fa-check"></i>
                </button>
                <p v-if="authError" class="text-danger">
                  <strong strong>{{ t('validationErrors.authError') }}</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, reactive } from "vue";
import { useAuthStore } from "@/stores/auth";
import { useVuelidate } from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import { useI18n } from "vue-i18n";

/*
  data
*/
const emailField = ref();
const passwordField = ref();

const selected = reactive({ emailField: false, passwordField: false });
const authError = ref(false);

/*
  multilang, notifications
*/
const { t } = useI18n();

/*
  stores
*/
const authStore = useAuthStore();

/*
  validation
*/
const rules = {
  emailField: { required, email },
  passwordField: { required }
};

const v$ = useVuelidate(rules, { emailField, passwordField });

/*
  events
*/
  onMounted(async () => {
    emailField.length != 0
      ? (selected.email = true)
      : (selected.email = false);
      passwordField.length != 0
      ? (selected.password = true)
      : (selected.password = false);
  });

const handleLogin = async () => {
  const isValid = await v$.value.$validate();
  authError.value = false;

  if (isValid) {
    try {
      await authStore.login({ email: emailField.value, password: passwordField.value });
    } catch (error) {
      if (error.message === 'authError') {
        authError.value = true;
      }
    }
  }
};

const handleFocusOut = (field) => {
  if (field.value === "") {
    selected[field] = false;
  }
};

const select = (textbox) => {
  selected[textbox] = true;
};

</script>

<style scoped>
input:active,
input:focus {
  outline: none; /* Remove the default outline style */
}
</style>

