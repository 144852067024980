import Choices from "choices.js";

const initChoice = async (elementId, select, removeItemButton) => {
    if (document.getElementById(elementId)) {
        var element = document.getElementById(elementId);
        const choice = new Choices(element, {
            allowHTML: true,
            removeItemButton: removeItemButton,
            itemSelectText: "",
        });
        select.value = choice;
    }
}
    
export {
    initChoice
}