const validationErrors = {
    en: {
        'Value is required': 'Required field',
        'lang_field': 'The field is required in the main language: {field}({code})',
        'Value must be numeric':'Value must be numeric',
        'Value must be decimal':'Value must be decimal',
        'This field should be at least 6 characters long':'This field should be at least 6 characters long',
        'The maximum length allowed is 6':'The maximum length allowed is 6',
        'isEndDateValid':'Must be equal or greater than the start date.',
        'Value is not a valid email address':'Value is not a valid email address',
        'authError':'Authentication error',
    },
    hu: {
        'Value is required': 'Kötelező mező',
        'lang_field': 'Kötelező mező a fő nyelven {field}({code})',
        'Value must be numeric':'Az értéknek numerikusnak kell lennie',
        'Value must be decimal':'Az értéknek decimálisnak kell lennie',
        'This field should be at least 6 characters long':'Ennek a mezőnek legalább 6 karakter hosszúnak kell lennie',
        'The maximum length allowed is 6':'A megengedett maximális hossz 6 karakter',
        'isEndDateValid':'A kezdő dátummal egyenlőnek vagy nagyobbnak kell lennie.',
        'Value is not a valid email address':'Nem érvényes e-mail cím',
        'authError':'Hitelesítési hiba',
    },
    ro: {
        'Value is required': 'Câmp obligatoriu',
        'lang_field': 'Câmp obligatoriu în limba principală {field}({code})',
        'Value must be numeric':'Valoarea trebuie să fie numerică',
        'Value must be decimal':'Valoarea trebuie să fie zecimală',
        'This field should be at least 6 characters long':'Acest câmp trebuie să conțină cel puțin 6 caractere',
        'The maximum length allowed is 6':'Lungimea maximă permisă este de 6',
        'isEndDateValid':'Trebuie să fie egală sau mai mare decât data de începere.',
        'Value is not a valid email address':'Valoare nu este o adresă de e-mail validă',
        'authError':'Eroare de autentificare',
    }
}

export default validationErrors