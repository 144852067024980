<template>
  <div class="container-fluid">
    <footer class="footer">
      <div class="row">
        <div class="col-md-12 footer-copyright text-center">
          <p class="mb-0">Copyright {{ new Date().getFullYear() }} © Helyénvaló Helyi Admin</p>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>
