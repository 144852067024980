import { defineStore } from "pinia";

export let usePopupStore = defineStore('popup', {
    state: () => ({
        popups: [],
        popups_trashed: [],
        total_pages:0
    }),

    actions: {
        async fill(page = 1, paginated = 1, search = null) {
            try {
                let link = paginated == 1 ? `/popups?page=${page}` : `/popups?paginated=0`;
                if(search !== null){ link += `&search=${search}`;}
                let paginatedResponse = (search == null || search.length < 2) && paginated == 1;
                const response = await axios.get(link);
                this.popups = paginatedResponse ? response.data.data : response.data;
                this.total_pages = paginatedResponse ? response.data.last_page : 1;
            }
            catch (error) {
                console.log(error)
            }
        },
        async get(id) {
            try {
                return await axios
                .get("/popups/" + id).then(function (response) {
                    return response;
                });
            }
            catch(error){
                console.log(error);
            }
        },
        async getTrashed(page = 1, paginated = 1, search = null) {
            try {
                let link = paginated == 1 ? `/popups-trash?page=${page}` : `/popups-trash?paginated=0`;
                if(search !== null){ link += `&search=${search}`;}
                let paginatedResponse = (search == null || search.length < 3) && paginated == 1;
                const response = await axios.get(link);
                this.popups_trashed = paginatedResponse ? response.data.data : response.data;
                this.total_pages = paginatedResponse ? response.data.last_page : 1;
            }
            catch (error) {
                console.log(error)
            }
        },
        async create(popup) {
            try {
                return await axios
                .post("/popups", popup).then(function (response) {
                    return response;
                });
            }
            catch(error){
                return error.response;
            }
        },
        async update(id, popup) {
            try {
                return await axios
                .put("/popups/" + id, popup).then(function (response) {
                    return response;
                });
            }
            catch(error){
                return error.response;
            }
        },
        async delete(id) {
            try {
                return axios
                .delete(
                    "/popups/" + id
                )
                .then(function (response) {
                    return response;
                });
            }
            catch(error){
                console.log(error);
            }
        },
        async deleteForever(id) {
            try {
                return axios
                .delete(
                    "/popups-delete-forever/" + id
                )
                .then(function (response) {
                    return response;
                });
            }
            catch(error){
                console.log(error);
            }
        },
        async restore(id) {
            try {
                return axios
                .post(
                    "/popups-restore/" + id
                )
                .then(function (response) {
                    return response;
                });
            }
            catch(error){
                console.log(error);
            }
        },
        async changeStatus(popup, status) {
            try {
                return await axios
                .put("/popups-change-status/" + popup + "/" + status).then(function (response) {
                    return response;
                });
            }
            catch(error){
                return error.response;
            }
        },
    }
    
    
});


