<template>
    <div class="card">
        <div class="card-body">
            <form class="theme-form theme-form-2 mega-form" @submit.prevent="savePost">
                <language-inputs v-if="mainCode && fields && fields.length > 0" v-model:fields="fields" :fields="fields"
                    :mainCode="mainCode" :fieldTypes="fieldTypes" />
                <div class="mt-3 lang-errors-container">
                    <!-- <p class="text-danger" v-for="error of v$.fields.$errors" :key="error.$uid">
                        <strong>{{
                            t("validationErrors.lang_field", {
                                field: t(`message.${error.$propertyPath.split(".")[2]}`),
                                code: mainCode,
                            })
                        }}</strong>
                    </p> -->
                </div>
                <div class="row mt-1">
                    <label class="mt-4 form-label">{{ t("message.post_categories") }}</label>
                    <select v-model="v$.selectedCategories.$model" id="post-categories-1" class="form-control"
                        multiple></select>
                    <p class="text-danger" v-for="error of v$.selectedCategories.$errors" :key="error.$uid">
                        <strong>{{ t(`validationErrors.${[error.$message]}`) }}</strong>
                    </p>
                </div>
                <div class="row mt-1 mb-2">
                    <div class="col-lg-6">
                        <label class="form-label-title mb-2 form-label">{{
                            t("message.published_date")
                        }}</label>
                        <input v-model="published_date" class="form-control" type="datetime-local" step="1" />
                    </div>
                </div>
                <div class="row mt-1">
                    <label for="basic-media-input" class="form-label-title form-label mb-1 mt-2">{{ t("message.basic_media")
                    }}</label>
                    <Uploader v-if="basicMedia.saved && basicMedia.saved.length > 0 && id > 0" max=1 class="mt-1"
                        server="/store-media" inputId="basic-media-input" :media="basicMedia.saved"
                        :location="$storageLocation" :withIdFolder="true" @add="addBasicMedia" @remove="removeBasicMedia"
                        maxFilesize=100 />
                    <Uploader v-else max=1 class="mt-1" server="/store-media" @change="changeBasicMedia"
                        inputId="basic-media-input" maxFilesize=100 />
                </div>
                <div class="row g-4">
                    <div class="col-lg-6 text-center mt-5">
                        <button :class="{ 'btn btn-primary': true, 'disabled': btnDisabled }">
                            {{ t("message.save") }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>
  
<script setup>
import { ref, onMounted, onBeforeMount, computed, inject, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useLocaleStore } from "@/stores/locale";
import { usePostStore } from "@/stores/postStore";
import { fillTranslationFields } from "@/helpers/useTranslationFields";
import { useFieldStore } from "@/stores/fieldStore";
import languageInputs from "@/components/custom/languageInputs";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { useLanguageStore } from "@/stores/languageStore";
import { useRouter, useRoute } from "vue-router";
import Uploader from "vue-media-upload";
import { initChoice } from "@/helpers/useInitChoice";

/*
  multilang, notifications
*/
const { t } = useI18n();
const toast = inject("toast");

/*
  form data, data
*/
let fields = ref([]);
let fieldTypes = ref();
let btnDisabled = ref(false);
const basicMedia = ref({ saved: [], added: [], removed: [] });
let postCategorySelect = ref();
let postCategories = ref();
let selectedCategories = ref();
let published_date = ref();

/*
  stores
*/
let activeLang = useLocaleStore();
let postStore = usePostStore();
let fieldStore = useFieldStore();
const languageStore = useLanguageStore();

const mainCode = computed(() => {
    return languageStore.mainCode;
});


/*
  validation
*/
const rules = {
    // fields: {
    //     0: { title: { [mainCode.value]: { required } } },
    //     1: { body: { [mainCode.value]: { required } } }
    // },
    selectedCategories: { required },
};

const v$ = useVuelidate(rules, { fields, selectedCategories });

/*
  router
*/
const router = useRouter();
const route = useRoute();
const id = computed(() => {
    return route.params.id;
});

/*
  events
*/
onBeforeMount(async () => {
    await languageStore.getMainCode();
});

onMounted(async () => {
    await getFields();
    initChoice("post-categories-1", postCategorySelect, true);

    if (id.value > 0) {
        const response = await postStore.get(id.value);
        fillTranslationFields(fields, response.data.translations);
        selectedCategories.value = response.data.post_categories;
        published_date.value = response.data.published_at;
        let basic = response.data.media.filter(item => item.collection_name === 'basic')[0];
        basicMedia.value.saved = basic ? [{ id: basic.id, name: basic.file_name }] : [];
    }


    getPostCategories();
});

const savePost = async () => {
    btnDisabled.value = true;
    const request = {
        lang: activeLang.locale,
        translations: fields.value,
        post_categories: selectedCategories.value,
        published_at: published_date.value,
        basicMedia: id.value > 0 ? basicMedia.value : basicMedia.value.added,
    };

    request.translations.forEach(element => {
        if (element && element.body) {
            const body = element.body;

            const ro = body['ro'];
            const hu = body['hu'];
            const en = body['en'];

            if (ro === '') { delete body['ro'] }
            if (hu === '') { delete body['hu'] }
            if (en === '') { delete body['en'] }
        }
    });

    const isValid = await v$.value.$validate();

    if (isValid) {
        const response =
            id.value > 0
                ? await postStore.update(id.value, request)
                : await postStore.create(request);

        if (response && response.data.message == "success") {
            toast.success(t("message.success_message"));
            id.value > 0 ? router.go(-1) : router.push({ name: "post_list", query: { page: 1 } });
        } else {
            if(response.status == 422){
                toast.error(t("message.lang_title_error"));
            } else {
                toast.error(t("message.error_message"));
            }
        }
    }
    btnDisabled.value = false;
};

watch(
    () => route.path,
    (newPath) => {
        if (newPath == "/create_post") {
            getFields();
            getPostCategories();
            published_date.value = "";
            basicMedia.value = {};
        }
    }
);

const getFields = async () => {
    const fieldsResponse = await fieldStore.fill("post_translations");
    fields.value = fieldsResponse.data;
    const typesResponse = await fieldStore.getFieldTypes("post_translations");
    fieldTypes.value = typesResponse.data;
};

function getPostCategories() {
    postCategorySelect.value.removeActiveItems();
    (async () => {
        await postStore.get_categories(id.value)
            .then(function (response) {
                let categories = response.data;
                let choices = [];
                categories.forEach((val, key) => {
                    choices[key] = {};
                    choices[key].value = val.id;
                    choices[key].label = val.name == null ? t('message.without_name') : val.name;
                    choices[key].selected = id.value ? val.selected : false;
                });
                postCategories.value = choices;
                postCategorySelect.value.setChoices(
                    postCategories.value,
                    "value",
                    "label",
                    true
                );
            });
    })();
}

const changeBasicMedia = (basic) => {
    basicMedia.value.added = basic;
};

const addBasicMedia = (addedImage, added) => {
    basicMedia.value.added = added;
};

const removeBasicMedia = (removedImage, removed) => {
    basicMedia.value.removed = removed;
};

</script>
<style>
.lang-errors-container {
    border-bottom: 1px solid #ddd;
    padding-bottom: 1rem;
}
</style>
  