import Swal from 'sweetalert2'

const deleteItem = async (options) => {
    const result = await Swal.fire({
        title: options.title,
        showCancelButton: true,
        confirmButtonText: options.confirmButtonText,
        cancelButtonText: options.cancelButtonText,
        reverseButtons: true,
    })

    if (result.isConfirmed) {
        const response = await options.deleteAction()
        if (response && response.data == "success") {
            Swal.fire({
                title: options.messageDeleted,
                icon: "success",
            });
            await options.reloadAction()
        } else {
            Swal.fire({
                title: options.cannotDelete, 
                icon: "error",
            });
        }
    }
    else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire({
            title: options.messageCancelled,
            icon: "error",
            customClass: {
                confirmButton: "btn bg-gradient-success",
            },
        })
    }
}

export {
    deleteItem
}