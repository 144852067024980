import { defineStore } from "pinia";

export let useDashboardDataStore = defineStore('dashboardData', {
    state: () => ({
        dashboardData: [],
    }),

    actions: {
        async fill() {
            try {
                const response = await axios.get('/dashboard-data');
                this.dashboardData = response.data
            }
            catch (error) {
                console.log(error)
            }
        }
    }
});


