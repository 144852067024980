<template>
  <div class="card">
    <div class="card-body">
      <form class="theme-form theme-form-2 mega-form" @submit.prevent="saveBanner">
        <language-inputs v-if="mainCode && fields && fields.length > 0" v-model:fields="fields" :fields="fields"
          :mainCode="mainCode" :fieldTypes="fieldTypes" />
        <div class="mt-3 lang-errors-container">
          <p class="text-danger" v-for="error of v$.fields.$errors" :key="error.$uid">
            <strong>{{
        t("validationErrors.lang_field", {
          field: t(`message.${error.$propertyPath.split(".")[2]}`),
          code: mainCode,
        })
      }}</strong>
          </p>
        </div>
        <div class="row mt-1">
          <div class="col-lg-6">
            <label class="form-label-title mb-2 form-label"> {{ t("message.link_name") }}</label>
            <input v-model="link_name" type="text" class="form-control" />
          </div>
        </div>
        <div class="row">
          <!-- RO Image Upload -->
          <label for="basic_ro-media-input" class="form-label-title form-label mb-1 mt-2">{{ t("message.basic_ro")
            }}</label>
          
          <Uploader v-if="basicMedia.saved.basic_ro && basicMedia.saved.basic_ro.length > 0 && id > 0" max="1" class="mt-1" server="/store-media"
            inputId="basic_ro-media-input" :media="basicMedia.saved.basic_ro" :location="$storageLocation"
            :withIdFolder="true" @add="addBasicMedia('basic_ro', $event)" @remove="removeBasicMedia('basic_ro', $event)"
            maxFilesize="100" />
          <Uploader v-else max="1" class="mt-1" server="/store-media" @change="changeBasicMedia('basic_ro', $event)"
            inputId="basic_ro-media-input" maxFilesize="100" />

          <!-- HU Image Upload -->
          <label for="basic_hu-media-input" class="form-label-title form-label mb-1 mt-2">{{ t("message.basic_hu")
            }}</label>
          <Uploader v-if="basicMedia.saved.basic_hu && basicMedia.saved.basic_hu.length > 0 && id > 0" max="1" class="mt-1" server="/store-media"
            inputId="basic_hu-media-input" :media="basicMedia.saved.basic_hu" :location="$storageLocation"
            :withIdFolder="true" @add="addBasicMedia('basic_hu', $event)" @remove="removeBasicMedia('basic_hu', $event)"
            maxFilesize="100" />
          <Uploader v-else max="1" class="mt-1" server="/store-media" @change="changeBasicMedia('basic_hu', $event)"
            inputId="basic_hu-media-input" maxFilesize="100" />

          <!-- EN Image Upload -->
          <label for="basic_en-media-input" class="form-label-title form-label mb-1 mt-2">{{ t("message.basic_en")
            }}</label>
          <Uploader v-if="basicMedia.saved.basic_en && basicMedia.saved.basic_en.length > 0 && id > 0" max="1" class="mt-1" server="/store-media"
            inputId="basic_en-media-input" :media="basicMedia.saved.basic_en" :location="$storageLocation"
            :withIdFolder="true" @add="addBasicMedia('basic_en', $event)" @remove="removeBasicMedia('basic_en', $event)"
            maxFilesize="100" />
          <Uploader v-else max="1" class="mt-1" server="/store-media" @change="changeBasicMedia('basic_en', $event)"
            inputId="basic_en-media-input" maxFilesize="100" />
        </div>
        <div class="row g-4">
          <div class="col-lg-6 text-center mt-5">
            <button :class="{ 'btn btn-primary': true, 'disabled': btnDisabled }">
              {{ t("message.save") }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeMount, computed, inject, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useLocaleStore } from "@/stores/locale";
import { useBannerStore } from "@/stores/bannerStore";
import { fillTranslationFields } from "@/helpers/useTranslationFields";
import { useFieldStore } from "@/stores/fieldStore";
import languageInputs from "@/components/custom/languageInputs";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { useLanguageStore } from "@/stores/languageStore";
import { useRouter, useRoute } from "vue-router";
import Uploader from "vue-media-upload";


/*
  multilang, notifications
*/
const { t } = useI18n();
const toast = inject("toast");

/*
  form data, data
*/
let fields = ref([]);
let fieldTypes = ref();
let link_name = ref();
let btnDisabled = ref(false);
let basicMedia = ref({
  saved: {
    basic_ro: [],
    basic_hu: [],
    basic_en: []
  },
  added: {
    basic_ro: [],
    basic_hu: [],
    basic_en: []
  },
  removed: {
    basic_ro: [],
    basic_hu: [],
    basic_en: []
  }
});
/*
  stores
*/
let activeLang = useLocaleStore();
let bannerStore = useBannerStore();
let fieldStore = useFieldStore();
const languageStore = useLanguageStore();

const mainCode = computed(() => {
  return languageStore.mainCode;
});


/*
  validation
*/
const rules = {
  fields: {
    0: { link: { [mainCode.value]: { required } } },
  },
  link_name: { required },
};




const v$ = useVuelidate(rules, { fields, link_name });
/*
  router
*/
const router = useRouter();
const route = useRoute();
const id = computed(() => {
  return route.params.id;
});

/*
  events
*/
onBeforeMount(async () => {
  await languageStore.getMainCode();
});

onMounted(async () => {
  await getFields();
  if (id.value > 0) {
    const response = await bannerStore.get(id.value);
    fillTranslationFields(fields, response.data.translations);
    link_name.value = response.data.link_name;

    let basic = response.data.media;
    basicMedia.value.saved = {
      basic_ro: basic.filter(item => item.collection_name === 'basic_ro').map(item => ({
        id: item.id,
        name: item.file_name,
      })),
      basic_hu: basic.filter(item => item.collection_name === 'basic_hu').map(item => ({
        id: item.id,
        name: item.file_name,
      })),
      basic_en: basic.filter(item => item.collection_name === 'basic_en').map(item => ({
        id: item.id,
        name: item.file_name,
      }))
    };
  }
});

const saveBanner = async () => {
  btnDisabled.value = true;
  const request = {
    lang: activeLang.locale,
    translations: fields.value,
    link_name: link_name.value,
    basicMedia: id.value > 0 ? basicMedia.value : basicMedia.value.added,
  };
  const isValid = await v$.value.$validate();

  if (isValid) {
    const response =
      id.value > 0
        ? await bannerStore.update(id.value, request)
        : await bannerStore.create(request);

    if (response && response.data.message == "success") {      
      toast.success(t("message.success_message"));
      id.value > 0 ? router.go(-1) : router.push({ name: "banner_list", query: { page: response.data.lastPage } });
    } else {
      if (response.status == 422) {
        toast.error(t("message.lang_name_error"));
      } else {        
        toast.error(t("message.error_message"));
      }
    }
  }
  btnDisabled.value = false;
};

watch(
  () => route.path,
  (newPath) => {
    if (newPath == "/create_banner") {
      getFields();
      link_name.value = "";
      basicMedia.value = {};
    }
  }
);

const getFields = async () => {
  const fieldsResponse = await fieldStore.fill("banner_translations");
  fields.value = fieldsResponse.data;
  const typesResponse = await fieldStore.getFieldTypes("banner_translations");
  fieldTypes.value = typesResponse.data;
};


const changeBasicMedia = (category, media) => {
  basicMedia.value.added[category] = media;
};

const addBasicMedia = (category, addedImage) => {
  basicMedia.value.added[category] = addedImage;
};

const removeBasicMedia = (category, removedImage) => {
  basicMedia.value.removed[category] = removedImage;
};

</script>