<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="page-header close_icon">
        <div class="header-wrapper row mx-4">
          <form
            class="form-inline search-full col custom-search"
            action="javascript:void(0)"
            method="get"
          >
            <div class="form-group w-100">
              <div class="Typeahead Typeahead--twitterUsers">
                <div class="u-posRelative">
                  <input
                    class="demo-input Typeahead-input form-control-plaintext w-100"
                    type="text"
                    :placeholder="$t('message.search')"
                    name="q"
                    title=""
                    autofocus
                    v-model="searchText"
                    @keyup="searchProducts"
                  />
                </div>
              </div>
            </div>
          </form>
          <div class="row">
          <div class="col-12 col-md-4">
            <label class="mt-4 form-label">{{ t("message.product_categories") }}</label>
            <select
              v-model="selectedCategory"
              id="prodListCategories"
              class="form-control" 
            >
            </select>
          </div>
          <div class="col-12 col-md-4">
            <label class="mt-4 form-label">{{ t("message.Manufacturer") }}</label>
            <select
              v-model="selectedBrand"
              id="prodListBrands"
              class="form-control" 
            >
            <option></option>
            <option v-for="brand in brands" :value="brand.id">{{ brand.name }}</option>
            </select>
          </div>
          <div style="align-self:end" class="col-12 col-md-4 mb-2">
            <button @click="resetSearch" class="btn btn-primary">
            {{ t("message.reset_search") }}
            </button>
          </div>
        </div>
        </div>
      </div>
      <div class="card">
        <p v-if="noProductFound" class="m-3">{{ $t('message.no_product_found') }}</p>
        <tableList
          v-if="products.length"
          :columns="columns"
          :listItems="products"
          :options="options"
          :store="productStore"
          :withStatusChange="true"
          :page="page"
          :totalPages="totalPages"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from "vue";
import { useProductStore } from "@/stores/productStore";
import { useI18n } from "vue-i18n";
import tableList from "@/components/custom/tableList";
import { useRoute, useRouter } from "vue-router";
import debounce from 'lodash.debounce';
import { useBrandStore } from "@/stores/brandStore";
import { initChoice } from "@/helpers/useInitChoice";

/*
    multilang
  */
const { t } = useI18n();

/*
  data
*/
const products = computed(() => {
  if (path.value == "/product_list") {
    return productStore.products;
  } else {
    return productStore.products_trashed;
  }
});
const totalPages = computed(() => {
  return productStore.total_pages;
});
const columns = [
  { name: "ID", data: "id" },
  { name: "SKU", data: "sku" },
  { name: t("message.title"), data: "title" },
  { name: t("message.price"), data: "price", suffix: "Lei", discount: "discount" },
];
const options = ref([]);
const searchText = ref();
const brands = computed(() => {
  return brandStore.brands;
});
let productCategories = ref([]);
let categorySelect = ref();
let brandsSelect = ref();
let selectedCategory = ref();
let selectedBrand = ref();
let noProductFound = ref(false);
/*
  route
*/
const route = useRoute();
const router = useRouter();
const path = ref(route.path);
const page = computed(() => {
  return parseInt(route.query.page);
});

/*
    stores
  */
let productStore = useProductStore();
let brandStore = useBrandStore();

/*
    events
  */
onMounted(async () => {
  await brandStore.fill(1,0);
  initChoice("prodListCategories", categorySelect, false);
  initChoice("prodListBrands", brandsSelect, false);
  getCategories();
  getList(route.query.page);
});

watch(
  () => route.query.page,
  (newPage) => {
    getList(newPage, null, selectedCategory.value, selectedBrand.value);
  }
);

watch(
  () => route.path,
  (newPath) => {
    path.value = newPath;
    resetSearch();
    getList();
  }
);

watch(
  () => selectedCategory.value,
  (newCategory) => {
    getList(1, searchText.value, newCategory, selectedBrand.value)
  }
);

watch(
  () => selectedBrand.value,
  (newBrand) => {
    getList(1, searchText.value, selectedCategory.value, newBrand)
  }
);

const getList = debounce(async (page = 1, search = null, category = null, brand = null) => {
  if (path.value == "/product_list") {
    await productStore.fill(page, search, category, brand);
    options.value = [
      { type: "edit_link", name: "edit_product" },
      { type: "event", icon: "lnr lnr-trash", event_type: "trash" },
    ];
  } else {
    await productStore.getTrashed(page, search, category, brand);
    options.value = [
      { type: "event", icon: "lnr lnr-undo", event_type: "restore" },
      { type: "edit_link", name: "edit_product" },
      { type: "event", icon: "lnr lnr-trash", event_type: "delete" },
    ];
  }
  if(products.value.length == 0){
    noProductFound.value = true;
  } else {
    noProductFound.value = false;
  }
}, 1000);

const searchProducts = () => {
  router.push({ query: {page: 1} })
  getList(1, searchText.value, selectedCategory.value, selectedBrand.value);
}

function getCategories() {
  (async () => {
    await productStore.get_categories().then(function (response) {
      let datas = response.data;
      let choices = [];
      datas.forEach((val, key) => {
        choices[key] = {};
        choices[key].value = val.id;
        choices[key].label = val.name == null ? t('message.without_name') : val.name;
      });
      productCategories.value = choices;
      categorySelect.value.setChoices(productCategories.value, "value", "label", true);
    });
  })();
}

const resetSearch = () => {
  categorySelect.value.removeActiveItems();
  brandsSelect.value.removeActiveItems();
  searchText.value = '';
  selectedCategory.value = null;
  selectedBrand.value = null;
  noProductFound.value = false;
}

</script>

<style scoped>
.page-header.close_icon {
  position: unset;
  margin-left: 0 !important;
  width: calc(100% - 50px) !important;
  background-color: #fbfeff;
  box-shadow: unset;
}

.header-wrapper {
  padding: 22px 0px !important;
}

/* search input not to dissapear at mobile */
@media only screen and (max-width: 762px){
  .custom-search {
    display: flex !important;
    position: relative !important;
    .form-group {
      margin-top: 0 !important;
    }
}
}


</style>
