<template>
    <div class="card">
      <div class="card-body">
        <form class="theme-form theme-form-2 mega-form" @submit.prevent="saveLabel">
          <language-inputs
            v-if="mainCode && fields && fields.length > 0"
            v-model:fields="fields"
            :fields="fields"
            :mainCode="mainCode"
            :fieldTypes="fieldTypes"
          />
          <div class="mt-3 lang-errors-container">
            <p class="text-danger" v-for="error of v$.fields.$errors" :key="error.$uid">
              <strong>{{
                t("validationErrors.lang_field", {
                  field: t(`message.${error.$propertyPath.split(".")[2]}`),
                  code: mainCode,
                })
              }}</strong>
            </p>
          </div>
          <div class="row g-4">
            <div class="col-lg-6 text-center mt-5">
              <button :class="{'btn btn-primary':true, 'disabled':btnDisabled}">
                {{ t("message.save") }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted, onBeforeMount, computed, inject, watch } from "vue";
  import { useI18n } from "vue-i18n";
  import { useLocaleStore } from "@/stores/locale";
  import { useLabelStore } from "@/stores/labelStore";
  import { fillTranslationFields } from "@/helpers/useTranslationFields";
  import { useFieldStore } from "@/stores/fieldStore";
  import languageInputs from "@/components/custom/languageInputs";
  import { useVuelidate } from "@vuelidate/core";
  import { required } from "@vuelidate/validators";
  import { useLanguageStore } from "@/stores/languageStore";
  import { useRouter, useRoute } from "vue-router";
  
  /*
    multilang, notifications
  */
  const { t } = useI18n();
  const toast = inject("toast");
  
  /*
    form data, data
  */
  let fields = ref([]);
  let icon = ref();
  let fieldTypes = ref();
  let btnDisabled = ref(false);
  
  /*
    stores
  */
  let activeLang = useLocaleStore();
  let labelStore = useLabelStore();
  let fieldStore = useFieldStore();
  const languageStore = useLanguageStore();
  
  const mainCode = computed(() => {
    return languageStore.mainCode;
  });
  
  
  /*
    validation
  */
  const rules = {
    fields: { 0: { name: { [mainCode.value]: { required } } } }
  };
  
  const v$ = useVuelidate(rules, { fields });
  
  /*
    router
  */
  const router = useRouter();
  const route = useRoute();
  const id = computed(() => {
    return route.params.id;
  });
  
  /*
    events
  */
  onBeforeMount(async () => {
    await languageStore.getMainCode();
  });
  
  onMounted(async () => {
    await getFields();
    if (id.value > 0) {
      const response = await labelStore.get(id.value);
      fillTranslationFields(fields, response.data.translations);
    }
  });
  
  const saveLabel = async () => {
    btnDisabled.value = true;
    const request = {
      lang: activeLang.locale,
      translations: fields.value
    };
  
    const isValid = await v$.value.$validate();
  
    if (isValid) {
      const response =
        id.value > 0
          ? await labelStore.update(id.value, request)
          : await labelStore.create(request);
  
      if (response && response.data.message == "success") {
        toast.success(t("message.success_message"));
          id.value > 0 ? router.go(-1) : router.push({ name: "label_list", query:{page:response.data.lastPage} });    
      } else {
        toast.error(t("message.error_message"));
      }
    }
    btnDisabled.value = false;
  };
  
  watch(
    () => route.path,
    (newPath) => {
      if (newPath == "/create_label") {
        getFields();
      }
    }
  );
  
  const getFields = async () => {
    const fieldsResponse = await fieldStore.fill("label_translations");
    fields.value = fieldsResponse.data;
    const typesResponse = await fieldStore.getFieldTypes("label_translations");
    fieldTypes.value = typesResponse.data;
  };
  
  </script>
  