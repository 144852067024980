<template>
    <!-- Table Start -->
    <div class="card-body position-relative">
        <!-- Loading Overlay -->
        <div v-if="loading" class="loading-overlay">
            <div class="spinner"></div>
        </div>

        <div class="table-responsive table-desi">
            <table v-if="!isNaN(currentPage)" class="user-table vendor-table menu-list-table table table-striped">
                <thead>
                    <tr>
                        <th class="status-th">{{ t('message.order_number') }}</th>
                        <th class="status-th">{{ t('message.name2') }}</th>
                        <th class="status-th">{{ t('message.payment_method') }}</th>
                        <th class="status-th">{{ t('message.total_price') }}</th>
                        <th class="status-th">{{ t('message.payed') }}</th>
                        <th class="status-th">{{ t('message.state') }}</th>
                        <th class="status-th">{{ t('message.date') }}</th>
                        <th>{{ t("message.options") }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in listItems" :key="item.id"
                    :class="{'red-list': item.payment_method === 'bank_card' && item.payed === 0}"
                    > 
                        <td class="status-th">{{ item.order_number }}</td>
                        <td class="status-th">{{ item.shipping_address.contact_person }}</td>
                        <td class="status-th">{{ t(`message.${item.payment_method}`) }}</td>
                        <td class="status-th">{{ Number(item.payableAmount).toFixed(2) }} Lei</td>

                        <td class="menu-status status-td">
                            <select name="payed" id="payed" @change="changePayed($event, item)" :disabled="loading">
                                <option :selected="item.payed == 1 ?? false" value="1">
                                    {{ t('message.yes') }}
                                </option>
                                <option :selected="item.payed == 0 ?? false" value="0">
                                    {{ t('message.no') }}
                                </option>
                            </select>
                        </td>

                        <td class="status-th">
                            <select name="status" id="status" v-model="item.status"
                                @change="changeOrderStatus($event, item)" :disabled="loading">
                                <option :value="1">{{ t('message.new') }}</option>
                                <option :value="2">{{ t('message.registered') }}</option>
                                <option :value="3">{{ t('message.packaged') }}</option>
                                <option :value="4">{{ t('message.courier') }}</option>
                                <option :value="5">{{ t('message.arrived') }}</option>
                                <option :value="6">{{ t('message.archived') }}</option>
                            </select>
                        </td>

                        <td class="status-th">{{ format(new Date(item.created_at), 'yyyy-MM-dd H:mm:ss') }}</td>

                        <td>
                            <ul>
                                <li>
                                    <router-link :to="'/order_detail/' + item.id" href="javascript:void(0)">
                                        <span class="lnr lnr-eye"></span>
                                    </router-link>
                                </li>
                            </ul>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <!-- Table End -->
    <div class="pagination-box">
        <nav class="ms-auto me-auto" aria-label="...">
            <ul class="pagination pagination-primary">
                <li class="page-item" :class="[{ disabled: currentPage == 1 }]">
                    <a class="page-link" href="javascript:void(0)" @click.prevent="changePage(currentPage - 1)"
                        :disabled="loading">{{ t("message.prev") }}</a>
                </li>

                <li v-if="pages" class="page-item" :class="[{ active: currentPage == page }]"
                    v-for="(page, index) in pages" :key="index">
                    <a class="page-link" href="javascript:void(0)" @click.prevent="changePage(page)"
                        :disabled="loading">{{ page }}</a>
                </li>
                <li class="page-item" :class="[{ disabled: currentPage == totalPages }]">
                    <a class="page-link" href="javascript:void(0)"
                        @click.prevent="changePage(currentPage + 1 <= totalPages ? currentPage + 1 : currentPage)"
                        :disabled="loading">{{ t("message.next") }}</a>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script setup>
import { ref, onMounted, watch, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { format } from 'date-fns'

const { t } = useI18n();
const loading = ref(false);

const props = defineProps({
    listItems: Array,
    store: Object,
    page: Number,
    totalPages: Number,
    state: String,
    activeTab: Number,
    getList: Function,

});

let currentPage = ref(props.page);
const pages = ref([]);

const route = useRoute();
const router = useRouter();

onMounted(() => {
    currentPage.value = props.page;
    calculatePages();
});

const changePage = async (toPage) => {
    if (toPage <= props.totalPages && toPage > 0) {
        loading.value = true;
        currentPage.value = toPage;
        await router.push({ path: route.path, query: { page: toPage } });
        calculatePages();
        loading.value = false;
    }
};

const calculatePages = () => {
    let numbers = [];
    let startpage = currentPage.value === 1 ? currentPage.value : currentPage.value - 1;
    let endPage =
        currentPage.value === 1
            ? currentPage.value + 2
            : currentPage.value + 1 <= props.totalPages
                ? currentPage.value + 1
                : currentPage.value;
    for (let i = startpage; i <= endPage; i++) {
        i <= props.totalPages && numbers.push(i);
    }
    pages.value = numbers;
};

watch(
    () => props.totalPages,
    () => {
        if (isNaN(currentPage.value)) {
            currentPage.value = props.totalPages;
        } else {
            currentPage.value = props.page;
        }
        calculatePages();
    }
);

const changePayed = async (event, item) => {
    let payed = event.target.value;
    loading.value = true;
    const response = await props.store.changePayed(item.id, payed);
    if (response.data) {
        item.payed = payed;
    }
    loading.value = false;
};
const changeOrderStatus = async (event, item) => {
    let newStatus = parseInt(event.target.value, 10);

    try {
        const response = await props.store.changeOrderStatus(item.id, newStatus);
        if (response.data) {
            item.status = newStatus;

            await props.getList(props.activeTab, props.page);
        }
    } catch (error) {
        console.error("Error updating order status:", error);
    }
};
</script>

<style scoped>
.red-list {
  background-color: #dc3545 !important;
  color: white !important;
}
.red-list td {
  color: white !important;
}

.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    border-top: 4px solid #007bff;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>