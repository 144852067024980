<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-body">
          <div class="table-responsive table-desi">
            <table class="user-table list-table table table-striped">
              <thead>
                <tr>
                  <th>
                    <span class="form-check user-checkbox">
                      <input
                        class="checkbox_animated"
                        type="checkbox"
                        value=""
                        id="checkall"
                      />
                    </span>
                  </th>
                  <th>Title</th>
                  <th>Author</th>
                  <th>Date</th>
                  <th>Options</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <span class="form-check user-checkbox">
                      <input
                        class="checkbox_animated check-it"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                      />
                    </span>
                  </td>

                  <td>
                    <span class="theme-color">Blog</span>
                  </td>

                  <td>Root</td>

                  <td>
                    <a href="javascript:void(0)">
                      <span class="d-block theme-color">Published</span>
                      <span>23-10-2020 at 6:12 AM</span>
                    </a>
                  </td>

                  <td>
                    <ul>
                      <li>
                        <router-link
                          to="/order_detail"
                          href="javascript:void(0)"
                        >
                          <span class="lnr lnr-eye"></span>
                        </router-link>
                      </li>

                      <li>
                        <a href="javascript:void(0)">
                          <span class="lnr lnr-pencil"></span>
                        </a>
                      </li>

                      <li>
                        <a href="javascript:void(0)">
                          <span class="lnr lnr-trash"></span>
                        </a>
                      </li>
                    </ul>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="form-check user-checkbox">
                      <input
                        class="checkbox_animated check-it"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault1"
                      />
                    </span>
                  </td>

                  <td><span class="theme-color">Cart</span> - Cart Page</td>

                  <td>Root</td>

                  <td>
                    <a href="javascript:void(0)">
                      <span class="d-block theme-color">Published</span>
                      <span>23-10-2020 at 6:12 AM</span>
                    </a>
                  </td>

                  <td>
                    <ul>
                      <li>
                        <router-link
                          to="/order_detail"
                          href="javascript:void(0)"
                        >
                          <span class="lnr lnr-eye"></span>
                        </router-link>
                      </li>

                      <li>
                        <a href="javascript:void(0)">
                          <span class="lnr lnr-pencil"></span>
                        </a>
                      </li>

                      <li>
                        <a href="javascript:void(0)">
                          <span class="lnr lnr-trash"></span>
                        </a>
                      </li>
                    </ul>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="form-check user-checkbox">
                      <input
                        class="checkbox_animated check-it"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault2"
                      />
                    </span>
                  </td>

                  <td>
                    <span class="theme-color">Checkout</span> - Checkout Page
                  </td>

                  <td>Root</td>

                  <td>
                    <a href="javascript:void(0)">
                      <span class="d-block theme-color">Published</span>
                      <span>23-10-2020 at 6:12 AM</span>
                    </a>
                  </td>

                  <td>
                    <ul>
                      <li>
                        <router-link
                          to="/order_detail"
                          href="javascript:void(0)"
                        >
                          <span class="lnr lnr-eye"></span>
                        </router-link>
                      </li>

                      <li>
                        <a href="javascript:void(0)">
                          <span class="lnr lnr-pencil"></span>
                        </a>
                      </li>

                      <li>
                        <a href="javascript:void(0)">
                          <span class="lnr lnr-trash"></span>
                        </a>
                      </li>
                    </ul>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="form-check user-checkbox">
                      <input
                        class="checkbox_animated check-it"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault3"
                      />
                    </span>
                  </td>

                  <td><span class="theme-color">Home</span> - Elementor</td>

                  <td>Root</td>

                  <td>
                    <a href="javascript:void(0)">
                      <span class="d-block theme-color">Published</span>
                      <span>23-10-2020 at 6:12 AM</span>
                    </a>
                  </td>

                  <td>
                    <ul>
                      <li>
                        <router-link
                          to="/order_detail"
                          href="javascript:void(0)"
                        >
                          <span class="lnr lnr-eye"></span>
                        </router-link>
                      </li>

                      <li>
                        <a href="javascript:void(0)">
                          <span class="lnr lnr-pencil"></span>
                        </a>
                      </li>

                      <li>
                        <a href="javascript:void(0)">
                          <span class="lnr lnr-trash"></span>
                        </a>
                      </li>
                    </ul>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="form-check user-checkbox">
                      <input
                        class="checkbox_animated check-it"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault4"
                      />
                    </span>
                  </td>

                  <td><span class="theme-color">Home2</span> - Elementor</td>

                  <td>Root</td>

                  <td>
                    <a href="javascript:void(0)">
                      <span class="d-block theme-color">Published</span>
                      <span>23-10-2020 at 6:12 AM</span>
                    </a>
                  </td>

                  <td>
                    <ul>
                      <li>
                        <router-link
                          to="/order_detail"
                          href="javascript:void(0)"
                        >
                          <span class="lnr lnr-eye"></span>
                        </router-link>
                      </li>

                      <li>
                        <a href="javascript:void(0)">
                          <span class="lnr lnr-pencil"></span>
                        </a>
                      </li>

                      <li>
                        <a href="javascript:void(0)">
                          <span class="lnr lnr-trash"></span>
                        </a>
                      </li>
                    </ul>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="form-check user-checkbox">
                      <input
                        class="checkbox_animated check-it"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault5"
                      />
                    </span>
                  </td>

                  <td><span class="theme-color">Home3</span> - Elementor</td>

                  <td>Root</td>

                  <td>
                    <a href="javascript:void(0)">
                      <span class="d-block theme-color">Published</span>
                      <span>23-10-2020 at 6:12 AM</span>
                    </a>
                  </td>

                  <td>
                    <ul>
                      <li>
                        <router-link
                          to="/order_detail"
                          href="javascript:void(0)"
                        >
                          <span class="lnr lnr-eye"></span>
                        </router-link>
                      </li>

                      <li>
                        <a href="javascript:void(0)">
                          <span class="lnr lnr-pencil"></span>
                        </a>
                      </li>

                      <li>
                        <a href="javascript:void(0)">
                          <span class="lnr lnr-trash"></span>
                        </a>
                      </li>
                    </ul>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="form-check user-checkbox">
                      <input
                        class="checkbox_animated check-it"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault6"
                      />
                    </span>
                  </td>

                  <td>
                    <span class="theme-color">my Account</span> - My Account
                    Page
                  </td>

                  <td>Root</td>

                  <td>
                    <a href="javascript:void(0)">
                      <span class="d-block theme-color">Published</span>
                      <span>23-10-2020 at 6:12 AM</span>
                    </a>
                  </td>

                  <td>
                    <ul>
                      <li>
                        <router-link
                          to="/order_detail"
                          href="javascript:void(0)"
                        >
                          <span class="lnr lnr-eye"></span>
                        </router-link>
                      </li>

                      <li>
                        <a href="javascript:void(0)">
                          <span class="lnr lnr-pencil"></span>
                        </a>
                      </li>

                      <li>
                        <a href="javascript:void(0)">
                          <span class="lnr lnr-trash"></span>
                        </a>
                      </li>
                    </ul>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="form-check user-checkbox">
                      <input
                        class="checkbox_animated check-it"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault7"
                      />
                    </span>
                  </td>

                  <td>
                    <span class="theme-color">Privacy Policy</span> - Draft,
                    Privacy Policy Page
                  </td>

                  <td>Root</td>

                  <td>
                    <a href="javascript:void(0)">
                      <span class="d-block theme-color">Published</span>
                      <span>23-10-2020 at 6:12 AM</span>
                    </a>
                  </td>

                  <td>
                    <ul>
                      <li>
                        <router-link
                          to="/order_detail"
                          href="javascript:void(0)"
                        >
                          <span class="lnr lnr-eye"></span>
                        </router-link>
                      </li>

                      <li>
                        <a href="javascript:void(0)">
                          <span class="lnr lnr-pencil"></span>
                        </a>
                      </li>

                      <li>
                        <a href="javascript:void(0)">
                          <span class="lnr lnr-trash"></span>
                        </a>
                      </li>
                    </ul>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="form-check user-checkbox">
                      <input
                        class="checkbox_animated check-it"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault8"
                      />
                    </span>
                  </td>

                  <td>
                    <span class="theme-color">Privacy Policy</span> - Draft
                  </td>

                  <td>Root</td>

                  <td>
                    <a href="javascript:void(0)">
                      <span class="d-block theme-color">Published</span>
                      <span>23-10-2020 at 6:12 AM</span>
                    </a>
                  </td>

                  <td>
                    <ul>
                      <li>
                        <router-link
                          to="/order_detail"
                          href="javascript:void(0)"
                        >
                          <span class="lnr lnr-eye"></span>
                        </router-link>
                      </li>

                      <li>
                        <a href="javascript:void(0)">
                          <span class="lnr lnr-pencil"></span>
                        </a>
                      </li>

                      <li>
                        <a href="javascript:void(0)">
                          <span class="lnr lnr-trash"></span>
                        </a>
                      </li>
                    </ul>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="form-check user-checkbox">
                      <input
                        class="checkbox_animated check-it"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault9"
                      />
                    </span>
                  </td>

                  <td>
                    <span class="theme-color">Refund and Return Policy</span> -
                    Draft
                  </td>

                  <td>Root</td>

                  <td>
                    <a href="javascript:void(0)">
                      <span class="d-block theme-color">Published</span>
                      <span>23-10-2020 at 6:12 AM</span>
                    </a>
                  </td>

                  <td>
                    <ul>
                      <li>
                        <router-link
                          to="/order_detail"
                          href="javascript:void(0)"
                        >
                          <span class="lnr lnr-eye"></span>
                        </router-link>
                      </li>

                      <li>
                        <a href="javascript:void(0)">
                          <span class="lnr lnr-pencil"></span>
                        </a>
                      </li>

                      <li>
                        <a href="javascript:void(0)">
                          <span class="lnr lnr-trash"></span>
                        </a>
                      </li>
                    </ul>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="form-check user-checkbox">
                      <input
                        class="checkbox_animated check-it"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault0"
                      />
                    </span>
                  </td>

                  <td><span class="theme-color">Simple Page</span></td>

                  <td>Root</td>

                  <td>
                    <a href="javascript:void(0)">
                      <span class="d-block theme-color">Published</span>
                      <span>23-10-2020 at 6:12 AM</span>
                    </a>
                  </td>

                  <td>
                    <ul>
                      <li>
                        <router-link
                          to="/order_detail"
                          href="javascript:void(0)"
                        >
                          <span class="lnr lnr-eye"></span>
                        </router-link>
                      </li>

                      <li>
                        <a href="javascript:void(0)">
                          <span class="lnr lnr-pencil"></span>
                        </a>
                      </li>

                      <li>
                        <a href="javascript:void(0)">
                          <span class="lnr lnr-trash"></span>
                        </a>
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- Pagination Box Start -->
        <div class="pagination-box">
          <nav class="ms-auto me-auto" aria-label="...">
            <ul class="pagination pagination-primary">
              <li class="page-item disabled">
                <a class="page-link" href="javascript:void(0)">Previous</a>
              </li>
              <li class="page-item active">
                <a class="page-link" href="javascript:void(0)">1 </a>
              </li>
              <li class="page-item">
                <a class="page-link" href="javascript:void(0)">2</a>
              </li>
              <li class="page-item">
                <a class="page-link" href="javascript:void(0)">3</a>
              </li>
              <li class="page-item">
                <a class="page-link" href="javascript:void(0)">Next</a>
              </li>
            </ul>
          </nav>
        </div>
        <!-- Pagination Box End -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  title: "Voxo-List Page",
};
</script>

<style></style>
