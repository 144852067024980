const fillTranslationFields = (fields, translations) => {
    
    translations.forEach((v, index) => {
      
        
        fields.value.forEach((val, key) => {
            fields.value[key][Object.keys(val)[0]][v["locale"]] =
                v[Object.keys(val)[0]] ?? '';
        });
    });
}

export { fillTranslationFields }