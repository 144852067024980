<template>
    <div class="card">
        <div class="card-body">
            <form class="theme-form theme-form-2 mega-form" @submit.prevent="saveCoupon">
                <div class="row mt-1">
                    <div class="col-lg-6">
                        <label class="form-label-title mb-2 form-label">{{ t("message.code") }}</label>
                        <input v-model="code" type="text" class="form-control" />
                        <p class="text-danger" v-for="error of v$.code.$errors" :key="error.$uid">
                            <strong>{{ t(`validationErrors.${[error.$message]}`) }}</strong>
                        </p>
                    </div>
                </div>
                <div class="row mt-1">
                    <div class="col-lg-6">
                        <label class="form-label-title mb-2 form-label">{{ t("message.value") }}</label>
                        <input v-model="value" type="number" class="form-control" />
                        <p class="text-danger" v-for="error of v$.value.$errors" :key="error.$uid">
                            <strong>{{ t(`validationErrors.${[error.$message]}`) }}</strong>
                        </p>
                    </div>
                </div>
                <div class="row mt-1">
                    <div class="col-lg-6">
                        <label class="form-label-title mb-2 form-label">{{ t("message.type") }}</label>
                        <select v-model="type" class="form-control">
                            <option value="percentage">{{ t("message.percentage") }}</option>
                            <option value="amount">{{ t("message.amount") }}</option>
                        </select>
                        <p class="text-danger" v-for="error of v$.type.$errors" :key="error.$uid">
                            <strong>{{ t(`validationErrors.${[error.$message]}`) }}</strong>
                        </p>
                    </div>
                </div>
                <div class="row mt-1">
                    <div class="col-lg-6">
                        <label class="form-label-title mb-2 form-label">{{ t("message.start_date") }}</label>
                        <input v-model="start_date" type="date" class="form-control" />
                        <p class="text-danger" v-for="error of v$.start_date.$errors" :key="error.$uid">
                            <strong>{{ t(`validationErrors.${[error.$message]}`) }}</strong>
                        </p>
                    </div>
                </div>
                <div class="row mt-1">
                    <div class="col-lg-6">
                        <label class="form-label-title mb-2 form-label">{{ t("message.end_date") }}</label>
                        <input v-model="end_date" type="date" class="form-control" />
                        <p class="text-danger" v-for="error of v$.end_date.$errors" :key="error.$uid">
                            <strong v-if="error.$validator == 'isEndDateValid'">{{ t(`validationErrors.${[error.$validator]}`) }}</strong>
                            <strong v-else>{{ t(`validationErrors.${[error.$message]}`) }}</strong>
                        </p>
                    </div>
                </div>
                <div class="row mt-1">
                    <div class="col-lg-6">
                        <label class="form-label-title mb-2 form-label">{{ t("message.used_once") }}</label>
                        <select v-model="used_once" class="form-control">
                            <option :selected="used_once ??= 1" value="1">{{ t("message.yes") }}</option>
                            <option :selected="used_once ??= 0" value="0">{{ t("message.no") }}</option>
                        </select>
                        <p class="text-danger" v-for="error of v$.used_once.$errors" :key="error.$uid">
                            <strong>{{ t(`validationErrors.${[error.$message]}`) }}</strong>
                        </p>
                    </div>
                </div>
                <div class="row mt-1">
                    <div class="col-lg-6">
                        <label class="form-label-title mb-2 form-label">{{ t("message.reusable_per_days") }}</label>
                        <input v-model="reusable_per_days" type="number" class="form-control" :class="used_once == 1 ? 'disabled-cursor' : ''" :disabled="used_once == 1" />
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-lg-6"> 
                        <div class="form-check user-checkbox ps-0">
                          <input
                            v-model="freeDelivery"
                            class="checkbox_animated check-it"
                            type="checkbox"
                            id="freeDelivery"
                          />
                          <label
                            for="freeDelivery"
                            class="form-label-title mb-0"
                            >{{t('message.free_delivery')}}</label
                          >
                        </div>
                    </div>
                </div>
                <!-- for now it is not necessary but works in admin -->
                <!-- <div class="row mt-1">
                    <label class="mt-4 form-label">{{ t("message.coupon_products") }}</label>
                    <select v-model="selectedProducts" id="coupon-products-1" class="form-control"
                        multiple></select>
                </div> -->
                <div class="row g-4">
                    <div class="col-lg-6 text-center mt-5">
                        <button :class="{ 'btn btn-primary': true, 'disabled': btnDisabled }">
                            {{ t("message.save") }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>
  
<script setup>
import { ref, onMounted, onBeforeMount, computed, inject, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useLocaleStore } from "@/stores/locale";
import { useCouponStore } from "@/stores/couponStore";
import { useVuelidate } from "@vuelidate/core";
import { required, numeric, maxLength, minLength } from "@vuelidate/validators";
import { useRouter, useRoute } from "vue-router";
import { initChoice } from "@/helpers/useInitChoice";
import { useLanguageStore } from "@/stores/languageStore";
import { isEndDateValid } from "@/helpers/useIsEndDateValid";

/*
  multilang, notifications
*/
const { t } = useI18n();
const toast = inject("toast");

/*
  form data, data
*/
let btnDisabled = ref(false);
let code = ref();
let value = ref();
let type = ref();
let start_date = ref();
let end_date = ref();
let used_once = ref();
let reusable_per_days = ref();
let couponProductSelect = ref();
let couponProducts = ref();
let selectedProducts = ref();
let freeDelivery = ref(false);

/*
  stores
*/
let activeLang = useLocaleStore();
let couponStore = useCouponStore();
const languageStore = useLanguageStore();

/*
  validation
*/
const rules = {
    code: {
        required
    },
    value: { required, numeric },
    type: { required },
    start_date: { required },
    end_date: { required, isEndDateValid: () => isEndDateValid(start_date.value, end_date.value) },
    used_once: { required },
};

const v$ = useVuelidate(rules, {
    code,
    value,
    type,
    start_date,
    end_date,
    used_once,
});

/*
  router
*/
const router = useRouter();
const route = useRoute();
const id = computed(() => {
    return route.params.id;
});

/*
  events
*/
onBeforeMount(async () => {
    await languageStore.getMainCode();
});

onMounted(async () => {
    // initChoice("coupon-products-1", couponProductSelect, true);

    if (id.value > 0) {
        const response = await couponStore.get(id.value);
        selectedProducts.value = response.data.coupon_products;
        code.value = response.data.code;
        value.value = response.data.value;
        type.value = response.data.type;
        start_date.value = response.data.start_date;
        end_date.value = response.data.end_date;
        used_once.value = response.data.used_once;
        reusable_per_days.value = response.data.reusable_per_days;
        freeDelivery.value = response.data.free_delivery == 1 ? true : false;
    }

    // getCouponProducts();
});

const saveCoupon = async () => {
    btnDisabled.value = true;
    const request = {
        coupon_products: selectedProducts.value,
        code: code.value,
        value: value.value,
        type: type.value,
        start_date: start_date.value,
        end_date: end_date.value,
        used_once: used_once.value,
        reusable_per_days: reusable_per_days.value,
        free_delivery: freeDelivery.value
    };

    const isValid = await v$.value.$validate();

    if (isValid) {
        const response =
            id.value > 0
                ? await couponStore.update(id.value, request)
                : await couponStore.create(request);

        if (response && response.data.message == "success") {
            toast.success(t("message.success_message"));
            id.value > 0 ? router.go(-1) : router.push({ name: "coupon_list", query:{page:response.data.lastPage} });
        } else {
            toast.error(t("message.error_message"));
        }
    }
    btnDisabled.value = false;
};

watch(
    () => route.path,
    (newPath) => {
        if (newPath == "/create_coupon") {
            // getCouponProducts();
            code.value = "";
            value.value = "";
            type.value = "";
            start_date.value = "";
            end_date.value = "";
            used_once.value = "";
        }
    }
);

function getCouponProducts() {
    couponProductSelect.value.removeActiveItems();
    (async () => {
        await couponStore.get_products(id.value)
            .then(function (response) {
                let products = response.data;
                let choices = [];
                products.forEach((val, key) => {
                    choices[key] = {};
                    choices[key].value = val.id;
                    choices[key].label = val.title == null ? t('message.without_title') : val.title;
                    choices[key].selected = id.value ? val.selected : false;
                });
                couponProducts.value = choices;
                couponProductSelect.value.setChoices(
                    couponProducts.value,
                    "value",
                    "label",
                    true
                );
            });
    })();
}

</script>
<style>
.lang-errors-container {
    border-bottom: 1px solid #ddd;
    padding-bottom: 1rem;
}

.disabled-cursor{
    cursor: not-allowed;
}
</style>
  