<template>
  <div class="row">
    <div class="col-sm-12">
      <createVendor />
    </div>
  </div>
</template>

<script>
import createVendor from "@/components/application/vendors/create_vendor/createVendor.vue";
export default {
  components: { createVendor },
  title: "Voxo-Create Vendor",
};
</script>

<style></style>
