import { defineStore } from "pinia";

export let useStockStore = defineStore('stock', {
    state: () => ({
        products: [],
        total_pages:0
    }),

    actions: {
        async fill(page = 1, search = null) {
            try {
                let link = `/products?page=${page}`;
                if(search !== null){
                    link += `&search=${search}`;
                }
                let paginated = search == null || search.length < 2;
                const response = await axios.get(link);
                this.products = paginated ? response.data.data : response.data;
                this.total_pages = paginated ? response.data.last_page : 1;
            }
            catch (error) {
                console.log(error)
            }
        },
        async changeTitle(product, title) {
            try {
                return await axios
                .put("/products-change-title/" + product + "/" + title).then(function (response) {
                    return response;
                });
            }
            catch(error){
                return error.response;
            }
        },
        async changeSku(product, sku) {
            try {
                return await axios
                .put("/products-change-sku/" + product + "/" + sku).then(function (response) {
                    return response;
                });
            }
            catch(error){
                return error.response;
            }
        },
        async changePrice(product, price) {
            try {
                return await axios
                .put("/products-change-price/" + product + "/" + price).then(function (response) {
                    return response;
                });
            }
            catch(error){
                return error.response;
            }
        },
        async changeManageStock(product, manage_stock) {
            try {
                return await axios
                .put("/products-change-manage-stock/" + product + "/" + manage_stock).then(function (response) {
                    return response;
                });
            }
            catch(error){
                return error.response;
            }
        },
        async changeStockStatus(product, stock_status) {
            try {
                return await axios
                .put("/products-change-stock-status/" + product + "/" + stock_status).then(function (response) {
                    return response;
                });
            }
            catch(error){
                return error.response;
            }
        },
        async changeStock(product, stock) {
            try {
                return await axios
                .put("/products-change-stock/" + product + "/" + stock).then(function (response) {
                    return response;
                });
            }
            catch(error){
                return error.response;
            }
        },
    }
});


