<template>
    <!-- Table Start -->
    <div class="card-body">
        <div class="table-responsive table-desi">
            <table v-if="!isNaN(currentPage)" class="user-table vendor-table menu-list-table table table-striped">
                <thead>
                    <tr>
                        <th class="text-capitalize" v-for="column in columns">{{ column.name }}</th>
                        <th class="status-th">{{ t('message.title') }}</th>
                        <th class="status-th">SKU</th>
                        <th class="status-th">{{ t('message.price') }}</th>
                        <th class="status-th">{{ t('message.manage_stock') }}</th>
                        <th class="status-th">{{ t('message.stock_status') }}</th>
                        <th class="status-th">{{ t('message.stock') }}</th>
                        <th class="status-th" width="10px"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in listItems" :key="item.id">
                        <td v-for="column in columns">{{ column.translate ? t(`message.${item[column.data]}`) :
                            item[column.data] }}</td>
                        <td class="menu-status status-td">
                            <input class="w-300px" type="text" :value="item.title" @change="changeTitle($event, item)" />
                        </td>
                        <td class="menu-status status-td">
                            <input class="w-100px" type="text" :value="item.sku" @change="changeSku($event, item)" />
                        </td>
                        <td class="menu-status status-td">
                            <input class="w-100px" type="number" :value="item.price" @change="changePrice($event, item)" />
                        </td>
                        <td class="menu-status status-td">
                            <input class="checkbox_animated check-it" type="checkbox" :checked="item.manage_stock"
                                @change="changeManageStock($event, item)" />
                            <span :class="item.manage_stock ? 'success' : 'warning'">{{ item.manage_stock ?
                                t("message.managing") : t("message.notManaging") }}</span>
                        </td>
                        <td class="menu-status status-td">
                            <select name="stock_status" id="stock_status" :disabled="item.manage_stock == true"
                                @change="changeStockStatus($event, item)">
                                <option :selected="item.wp_availability == 'instock' ? true : false" value="instock">{{
                                    t('message.inStock') }}</option>
                                <option :selected="item.wp_availability == 'onbackorder' ? true : false" value="onbackorder">{{
                                    t('message.onBackOrder') }}</option>
                                <option :selected="item.wp_availability == 'outofstock' ? true : false" value="outofstock">
                                    {{ t('message.outOfStock') }}</option>
                            </select>
                        </td>
                        <td class="menu-status status-td">
                            <input :disabled="item.manage_stock == false" class="w-100px" type="number" :value="item.stock"
                                @change="changeStock($event, item)" />
                        </td>
                        <td class="menu-status status-td">
                            <span class="fas fa-check-circle" :id="item.id" style="color: #0ee00b; font-size: 1.33333em; opacity: 0;"></span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <!-- Table End -->
    <div class="pagination-box">
        <nav class="ms-auto me-auto" aria-label="...">
            <ul class="pagination pagination-primary">
                <li class="page-item" :class="[{ disabled: currentPage == 1 }]">
                    <a class="page-link" href="javascript:void(0)" @click.prevent="
                        changePage(currentPage - 1 > 0 ? currentPage - 1 : changePage)
                        ">{{ t("message.prev") }}</a>
                </li>

                <li v-if="pages" class="page-item" :class="[{ active: currentPage == page }]" v-for="(page, index) in pages"
                    :key="index">
                    <a class="page-link" href="javascript:void(0)" @click.prevent="changePage(page)">{{ page }}</a>
                </li>
                <li class="page-item" :class="[{ disabled: currentPage == totalPages }]">
                    <a class="page-link" href="javascript:void(0)" @click.prevent="
                        changePage(currentPage + 1 <= totalPages ? currentPage + 1 : changePage)
                        ">{{ t("message.next") }}</a>
                </li>
            </ul>
        </nav>
    </div>
</template>
  
<script setup>
import { deleteItem } from "@/helpers/useDeleteItem";
import { useI18n } from "vue-i18n";
import { ref, onMounted, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

/*
  multilang
*/
const { t } = useI18n();

/*
  props
*/
const props = defineProps({
    columns: Array,
    listItems: Array,
    store: Object,
    page: Number,
    totalPages: Number
});

/*
  data
*/
let currentPage = ref(props.page);
const pages = ref([]);

/*
  route
*/
const route = useRoute();
const router = useRouter();

/*
  events
*/
onMounted(() => {
    currentPage.value = props.page;
    calculatePages();
});

const changePage = (toPage) => {
    if (toPage <= props.totalPages && toPage > 0) {
        currentPage.value = toPage;
        router.push({ path: route.path, query: { page: toPage } })
    }
    calculatePages();
};

const calculatePages = () => {
    var numbers = [];
    let startpage = currentPage.value === 1 ? currentPage.value : currentPage.value - 1;
    let endPage =
        currentPage.value === 1
            ? currentPage.value + 2
            : currentPage.value + 1 <= props.totalPages
                ? currentPage.value + 1
                : currentPage.value;
    for (let i = startpage; i <= endPage; i++) {
        i <= props.totalPages && numbers.push(i);
    }
    pages.value = numbers;

}

watch(
    () => props.totalPages,
    () => {
        if (isNaN(currentPage.value)) {
            currentPage.value = props.totalPages;
        } else {
            currentPage.value = props.page;
        }
        calculatePages();
    }
);

function fadeOut(element) {
    let el = document.getElementById(element);
    el.style.opacity = 1;
    setInterval(function () {
        el.style.opacity -= 0.1;
    }, 400);
}

const changeTitle = async (event, item) => {
    let data = event.target.value;
    if (data == '') {
        alert(t('message.needsTitle'));
        event.target.value = item.title;
        return;
    }
    const response = await props.store.changeTitle(item.id, data);
    fadeOut(item.id);
    if (response.data) {
        item.title = data;
    } else {
        event.target.value = '';
        item.title = '';
    }
};

const changeSku = async (event, item) => {
    let data = event.target.value;
    if (data == '') {
        await props.store.changeSku(item.id, null);
        fadeOut(item.id);
        return;
    }
    const response = await props.store.changeSku(item.id, data);
    fadeOut(item.id);
    if (response.data) {
        item.sku = data;
    } else {
        event.target.value = '';
        item.sku = '';
    }
};

const changePrice = async (event, item) => {
    let data = event.target.value;
    if (data == '') {
        alert(t('message.needsPrice'));
        event.target.value = item.price;
        return;
    }
    const response = await props.store.changePrice(item.id, data);
    fadeOut(item.id);
    if (response.data) {
        item.price = data;
    } else {
        event.target.value = '';
        item.price = '';
    }
};

const changeManageStock = async (event, item) => {
    let checked = event.target.checked;
    const response = await props.store.changeManageStock(item.id, checked ? 1 : 0);
    if (response.data) {
        item.manage_stock = checked;
    } else {
        event.target.checked = !checked;
        item.manage_stock = !checked;
    }
};

const changeStockStatus = async (event, item) => {
    const response = await props.store.changeStockStatus(item.id, event.target.value);
    fadeOut(item.id);
    if (response.data) {
        item.stock_status = event.target.value;
    }
};

const changeStock = async (event, item) => {
    let data = event.target.value;
    if (data == '') {
        await props.store.changeStock(item.id, 0);
        fadeOut(item.id);
        item.stock = 0;
        event.target.value = 0;
        return;
    }
    const response = await props.store.changeStock(item.id, data);
    fadeOut(item.id);
    if (response.data) {
        item.stock = data;
    }
};

</script>
  
<style scoped>
.status-th,
.status-td {
    text-align: center !important;
}

.vendor-table tbody tr td:nth-child(5) {
    font-weight: normal;
    color: #2b2b2b;
}

.user-table tbody tr td {
    padding: 0.75rem !important;
}

.w-100px {
    width: 100px !important;
}

.w-300px {
    width: 300px !important;
}
</style>
  
