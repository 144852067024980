import { defineStore } from "pinia";

export let useProductCategoryStore = defineStore('product_category', {
    state: () => ({
        product_categories: [],
        product_categories_trashed: [],
        total_pages:0
    }),

    actions: {
        async fill(page = 1,search = null) {
            try {
                let link = `/product-categories?page=${page}`;
              
                if (search !== null) {
                    link += `&search=${search}`;
                }
                let paginated = search == null || search.length < 2;
                const response = await axios.get(link);
                this.product_categories = paginated ? response.data.data : response.data;
                this.total_pages = paginated ? response.data.last_page : 1;
            }
            catch (error) {
                console.log(error)
            }
        },
        async get(id) {
            try {
                return await axios
                .get("/product-categories/" + id).then(function (response) {
                    return response;
                });
            }
            catch(error){
                console.log(error);
            }
        },
        async getTrashed(page = 1,search = null) {
            try {
                let link = `/product-categories-trash?page=${page}`;
                if(search !== null){ link += `&search=${search}`;}
                let paginated = search == null || search.length < 3;
                const response = await axios.get(link);
                this.product_categories_trashed = paginated ? response.data.data : response.data;
                this.total_pages = paginated ? response.data.last_page : 1;
            }
            catch (error) {
                console.log(error)
            }
        },
        async create(product_category) {
            try {
                return await axios
                .post("/product-categories", product_category).then(function (response) {
                    return response;
                });
            }
            catch(error){
                return error.response;
            }
        },
        async update(id, product_category) {
            try {
                return await axios
                .put("/product-categories/" + id, product_category).then(function (response) {
                    return response;
                });
            }
            catch(error){
                return error.response;
            }
        },
        async delete(id) {
            try {
                return axios
                .delete(
                    "/product-categories/" + id
                )
                .then(function (response) {
                    return response;
                });
            }
            catch(error){
                console.log(error);
            }
        },
        async deleteForever(id) {
            try {
                return axios
                .delete(
                    "/product-categories-delete-forever/" + id
                )
                .then(function (response) {
                    return response;
                });
            }
            catch(error){
                console.log(error);
            }
        },
        async restore(id) {
            try {
                return axios
                .post(
                    "/product-categories-restore/" + id
                )
                .then(function (response) {
                    return response;
                });
            }
            catch(error){
                console.log(error);
            }
        },
        async get_parents(id = null) {
            let link = id == null ? "/product-categories-get" : "/product-categories-get/"+id;
            try {
                return await axios
                .get(link).then(function (response) {
                    return response;
                });
            }
            catch(error){
                console.log(error);
            }
        }
    }
});


