import { defineStore } from "pinia";

export let usePostCategoryStore = defineStore('post_category', {
    state: () => ({
        post_categories: [],
        post_categories_trashed: [],
        total_pages:0
    }),

    actions: {
        async fill(page = 1,search = null) {
            try {
                let link = `/post-categories?page=${page}`;
                if (search !== null) {
                    link += `&search=${search}`;
                }
                let paginated = search == null || search.length < 2;
                const response = await axios.get(link);
                this.post_categories = paginated ? response.data.data : response.data;
                this.total_pages = paginated ? response.data.last_page : 1;
            }
            catch (error) {
                console.log(error)
            }
        },
        async get(id) {
            try {
                return await axios
                .get("/post-categories/" + id).then(function (response) {
                    return response;
                });
            }
            catch(error){
                console.log(error);
            }
        },
        async getTrashed(page = 1,search = null) {
            try {
                let link = `post-categories-trash?page=${page}`;
                if(search !== null){ link += `&search=${search}`;}
                let paginated = search == null || search.length < 3;
                const response = await axios.get(link);
                this.post_categories_trashed = paginated ? response.data.data : response.data;
                this.total_pages = paginated ? response.data.last_page : 1;
            }
            catch (error) {
                console.log(error)
            }
        },
        async create(post_category) {
            try {
                return await axios
                .post("/post-categories", post_category).then(function (response) {
                    return response;
                });
            }
            catch(error){
                return error.response;
            }
        },
        async update(id, post_category) {
            try {
                return await axios
                .put("/post-categories/" + id, post_category).then(function (response) {
                    return response;
                });
            }
            catch(error){
                return error.response;
            }
        },
        async delete(id) {
            try {
                return axios
                .delete(
                    "/post-categories/" + id
                )
                .then(function (response) {
                    return response;
                });
            }
            catch(error){
                console.log(error);
            }
        },
        async deleteForever(id) {
            try {
                return axios
                .delete(
                    "/post-categories-delete-forever/" + id
                )
                .then(function (response) {
                    return response;
                });
            }
            catch(error){
                console.log(error);
            }
        },
        async restore(id) {
            try {
                return axios
                .post(
                    "/post-categories-restore/" + id
                )
                .then(function (response) {
                    return response;
                });
            }
            catch(error){
                console.log(error);
            }
        }
    }
});


