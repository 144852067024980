<template>
    <div class="row">
      <div class="col-sm-12">
        <div class="page-header close_icon">
        <div class="header-wrapper row m-0">
          <form
            class="form-inline search-full col custom-search"
            action="javascript:void(0)"
            method="get"
          >
            <div class="form-group w-100">
              <div class="Typeahead Typeahead--twitterUsers">
                <div class="u-posRelative">
                  <input
                    class="demo-input Typeahead-input form-control-plaintext w-100"
                    type="text"
                    :placeholder="$t('message.search')"
                    name="q"
                    title=""
                    autofocus
                    v-model="searchText"
                    @keyup="searchDiscount"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
        <div class="card">
          <p v-if="noDiscountFound" class="m-3">{{ $t('message.no_result') }}</p>

          <tableList
            v-if="discounts.length"
            :columns="columns"
            :listItems="discounts"
            :options="options"
            :store="discountStore"
            :withStatusChange="true"
            :page="page"
            :totalPages="totalPages"
          />
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { computed, onMounted, ref, watch } from "vue";
  import { useDiscountStore } from "@/stores/discountStore";
  import { useI18n } from "vue-i18n";
  import tableList from "@/components/custom/tableList";
  import { useRoute, useRouter } from "vue-router";
  import debounce from 'lodash.debounce';
  
  /*
    multilang
  */
  const { t } = useI18n();
  
  /*
    data
  */
  const discounts = computed(() => {
      return discountStore.discounts;
  });
  const columns = [
    { name: "ID", data: "id" },
    { name: t("message.product"), data: "product_title" },
    { name: t("message.value"), data: "value" },
    { name: t("message.type"), data: "type", translate:true },
    { name: t("message.start_date"), data: "start_date" },
    { name: t("message.end_date"), data: "end_date" },
  ];
  const options = ref([]);
  const totalPages = computed(() => {
      return discountStore.total_pages;
  });

  const searchText = ref();
let noDiscountFound = ref(false);

  /*
    route
  */
  const route = useRoute();
  const router = useRouter();
  const path = ref(route.path);
  const page = computed(() => {
    return parseInt(route.query.page);
  });

  /*
    stores
  */
  let discountStore = useDiscountStore();
  
  
  /*
    events
  */
  onMounted(() => {
    getList(route.query.page);
  });

  watch(
    () => route.query.page,
    (newPage) => {
      getList(newPage);
    },
  );
  
  watch(
    () => route.path,
    (newPath) => {
      path.value = newPath;
      searchText.value = '';
      noDiscountFound.value = false;
      getList();
    }
  );
  
  const getList = debounce(async (page = 1,search = null) => {
      await discountStore.fill(page,search);
      options.value = [
        { type: "edit_link", name: "edit_discount" },
        { type: "event", icon: "lnr lnr-trash", event_type: "only_simple_delete" },
      ];
  }, 1000);

  const searchDiscount = () => {
  router.push({ query: { page: 1 } });
  getList(1, searchText.value);
};

  </script>

  <style scoped>
.page-header.close_icon {
  position: unset;
  margin-left: 0 !important;
  width: calc(100% - 50px) !important;
  background-color: #fbfeff;
  box-shadow: unset;
}

.header-wrapper {
  padding: 22px 0px !important;
}
/* search input not to dissapear at mobile */
@media only screen and (max-width: 762px){
    .custom-search {
      display: flex !important;
      position: relative !important;
      .form-group {
        margin-top: 0 !important;
      }
  }
}
</style>
  