import { defineStore } from "pinia";

export let useDiscountStore = defineStore('discount', {
    state: () => ({
        discounts: [],
        total_pages:0
    }),

    actions: {
        async fill(page = 1,search = null ) {
            try {
                let link = `/discounts?page=${page}`;
                if (search !== null) {
                    link += `&search=${search}`;
                }
                let paginated = search == null || search.length < 2;
                const response = await axios.get(link);
                this.discounts = paginated ? response.data.data : response.data;
                this.total_pages = paginated ? response.data.last_page : 1;
            }
            catch (error) {
                console.log(error)
            }
        },
        async get(id) {
            try {
                return await axios
                .get("/discounts/" + id).then(function (response) {
                    return response;
                });
            }
            catch(error){
                console.log(error);
            }
        },
        async create(discount) {
            try {
                return await axios
                .post("/discounts", discount).then(function (response) {
                    return response;
                });
            }
            catch(error){
                return error.response;
            }
        },
        async update(id, discount) {
            try {
                return await axios
                .put("/discounts/" + id, discount).then(function (response) {
                    return response;
                });
            }
            catch(error){
                return error.response;
            }
        },
        async delete(id) {
            try {
                return axios
                .delete(
                    "/discounts/" + id
                )
                .then(function (response) {
                    return response;
                });
            }
            catch(error){
                console.log(error);
            }
        },
        async changeStatus(discount, status) {
            try {
                return await axios
                .put("/discounts-change-status/" + discount + "/" + status).then(function (response) {
                    return response;
                });
            }
            catch(error){
                return error.response;
            }
        },
        async getProductsWithoutActiveDiscount() {
            try {
                return await axios
                .get("/get-products-without-active-discount").then(function (response) {
                    return response.data;
                });
            }
            catch (error) {
                console.log(error)
            }
        },
    }
});


