<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <!-- Table Start -->
        <div class="card-body">
          <div class="table-responsive table-desi">
            <table class="user-table ticket-table table table-striped">
              <thead>
                <tr>
                  <th>Ticket Number</th>
                  <th>Date</th>
                  <th>Subject</th>
                  <th>Status</th>
                  <th>Options</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>#786</td>

                  <td>25-09-2021</td>

                  <td>Query about return & exchange</td>

                  <td class="status-danger">
                    <span>Pending</span>
                  </td>

                  <td>
                    <ul>
                      <li>
                        <router-link to="/order_detail" href="javascript:void(0)">
                          <span class="lnr lnr-eye"></span>
                        </router-link>
                      </li>

                      <li>
                        <a href="javascript:void(0)">
                          <span class="lnr lnr-pencil"></span>
                        </a>
                      </li>

                      <li>
                        <a href="javascript:void(0)">
                          <span class="lnr lnr-trash"></span>
                        </a>
                      </li>
                    </ul>
                  </td>
                </tr>

                <tr>
                  <td>#453</td>
                  <td>20-10-2021</td>
                  <td>Query about return & exchange</td>
                  <td class="status-close">
                    <span>Closed</span>
                  </td>
                  <td>
                    <ul>
                      <li>
                        <router-link to="/order_detail" href="javascript:void(0)">
                          <span class="lnr lnr-eye"></span>
                        </router-link>
                      </li>
                      <li>
                        <a href="javascript:void(0)">
                          <span class="lnr lnr-pencil"></span>
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">
                          <span class="lnr lnr-trash"></span>
                        </a>
                      </li>
                    </ul>
                  </td>
                </tr>

                <tr>
                  <td>#456</td>
                  <td>30-01-2021</td>
                  <td>Query about return & exchange</td>
                  <td class="status-danger">
                    <span>Pending</span>
                  </td>
                  <td>
                    <ul>
                      <li>
                        <router-link to="/order_detail" href="javascript:void(0)">
                          <span class="lnr lnr-eye"></span>
                        </router-link>
                      </li>
                      <li>
                        <a href="javascript:void(0)">
                          <span class="lnr lnr-pencil"></span>
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">
                          <span class="lnr lnr-trash"></span>
                        </a>
                      </li>
                    </ul>
                  </td>
                </tr>

                <tr>
                  <td>#782</td>
                  <td>02-04-2021</td>
                  <td>Query about return & exchange</td>
                  <td class="status-close">
                    <span>Closed</span>
                  </td>
                  <td>
                    <ul>
                      <li>
                        <router-link to="/order_detail" href="javascript:void(0)">
                          <span class="lnr lnr-eye"></span>
                        </router-link>
                      </li>
                      <li>
                        <a href="javascript:void(0)">
                          <span class="lnr lnr-pencil"></span>
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">
                          <span class="lnr lnr-trash"></span>
                        </a>
                      </li>
                    </ul>
                  </td>
                </tr>

                <tr>
                  <td>#214</td>
                  <td>10-01-2021</td>
                  <td>Query about return & exchange</td>
                  <td class="status-close">
                    <span>Closed</span>
                  </td>
                  <td>
                    <ul>
                      <li>
                        <router-link to="/order_detail" href="javascript:void(0)">
                          <span class="lnr lnr-eye"></span>
                        </router-link>
                      </li>
                      <li>
                        <a href="javascript:void(0)">
                          <span class="lnr lnr-pencil"></span>
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">
                          <span class="lnr lnr-trash"></span>
                        </a>
                      </li>
                    </ul>
                  </td>
                </tr>

                <tr>
                  <td>#258</td>
                  <td>26-07-2021</td>
                  <td>Query about return & exchange</td>
                  <td class="status-danger">
                    <span>Pending</span>
                  </td>
                  <td>
                    <ul>
                      <li>
                        <router-link to="/order_detail" href="javascript:void(0)">
                          <span class="lnr lnr-eye"></span>
                        </router-link>
                      </li>
                      <li>
                        <a href="javascript:void(0)">
                          <span class="lnr lnr-pencil"></span>
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">
                          <span class="lnr lnr-trash"></span>
                        </a>
                      </li>
                    </ul>
                  </td>
                </tr>

                <tr>
                  <td>#634</td>
                  <td>30-10-2020</td>
                  <td>Query about return & exchange</td>
                  <td class="status-close">
                    <span>Closed</span>
                  </td>
                  <td>
                    <ul>
                      <li>
                        <router-link to="/order_detail" href="javascript:void(0)">
                          <span class="lnr lnr-eye"></span>
                        </router-link>
                      </li>
                      <li>
                        <a href="javascript:void(0)">
                          <span class="lnr lnr-pencil"></span>
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">
                          <span class="lnr lnr-trash"></span>
                        </a>
                      </li>
                    </ul>
                  </td>
                </tr>

                <tr>
                  <td>#124</td>
                  <td>09-08-2021</td>
                  <td>Query about return & exchange</td>
                  <td class="status-danger">
                    <span>Pending</span>
                  </td>
                  <td>
                    <ul>
                      <li>
                        <router-link to="/order_detail" href="javascript:void(0)">
                          <span class="lnr lnr-eye"></span>
                        </router-link>
                      </li>
                      <li>
                        <a href="javascript:void(0)">
                          <span class="lnr lnr-pencil"></span>
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">
                          <span class="lnr lnr-trash"></span>
                        </a>
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- Table End -->

        <!-- Pagination Start -->
        <div class="pagination-box">
          <nav class="ms-auto me-auto" aria-label="...">
            <ul class="pagination pagination-primary">
              <li class="page-item disabled">
                <a class="page-link" href="javascript:void(0)">Previous</a>
              </li>
              <li class="page-item active">
                <a class="page-link" href="javascript:void(0)">1 </a>
              </li>
              <li class="page-item">
                <a class="page-link" href="javascript:void(0)">2</a>
              </li>
              <li class="page-item">
                <a class="page-link" href="javascript:void(0)">3</a>
              </li>
              <li class="page-item">
                <a class="page-link" href="javascript:void(0)">Next</a>
              </li>
            </ul>
          </nav>
        </div>
        <!-- Pagination End -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  title: "Voxo-Support Ticket",

};
</script>

<style>
</style>
