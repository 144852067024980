<template>
  <div class="card">
    <div class="card-body">
      <form class="theme-form theme-form-2 mega-form" @submit.prevent="saveBrand">
        <language-inputs
          v-if="mainCode && fields && fields.length > 0"
          v-model:fields="fields"
          :fields="fields"
          :mainCode="mainCode"
          :fieldTypes="fieldTypes"
        />
        <div class="mt-3 lang-errors-container">
          <p class="text-danger" v-for="error of v$.fields.$errors" :key="error.$uid">
            <strong>{{
              t("validationErrors.lang_field", {
                field: t(`message.${error.$propertyPath.split(".")[2]}`),
                code: mainCode,
              })
            }}</strong>
          </p>
        </div>
        <div class="row">
          <label for="basic-media-input" class="form-label-title form-label mb-1 mt-2">{{ t("message.basic_media") }}</label>
          <Uploader v-if="basicMedia.saved && basicMedia.saved.length > 0 && id > 0" max="1" class="mt-1" server="/store-media" inputId="basic-media-input" :media="basicMedia.saved" :location="$storageLocation" :withIdFolder="true" @add="addBasicMedia" @remove="removeBasicMedia" maxFilesize="100"/>
          <Uploader v-else max="1" class="mt-1" server="/store-media" @change="changeBasicMedia" inputId="basic-media-input" maxFilesize="100"/>
        </div>
        <div class="row">
          <label for="media-collection-input" class="form-label-title form-label mb-1 mt-2">{{ t("message.media_files") }}</label>
          <Uploader v-if="mediaCollection.saved && mediaCollection.saved.length > 0 && id > 0" class="mt-1" server="/store-media" inputId="media-collection-input" :media="mediaCollection.saved" :location="$storageLocation" :withIdFolder="true" @add="addMediaCollection" @remove="removeMediaCollection" maxFilesize="100"/>
          <Uploader v-else class="mt-1" server="/store-media" @change="changeMediaCollection" inputId="media-collection-input" maxFilesize="100"/>
        </div>
        <div class="row g-4">
          <div class="col-lg-6 text-center mt-5">
            <button :class="{'btn btn-primary':true, 'disabled':btnDisabled}">
              {{ t("message.save") }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
  
<script setup>
import { ref, onMounted, onBeforeMount, computed, inject, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useLocaleStore } from "@/stores/locale";
import { useBrandStore } from "@/stores/brandStore";
import { fillTranslationFields } from "@/helpers/useTranslationFields";
import { useFieldStore } from "@/stores/fieldStore";
import languageInputs from "@/components/custom/languageInputs";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { useLanguageStore } from "@/stores/languageStore";
import { useRouter, useRoute } from "vue-router";
import Uploader from "vue-media-upload";

/*
  multilang, notifications
*/
const { t } = useI18n();
const toast = inject("toast");

/*
  form data, data
*/
let fields = ref([]);
let fieldTypes = ref();
let btnDisabled = ref(false);
const basicMedia = ref({saved:[],added:[],removed:[]});
const mediaCollection = ref({saved:[],added:[],removed:[]});

/*
  stores
*/
let activeLang = useLocaleStore();
let brandStore = useBrandStore();
let fieldStore = useFieldStore();
const languageStore = useLanguageStore();

const mainCode = computed(() => {
  return languageStore.mainCode;
});


/*
  validation
*/
const rules = {
  fields: { 0: { name: { [mainCode.value]: { required } } }, 
  // 1: { description: { [mainCode.value]: { required } }}
  },
};

const v$ = useVuelidate(rules, { fields });

/*
  router
*/
const router = useRouter();
const route = useRoute();
const id = computed(() => {
  return route.params.id;
});

/*
  events
*/
onBeforeMount(async () => {
  await languageStore.getMainCode();
});

onMounted(async () => {
  await getFields();
  if (id.value > 0) {
    const response = await brandStore.get(id.value);
    fillTranslationFields(fields, response.data.translations);
    let basic = response.data.media.filter(item => item.collection_name === 'basic')[0];
    basicMedia.value.saved = basic ? [{id:basic.id,name:basic.file_name}] : [];
    let collection = response.data.media.filter(item => item.collection_name === 'brands');
    collection.forEach(function(val,key){
      mediaCollection.value.saved.push({id:val.id, name:val.file_name, url:val.original_url});
    });
  }
});

const saveBrand = async () => {
  btnDisabled.value = true;
  const request = {
    lang: activeLang.locale,
    translations: fields.value,
    basicMedia: id.value > 0 ? basicMedia.value : basicMedia.value.added,
    mediaCollection: id.value > 0 ? mediaCollection.value : mediaCollection.value.added
  };

  const isValid = await v$.value.$validate();

  if (isValid) {
    const response =
      id.value > 0
        ? await brandStore.update(id.value, request)
        : await brandStore.create(request);

    if (response && response.data.message == "success") {
      toast.success(t("message.success_message"));
      id.value > 0 ? router.go(-1) : router.push({ name: "brand_list", query:{page:response.data.lastPage} });    
    } else {
      if(response.status == 422){
        toast.error(t("message.lang_name_error"));
      } else {
        toast.error(t("message.error_message"));
      }
    }
  }
  btnDisabled.value = false;
};

watch(
  () => route.path,
  (newPath) => {
    if (newPath == "/create_brand") {
      getFields();
      mediaCollection.value = {};
      basicMedia.value = {};
    }
  }
);

const getFields = async () => {
  const fieldsResponse = await fieldStore.fill("brand_translations");
  fields.value = fieldsResponse.data;
  const typesResponse = await fieldStore.getFieldTypes("brand_translations");
  fieldTypes.value = typesResponse.data;
};

const changeBasicMedia = (basic) => {
  basicMedia.value.added = basic;
};

const changeMediaCollection = (collection) => {
  mediaCollection.value.added = collection;
};

const addBasicMedia = (addedImage, added) => {
  basicMedia.value.added = added;
};

const removeBasicMedia = (removedImage, removed) => {
  basicMedia.value.removed = removed;
};

const addMediaCollection = (addedImage, added) => {
  mediaCollection.value.added = added;
};

const removeMediaCollection = (removedImage, removed) => {
  mediaCollection.value.removed = removed;
};

</script>
  