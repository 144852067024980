<template>
  <div class="row">
    <div class="col-sm-12">
      <!-- Loading Overlay -->
      <div v-if="loading" class="loading-overlay">
        <div class="spinner"></div>
      </div>

      <div class="page-header close_icon">
        <div class="header-wrapper row m-0">
          <form class="form-inline search-full col custom-search" action="javascript:void(0)" method="get">
            <div class="form-group w-100">
              <div class="Typeahead Typeahead--twitterUsers">
                <div class="u-posRelative">
                  <input class="demo-input Typeahead-input form-control-plaintext w-100" type="text"
                    :placeholder="$t('message.search')" name="q" title="" autofocus v-model="searchText"
                    @keyup="searchOrder" />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <ul class="nav nav-pills mb-3 pb-1" id="pills-tab" role="tablist" v-if="!searchText">
        <li class="nav-item" role="presentation">
          <button class="nav-link bg-grey text-white" :class="{ active: 1 == activeTab }" id="pills-home-tab"
            type="button" @click="changeTab(1)">
            {{ t("message.new") }}
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link bg-lightblue text-white" :class="{ active: 2 == activeTab }" id="pills-home-tab"
            type="button" @click="changeTab(2)">
            {{ t("message.registered") }}
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link bg-blue text-white" :class="{ active: 3 == activeTab }" id="pills-home-tab"
            type="button" @click="changeTab(3)">
            {{ t("message.packaged") }}
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link bg-yellow text-dark" :class="{ active: 4 == activeTab }" id="pills-home-tab"
            type="button" @click="changeTab(4)">
            {{ t("message.courier") }}
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link bg-green text-white" :class="{ active: 5 == activeTab }" id="pills-home-tab"
            type="button" @click="changeTab(5)">
            {{ t("message.arrived") }}
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link bg-red text-white" :class="{ active: 6 == activeTab }" id="pills-home-tab"
            type="button" @click="changeTab(6)">
            {{ t("message.archived") }}
          </button>
        </li>
      </ul>

      <div class="card">
        <p v-if="noOrderFound" class="m-3">{{ $t('message.no_result') }}</p>
        <OrderTableList v-if="orders.length" :listItems="orders" :store="orderStore" :page="page"
          :totalPages="totalPages" :getList="getList" :activeTab="activeTab" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from "vue";
import { useOrderStore } from "@/stores/orderStore";
import { useI18n } from "vue-i18n";
import OrderTableList from "@/components/custom/orderTableList.vue";
import { useRoute, useRouter } from "vue-router";
import debounce from 'lodash.debounce';

const { t } = useI18n();

const searchText = ref('');
const noOrderFound = ref(false);
const activeTab = ref(1); // Default to status 1
const loading = ref(false);

const orderStore = useOrderStore();
const route = useRoute();
const router = useRouter();
const path = ref(route.path);

const page = computed(() => {
  return parseInt(route.query.page) || 1;
});

const totalPages = computed(() => {
  return orderStore.total_pages;
});

const orders = computed(() => {
  if (path.value === "/order_list") {
    return orderStore.orders;
  }
});

const getList = debounce(async (status, page, search = null) => {
  try {
    loading.value = true; // Start loading
    await orderStore.fill(status, page, search);
    noOrderFound.value = orderStore.orders.length === 0;
  } catch (error) {
    console.error("Error in getList:", error);
  } finally {
    loading.value = false; // End loading
  }
}, 1000);

const searchOrder = () => {
  router.push({ query: { page: 1 } });
  getList(activeTab.value, 1, searchText.value);
};

const changeTab = (status) => {
  if (!loading.value) { // Prevent tab switch if loading
    activeTab.value = status;
    router.push({ query: { page: 1, status } });
  }
};

onMounted(() => {
  // Set default status to 1 if none is specified
  const initialStatus = parseInt(route.query.status) || 1;
  if (initialStatus !== 1) {
    // Redirect to default status 1 if the status is not 1
    router.push({ query: { page: 1, status: 1 } });
  } else {
    activeTab.value = initialStatus;
    getList(initialStatus, route.query.page || 1);
  }
});

watch(
  () => route.path,
  (newPath) => {
    path.value = newPath;
    searchText.value = '';
    noOrderFound.value = false;
    const initialStatus = parseInt(route.query.status) || 1;
    if (initialStatus !== 1) {
      router.push({ query: { page: 1, status: 1 } });
    } else {
      activeTab.value = initialStatus;
      getList(initialStatus, 1);
    }
  }
);

watch(
  () => route.query.page,
  (newPage) => {
    getList(activeTab.value, newPage);
  },
  { immediate: true }
);

watch(
  () => activeTab.value,
  (newState) => {
    getList(newState, 1);
  },
  { immediate: true }
);
</script>

<style scoped>
.page-header.close_icon {
  position: unset;
  margin-left: 0 !important;
  width: calc(100% - 50px) !important;
  background-color: #fbfeff;
  box-shadow: unset;
}

.header-wrapper {
  padding: 22px 0px !important;
}

/* search input not to dissapear at mobile */
@media only screen and (max-width: 762px) {
  .custom-search {
    display: flex !important;
    position: relative !important;

    .form-group {
      margin-top: 0 !important;
    }
  }
}


.nav-pills {
  display: flex;
  justify-content: center;
  padding-left: 0;
}

.status-th,
.status-td {
  text-align: center !important;
}

.vendor-table tbody tr td:nth-child(5) {
  font-weight: normal;
  color: #2b2b2b;
}

.user-table tbody tr td {
  padding: 0.75rem !important;
}

.bg-grey {
  background-color: grey !important;
}

.bg-lightblue {
  background-color: #17a2b8 !important;
}

.bg-blue {
  background-color: #007bff !important;
}

.bg-yellow {
  background-color: #ffc107 !important;
}

.bg-green {
  background-color: #28a745 !important;
}

.bg-red {
  background-color: #dc3545 !important;

}

.bg-red td {
  color: white !important;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 4px solid #007bff;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
