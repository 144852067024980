import { defineStore } from "pinia";

export let useBrandStore = defineStore('brand', {
    state: () => ({
        brands: [],
        brands_trashed: [],
        total_pages:0
    }),

    actions: {
        async fill(page = 1, paginated = 1, search = null) {
            try {
                let link = paginated == 1 ? `/brands?page=${page}` : `/brands?paginated=0`;
                if(search !== null){ link += `&search=${search}`;}
                let paginatedResponse = (search == null || search.length < 2) && paginated == 1;
                const response = await axios.get(link);
                this.brands = paginatedResponse ? response.data.data : response.data;
                this.total_pages = paginatedResponse ? response.data.last_page : 1;
            }
            catch (error) {
                console.log(error)
            }
        },
        async get(id) {
            try {
                return await axios
                .get("/brands/" + id).then(function (response) {
                    return response;
                });
            }
            catch(error){
                console.log(error);
            }
        },
        async getTrashed(page = 1, paginated = 1, search = null) {
            try {
                let link = paginated == 1 ? `/brands-trash?page=${page}` : `/brands-trash?paginated=0`;
                if(search !== null){ link += `&search=${search}`;}
                let paginatedResponse = (search == null || search.length < 3) && paginated == 1;
                const response = await axios.get(link);
                this.brands_trashed = paginatedResponse ? response.data.data : response.data;
                this.total_pages = paginatedResponse ? response.data.last_page : 1;
            }
            catch (error) {
                console.log(error)
            }
        },
        async create(brand) {
            try {
                return await axios
                .post("/brands", brand).then(function (response) {
                    return response;
                });
            }
            catch(error){
                return error.response;
            }
        },
        async update(id, brand) {
            try {
                return await axios
                .put("/brands/" + id, brand).then(function (response) {
                    return response;
                });
            }
            catch(error){
                return error.response;
            }
        },
        async delete(id) {
            try {
                return axios
                .delete(
                    "/brands/" + id
                )
                .then(function (response) {
                    return response;
                });
            }
            catch(error){
                console.log(error);
            }
        },
        async deleteForever(id) {
            try {
                return axios
                .delete(
                    "/brands-delete-forever/" + id
                )
                .then(function (response) {
                    return response;
                });
            }
            catch(error){
                console.log(error);
            }
        },
        async restore(id) {
            try {
                return axios
                .post(
                    "/brands-restore/" + id
                )
                .then(function (response) {
                    return response;
                });
            }
            catch(error){
                console.log(error);
            }
        }
    }
});


