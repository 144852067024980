<template>
  <div
    class="modal fade"
    id="staticBackdrop"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <h5 class="modal-title" id="staticBackdropLabel">Logging Out</h5>
          <p>Are you sure you want to log out?</p>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>

          <div class="button-box">
            <button type="button" class="btn btn--no" data-bs-dismiss="modal">
              No
            </button>
            <button
              type="button"
              @click="handleLogout"
              data-bs-dismiss="modal"
              class="btn btn--yes btn-primary"
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useAuthStore } from "@/stores/auth";

export default {
  methods: {
    handleLogout() {
      useAuthStore().logout();
    },
  },
};
</script>

<style></style>
