<template>
  <div class="tab-pane fade show active" id="pills-profile" role="tabpanel">
    <div class="card-header-1">
      <h5>Product Related Permition</h5>
    </div>
    <div class="mb-4 row align-items-center">
      <label class="col-md-2 col-sm-4 mb-0">Add Product</label>
      <div class="col-md-9 col-sm-7">
        <form class="radio-section">
          <label>
            <input type="radio" name="opinion" checked />
            <i></i>
            <span>Allow</span>
          </label>

          <label>
            <input type="radio" name="opinion" />
            <i></i>
            <span>Deny</span>
          </label>
        </form>
      </div>
    </div>

    <div class="mb-4 row align-items-center">
      <label class="col-md-2 col-sm-4 mb-0">Update Product</label>
      <div class="col-md-9 col-sm-7">
        <form class="radio-section">
          <label>
            <input type="radio" name="opinion" />
            <i></i>
            <span>Allow</span>
          </label>

          <label>
            <input type="radio" name="opinion" checked />
            <i></i>
            <span>Deny</span>
          </label>
        </form>
      </div>
    </div>

    <div class="mb-4 row align-items-center">
      <label class="col-md-2 col-sm-4 mb-0">Delete Product</label>
      <div class="col-md-9 col-sm-7">
        <form class="radio-section">
          <label>
            <input type="radio" name="opinion" checked />
            <i></i>
            <span>Allow</span>
          </label>

          <label>
            <input type="radio" name="opinion" />
            <i></i>
            <span>Deny</span>
          </label>
        </form>
      </div>
    </div>

    <div class="mb-4 row align-items-center">
      <label class="col-md-2 col-sm-4 mb-0">Apply Discount</label>
      <div class="col-md-9 col-sm-7">
        <form class="radio-section">
          <label>
            <input type="radio" name="opinion" />
            <i></i>
            <span>Allow</span>
          </label>

          <label>
            <input type="radio" name="opinion" checked />
            <i></i>
            <span>Deny</span>
          </label>
        </form>
      </div>
    </div>

    <div class="card-header-1">
      <h5>Category Related Permition</h5>
    </div>
    <div class="mb-4 row align-items-center">
      <label class="col-md-2 col-sm-4 mb-0">Add Product</label>
      <div class="col-md-9 col-sm-7">
        <form class="radio-section">
          <label>
            <input type="radio" name="opinion" checked />
            <i></i>
            <span>Allow</span>
          </label>

          <label>
            <input type="radio" name="opinion" />
            <i></i>
            <span>Deny</span>
          </label>
        </form>
      </div>
    </div>

    <div class="mb-4 row align-items-center">
      <label class="col-md-2 col-sm-4 mb-0">Update Product</label>
      <div class="col-md-9 col-sm-7">
        <form class="radio-section">
          <label>
            <input type="radio" name="opinion" />
            <i></i>
            <span>Allow</span>
          </label>

          <label>
            <input type="radio" name="opinion" checked />
            <i></i>
            <span>Deny</span>
          </label>
        </form>
      </div>
    </div>

    <div class="mb-4 row align-items-center">
      <label class="col-md-2 col-sm-4 mb-0">Delete Product</label>
      <div class="col-md-9 col-sm-7">
        <form class="radio-section">
          <label>
            <input type="radio" name="opinion" />
            <i></i>
            <span>Allow</span>
          </label>

          <label>
            <input type="radio" name="opinion" checked />
            <i></i>
            <span>Deny</span>
          </label>
        </form>
      </div>
    </div>

    <div class="mb-4 row align-items-center">
      <label class="col-md-2 col-sm-4 mb-0">Apply Discount</label>
      <div class="col-md-9 col-sm-7">
        <form class="radio-section">
          <label>
            <input type="radio" name="opinion" checked />
            <i></i>
            <span>Allow</span>
          </label>

          <label>
            <input type="radio" name="opinion" />
            <i></i>
            <span>Deny</span>
          </label>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>
