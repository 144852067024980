<template>
  <component
    :is="this.$route.meta.layout || 'div'"
    :breadcrumbs="this.$route.meta.breadcrumb"
  >
    <router-view />
    <logoutModal />
  </component>
</template>
<script>
import logoutModal from "@/layouts/common/modals/logoutModal.vue";

export default {
  components: { logoutModal }
};
</script>
