import commonMessages from './common'
import validationErrors from './validationErrors'
import productMessages from './product'
import couponMessages from './coupon'
import deliveryPriceMessages from './deliveryPrice'
import orderMessages from './orders'
import menuMessages from './menu'
import dashboardMessages from './dashboard'
import popupMessages from './popup'

export default {
    en: {
        message: {
            ...commonMessages.en,
            ...productMessages.en,
            ...couponMessages.en,
            ...deliveryPriceMessages.en,
            ...orderMessages.en,
            ...menuMessages.en,
            ...dashboardMessages.en,
            ...popupMessages.en,
        },
        validationErrors: validationErrors.en        
    },
    hu: {
        message: {
            ...commonMessages.hu,
            ...productMessages.hu,
            ...couponMessages.hu,
            ...deliveryPriceMessages.hu,
            ...orderMessages.hu,
            ...menuMessages.hu,
            ...dashboardMessages.hu,
            ...popupMessages.hu,

        },    
        validationErrors: validationErrors.hu        
    },
    ro: {
        message: {
            ...commonMessages.ro,
            ...productMessages.ro,
            ...couponMessages.ro,
            ...deliveryPriceMessages.ro,
            ...orderMessages.ro,
            ...menuMessages.ro,
            ...dashboardMessages.ro,
            ...popupMessages.ro,

        },
        validationErrors: validationErrors.ro        
    }
}