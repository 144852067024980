<template>
  <div class="card">
    <div class="card-body">
      <form class="theme-form theme-form-2 mega-form" @submit.prevent="saveDiscount">
        <div class="row mt-1 mb-2">
          <div class="col-lg-6">
            <label class="form-label-title mb-2 form-label">{{
              t("message.product")
            }}</label>
            <select 
              v-model="v$.selectedProduct.$model"
              id="products"
              class="form-control"
            ></select>
            <p class="text-danger" v-for="error of v$.selectedProduct.$errors" :key="error.$uid">
              <strong>{{ t(`validationErrors.${[error.$message]}`) }}</strong>
            </p>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-lg-6">
            <label class="form-label-title mb-2 form-label">{{ t("message.value") }}</label>
            <input v-model="value" type="text" class="form-control" />
            <p class="text-danger" v-for="error of v$.value.$errors" :key="error.$uid">
              <strong>{{ t(`validationErrors.${[error.$message]}`) }}</strong>
            </p>
          </div>
        </div>
        <div class="row mt-1 mb-2">
          <div class="col-lg-6">
            <label class="form-label-title mb-2 form-label">{{
              t("message.type")
            }}</label>
            <select class="js-example-basic-single w-100" v-model="type">
              <option value="amount">{{ t("message.amount") }}</option>
              <option value="percentage">%</option>
            </select>
            <p class="text-danger" v-for="error of v$.type.$errors" :key="error.$uid">
              <strong>{{ t(`validationErrors.${[error.$message]}`) }}</strong>
            </p>
          </div>
        </div>
        <div class="row mt-1 mb-2">
          <div class="col-lg-6">
            <label class="form-label-title mb-2 form-label">{{
              t("message.start_date")
            }}</label>
            <input v-model="start_date" class="form-control" type="date" />
          </div>
          <p class="text-danger" v-for="error of v$.start_date.$errors" :key="error.$uid">
              <strong>{{ t(`validationErrors.${[error.$message]}`) }}</strong>
            </p>
        </div>
        <div class="row mt-1 mb-2">
          <div class="col-lg-6">
            <label class="form-label-title mb-2 form-label">{{
              t("message.end_date")
            }}</label>
            <input v-model="end_date" class="form-control" type="date" />
          </div>
          <p class="text-danger" v-for="error of v$.end_date.$errors" :key="error.$uid">
              <strong>{{ t(`validationErrors.${[error.$validator]}`) }}</strong>
            </p>
        </div>
        <div class="row g-4">
          <div class="col-lg-6 text-center mt-5">
            <button :class="{ 'btn btn-primary': true, disabled: btnDisabled || (!discountIsActive && productHasOtherActiveDiscount)}">
              {{ t("message.save") }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed, inject, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useLocaleStore } from "@/stores/locale";
import { useDiscountStore } from "@/stores/discountStore";
import { useVuelidate } from "@vuelidate/core";
import { required, numeric } from "@vuelidate/validators";
import { useRouter, useRoute } from "vue-router";
import { isEndDateValid } from "@/helpers/useIsEndDateValid";
import { initChoice } from "@/helpers/useInitChoice";

/*
    multilang, notifications
*/
const { t } = useI18n();
const toast = inject("toast");

/*
    form data, data
*/
let btnDisabled = ref(false);
let value = ref();
let type = ref();
let selectedProduct = ref();
let start_date = ref();
let end_date = ref();
let products = ref([]);
let product_title = ref();
let discountIsActive = ref();
let productHasOtherActiveDiscount = ref();
let productSelect = ref();

/*
    stores
  */
let activeLang = useLocaleStore();
let discountStore = useDiscountStore();

/*
    validation
*/
const rules = {
  value: {required, numeric}, start_date: {required}, selectedProduct: {required}, type: {required}, end_date: { isEndDateValid: () => isEndDateValid(start_date.value, end_date.value) }
};

const v$ = useVuelidate(rules, { value,start_date,selectedProduct,type,end_date });

/*
    router
  */
const router = useRouter();
const route = useRoute();
const id = computed(() => {
  return route.params.id;
});


onMounted(async () => {
    if (id.value > 0) {
        initChoice("products", productSelect, false);
        getProducts();
        const response = await discountStore.get(id.value);
        value.value = response.data.value;
        type.value = response.data.type;
        selectedProduct.value = response.data.product_id;
        start_date.value = response.data.start_date;
        end_date.value = response.data.end_date;
        product_title.value = response.data.product_title;
        discountIsActive.value = response.data.active;
        productHasOtherActiveDiscount.value = response.data.productHasOtherActiveDiscount;
        productSelect.value.setChoices([{value:selectedProduct.value, label:product_title.value, selected:true}], "value", "label", true);
    } else {
      initChoice("products", productSelect, true);
      getProducts();
    }
});

const saveDiscount = async () => {
  btnDisabled.value = true;
  const request = {
    lang: activeLang.locale,
    product_id: selectedProduct.value, 
    value: value.value, 
    type: type.value, 
    start_date: start_date.value, 
    end_date: end_date.value
  };

  const isValid = await v$.value.$validate();

  if (isValid) {
    const response =
      id.value > 0
        ? await discountStore.update(id.value, request)
        : await discountStore.create(request);

    if (response && response.data.message == "success") {
      toast.success(t("message.success_message"));
      id.value > 0 ? router.go(-1) : router.push({ name: "discount_list", query:{page:response.data.lastPage} });
    } else {
      toast.error(t("message.error_message"));
    }
  }
  btnDisabled.value = false;
};

watch(
   () => route.path,
  async (newPath) =>  {
    if (newPath == "/create_discount") {
      await getProducts();
      productSelect.value.destroy();
      initChoice("products", productSelect, true);
        selectedProduct.value = "";
        value.value = "";
        type.value = "";
        start_date.value = "";
        end_date.value = "";
    }
  }
);

function getProducts() {
  productSelect.value.removeActiveItems();
  (async () => {
    await discountStore.getProductsWithoutActiveDiscount().then(function (response) {
      let datas = response;
      let choices = [];
      datas.forEach((val, key) => {
        choices[key] = {};
        choices[key].value = val.id;
        choices[key].label = val.title == null ? t('message.without_name') : val.title;
        choices[key].selected = id.value ? val.selected : false;
      });
      products.value = choices;
      productSelect.value.setChoices(products.value, "value", "label", true);
    });
  })();
}
</script>
